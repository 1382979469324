import { createReportPasswordAlertContentEn } from './create-report-password-alert.content-en'

export interface CreateReportPasswordAlertContent {
  header: string

  descriptionPart1: string
  descriptionPart2: string

  passwordRequirementsHeader: string
  passwordRequirements: string[]

  passwordLabel: string
  confirmPasswordLabel: string

  passwordErrorMessage: string
  confirmPasswordErrorMessage: string

  backButtonLabel: string
  createButtonLabel: string
}

export const createReportPasswordAlertContentProvider = (): CreateReportPasswordAlertContent => {
  return createReportPasswordAlertContentEn
}
