import React from 'react';
import { homeContentProvider } from '../../contents/home.content';
import { DefaultBodyText } from '../../../shared/components';

const content = homeContentProvider();

const HomeResumeDescription = () => {
  return (
    <DefaultBodyText fontSize="1rem">
      {content.resumeDescription}
    </DefaultBodyText>
  );
};

export default HomeResumeDescription;
