import React, { useState, useEffect, useContext } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { Color } from '../../../shared/theme';
import { useLayout, useLogoStyle, useNavStyle } from '../styles/styles';
import { sideNav } from '../models/side-nav.model';
import { REPORT_ROUTES } from '../../../constants';
import { SectionType } from '../../../shared/types/section-type';
import ServiceLocator from '../../../shared/service-locator/service-locator';
import alertService from '../../../alerts/services/alert-service';
import victimSectionFormService from '../../../victim-section/victim-section.form-service';
import vehicleSectionFormService from '../../../vehicle-section/services/vehicle-section-form-service';
import dateAndLocationSectionFormService
  from '../../../date-and-location-section/services/date-and-location-section-form-service';
import suspectSectionFormService from '../../../suspect-section/services/suspect-section-form-service';
import sequenceOfEventsSectionFormService
  from '../../../sequence-of-events-section/sequence-of-events-section.form-service';

import { NavigationContext } from '../../navigation-context';
// components
import { DefaultHeader, DefaultBodyText } from '../../../shared/components';
import SubmitFormButton from './submit-form-button';
import LeaveFormButton from './leave-form-button';
import logoImage from '../../../assets/logo.png';

const reportService = ServiceLocator.getInstance().getReportService();

type SideNavSectionData = {
  hasBeenFilled: boolean;
}

const SideNav: React.FunctionComponent<RouteComponentProps> = ({ location, history }) => {
  const layout = useLayout();
  const navStyle = useNavStyle();
  const logoStyle = useLogoStyle();

  const [sideNavData, setSideNavData] = useState<Record<SectionType, SideNavSectionData>>({
    victim: { hasBeenFilled: false },
    date: { hasBeenFilled: false },
    location: { hasBeenFilled: false },
    vehicle: { hasBeenFilled: false },
    suspect: { hasBeenFilled: false },
    sequenceOfEvents: { hasBeenFilled: false }
  });

  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const [sectionHasUnsavedData, setSectionHasUnsavedData] = useState({
    [REPORT_ROUTES.VICTIM_SECTION]: false,
    [REPORT_ROUTES.DATE_AND_LOCATION_SECTION]: false,
    [REPORT_ROUTES.VEHICLE_SECTION]: false,
    [REPORT_ROUTES.SUSPECT_SECTION]: false,
    [REPORT_ROUTES.EVENTS_SECTION]: false,
  });

  const navContext = useContext(NavigationContext);
  const unsubscribe: Subject<void> = new Subject();

  useEffect(() => {
    reportService.canSubmit?.pipe(takeUntil(unsubscribe)).subscribe((canSubmit) => {
      setCanSubmit(canSubmit);
    });
    reportService.sideNavData?.pipe(takeUntil(unsubscribe)).subscribe((data) => {
      setSideNavData(data);
    });
    // subscribing section data to see if there is any unsaved data
    victimSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setSectionHasUnsavedData({
        ...sectionHasUnsavedData,
        [REPORT_ROUTES.VICTIM_SECTION]: isReadyToSave
      });
    });
    dateAndLocationSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setSectionHasUnsavedData({
        ...sectionHasUnsavedData,
        [REPORT_ROUTES.DATE_AND_LOCATION_SECTION]: isReadyToSave
      });
    });
    vehicleSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setSectionHasUnsavedData({
        ...sectionHasUnsavedData,
        [REPORT_ROUTES.VEHICLE_SECTION]: isReadyToSave
      });
    });
    suspectSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setSectionHasUnsavedData({
        ...sectionHasUnsavedData,
        [REPORT_ROUTES.SUSPECT_SECTION]: isReadyToSave
      });
    });
    sequenceOfEventsSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setSectionHasUnsavedData({
        ...sectionHasUnsavedData,
        [REPORT_ROUTES.EVENTS_SECTION]: isReadyToSave
      });
    });
    return () => {
      unsubscribe.next();
      unsubscribe.complete();
    };
  }, []);

  const redirectTo = (nextRoute: string) => () => {
    navContext.toggleNav(true);

    const currentLocation = location.pathname;

    // if i'm currently in dashboard, just navigate to the section
    if (currentLocation === REPORT_ROUTES.DASHBOARD) {
      history.push(nextRoute);
    }
    // if i'm currently in one of the sections
    else {
      // check if there is any unsaved data for this section
      if (sectionHasUnsavedData[currentLocation]) {
        // if there is unsaved data, don't navigate but notify the alert service
        alertService.alertLeavingSection({ nextRoute });
      } else {
        // if there is no unsaved data, navigate the user to the destination
        history.push(nextRoute);
      }
    }
  };

  const getNavTextColor = (sections: SectionType[], renderWhite?: boolean): Color => {
    if (renderWhite) {
      return 'white';
    }
    if (sections.some(section => sideNavData[section].hasBeenFilled)) {
      return 'purple';
    }
    return 'white';
  };

  return (
    <div className={navContext.showNav ? navStyle.container : navStyle.containerCollapsed}>
      <nav className={navStyle.nav}>
        <button className={navContext.showNav ? navStyle.mobileToggle : navStyle.mobileToggleCollapsed} onClick={() => {
          navContext.toggleNav();
        }}>{navContext.showNav ? 'HIDE SECTIONS' : 'SHOW SECTIONS'}
        </button>
        <img src={logoImage} alt='speak out logo' className={logoStyle.logo}/>
        <div className={navStyle.linksContainer}>
          <div className={layout.header}>
            <DefaultHeader color="white">
              {sideNav.heading}
            </DefaultHeader>
          </div>

          <ul className={navStyle.linkList}>
            <li className={navStyle.topLinkListItem}>
              <button role="link" className={navStyle.link} onClick={redirectTo(REPORT_ROUTES.VICTIM_SECTION)}>
                {location.pathname === REPORT_ROUTES.VICTIM_SECTION && (
                  <div className={navStyle.activeLinkIndicator}/>
                )}
                <label className={navStyle.linkLabel}>
                  <DefaultBodyText
                    letterSpacing="1px"
                    isBolded={location.pathname === REPORT_ROUTES.VICTIM_SECTION}
                    color={getNavTextColor(['victim'], location.pathname === REPORT_ROUTES.VICTIM_SECTION)}
                  >
                    {sideNav.links[0].label}
                  </DefaultBodyText>
                </label>
              </button>
            </li>

            <li className={navStyle.linkListItem}>
              <button role="link" className={navStyle.link}
                      onClick={redirectTo(REPORT_ROUTES.DATE_AND_LOCATION_SECTION)}>
                {location.pathname === REPORT_ROUTES.DATE_AND_LOCATION_SECTION && (
                  <div className={navStyle.activeLinkIndicator}/>
                )}
                <label className={navStyle.linkLabel}>
                  <DefaultBodyText
                    letterSpacing="1px"
                    isBolded={location.pathname === REPORT_ROUTES.DATE_AND_LOCATION_SECTION}
                    color={getNavTextColor(['date', 'location'], location.pathname === REPORT_ROUTES.DATE_AND_LOCATION_SECTION)}
                  >
                    {sideNav.links[1].label}
                  </DefaultBodyText>
                </label>
              </button>
            </li>

            <li className={navStyle.linkListItem}>
              <button role="link" className={navStyle.link} onClick={redirectTo(REPORT_ROUTES.VEHICLE_SECTION)}>
                {location.pathname === REPORT_ROUTES.VEHICLE_SECTION && (
                  <div className={navStyle.activeLinkIndicator}/>
                )}
                <label className={navStyle.linkLabel}>
                  <DefaultBodyText
                    letterSpacing="1px"
                    isBolded={location.pathname === REPORT_ROUTES.VEHICLE_SECTION}
                    color={getNavTextColor(['vehicle'], location.pathname === REPORT_ROUTES.VEHICLE_SECTION)}
                  >
                    {sideNav.links[2].label}
                  </DefaultBodyText>
                </label>
              </button>
            </li>

            <li className={navStyle.linkListItem}>
              <button role="link" className={navStyle.link} onClick={redirectTo(REPORT_ROUTES.SUSPECT_SECTION)}>
                {location.pathname === REPORT_ROUTES.SUSPECT_SECTION && (
                  <div className={navStyle.activeLinkIndicator}/>
                )}
                <label className={navStyle.linkLabel}>
                  <DefaultBodyText
                    letterSpacing="1px"
                    isBolded={location.pathname === REPORT_ROUTES.SUSPECT_SECTION}
                    color={getNavTextColor(['suspect'], location.pathname === REPORT_ROUTES.SUSPECT_SECTION)}
                  >
                    {sideNav.links[3].label}
                  </DefaultBodyText>
                </label>
              </button>
            </li>

            <li className={navStyle.linkListItem}>
              <button role="link" className={navStyle.link} onClick={redirectTo(REPORT_ROUTES.EVENTS_SECTION)}>
                {location.pathname === REPORT_ROUTES.EVENTS_SECTION && (
                  <div className={navStyle.activeLinkIndicator}/>
                )}
                <label className={navStyle.linkLabel}>
                  <DefaultBodyText
                    letterSpacing="1px"
                    isBolded={location.pathname === REPORT_ROUTES.EVENTS_SECTION}
                    color={getNavTextColor(['sequenceOfEvents'], location.pathname === REPORT_ROUTES.EVENTS_SECTION)}
                  >
                    {sideNav.links[4].label}
                  </DefaultBodyText>
                </label>
              </button>
            </li>
          </ul>

        </div>
        <div className={navStyle.buttonsContainer}>
          <SubmitFormButton disabled={!canSubmit || location.pathname !== REPORT_ROUTES.DASHBOARD}
                            aria-disabled={!canSubmit || location.pathname !== REPORT_ROUTES.DASHBOARD}/>
          <LeaveFormButton/>
        </div>
      </nav>
    </div>
  );
};

export default withRouter(SideNav);
