import { createUseStyles } from "react-jss";

export const resumeReportAlertStylesFactory = createUseStyles({
  container: {
    paddingTop: 48
  },
  selectionDescription: {
    marginTop: '30px'
  },
  selectionContainer: {
    marginTop: '20px'
  },
  reportCodeInputField: {
    marginTop: '20px'
  },
  passwordInputField: {
    marginTop: '10px'
  },
  continueButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    marginBottom: '35px'
  }
})
