import { Section } from '../../shared/models/section.model'
import { returnCallOutWithPassword, returnCallOutWithoutPassword } from '../../shared/models/return-call-out.model'
import { CheckboxesInputField, TextInputField, DropdownInputField } from '../../shared/models/question.model';
import { CHECKBOX_YES_NO_OPTIONS } from '../../constants';

interface WasVehicleInvolved {
  vehicleInvolved: CheckboxesInputField;
  make: TextInputField;
  model: TextInputField;
  colour: TextInputField;
  vehicleStyle: DropdownInputField;
  licensePlate: TextInputField;
  issuingProvince: DropdownInputField;
  distinctFeatures: TextInputField;
}

const wasVehicleInvolved: WasVehicleInvolved = {
  vehicleInvolved: {
    label: 'Was a vehicle involved?',
    options: CHECKBOX_YES_NO_OPTIONS
  },
  make: { label: 'Make' },
  model: { label: 'Model' },
  colour: { label: 'Colour' },
  vehicleStyle: {
    label: 'Vehicle style',
    options: [
      { value: 'Passenger vehicle', displayText: 'Passenger vehicle' },
      { value: 'Van', displayText: 'Van' },
      { value: 'Pick-up trick', displayText: 'Pick-up trick' },
      { value: 'Truck', displayText: 'Truck' },
      { value: 'Tractor-trailer', displayText: 'Tractor-trailer' },
      { value: 'Sport utility vehicle', displayText: 'Sport utility vehicle' },
      { value: 'Recreational vehicle / camper', displayText: 'Recreational vehicle / camper' },
      { value: 'Bicycle', displayText: 'Bicycle' },
      { value: 'Motorcycle', displayText: 'Motorcycle' },
      { value: 'All-terrain vehicle', displayText: 'All-terrain vehicle' },
      { value: 'Snowmobile', displayText: 'Snowmobile' },
      { value: 'Boat', displayText: 'Boat' },
      { value: 'Bus', displayText: 'Bus' },
      { value: 'Train', displayText: 'Train' },
      { value: 'Ferry', displayText: 'Ferry' },
      { value: 'Aircraft', displayText: 'Aircraft' },
      { value: 'Other', displayText: 'Other' }
    ]
  },
  licensePlate: {
    label: 'License plate',
    placeholder: '(Full or partial plates)'
  },
  issuingProvince: {
    label: 'Issuing Province or Territory',
    options: [
      {value: 'Alberta', displayText: 'Alberta'},
      {value: 'British Columbia', displayText: 'British Columbia'},
      {value: 'Manitoba', displayText: 'Manitoba'},
      {value: 'New Brunswick', displayText: 'New Brunswick'},
      {value: 'Newfoundland and Labrador', displayText: 'Newfoundland and Labrador'},
      {value: 'Nova Scotia', displayText: 'Nova Scotia'},
      {value: 'Ontario', displayText: 'Ontario'},
      {value: 'Prince Edward Island', displayText: 'Prince Edward Island'},
      {value: 'Québec', displayText: 'Québec'},
      {value: 'Saskatchewan', displayText: 'Saskatchewan'},
      {value: 'Northwest Territories', displayText: 'Northwest Territories'},
      {value: 'Nunavut', displayText: 'Nunavut'},
      {value: 'Yukon', displayText: 'Yukon'},
      { value: 'Other', displayText: 'Other' }
    ]
  },
  distinctFeatures: {
    label: 'Do you recall any distinct features / modifications about the vehicle? Anything you saw such as condition, modifications, or marks on the vehicle could help build a picture.'
  }
}

export interface VehicleSection extends Section {
  wasVehicleInvolved: WasVehicleInvolved;
}

export const vehicleSection: VehicleSection = {
  sectionHeader: 'Description of the Vehicle',
  description: 'Knowing if a vehicle was involved can help create a clearer picture of how a specific suspect/offender may operate.',
  returnCallOutWithPassword,
  returnCallOutWithoutPassword,
  wasVehicleInvolved
}