import { ReportService } from "../services/report-service/interfaces/report-service.interface";
import { MainReportService } from "../services/report-service/main-report-service";
import MainSectionService from "../services/create-section-service/main-section-service";
import { SectionService } from "../services/create-section-service/interfaces/section-service.interface";
import AuthHttpClient from "../clients/auth-http-client";
import MainAuthService from "../../auth/services/main-auth-service";
import MainHttpClient from "../clients/main-http-client";
import { AuthService } from "../../auth/services/auth-service.interface";
import { GoogleAnalyticsService } from "../services/analytics-service/google-analytics.service";
import { AnalyticsService } from "../services/analytics-service/analytics-service.interface";
import { RegionService } from '../services/region-service/interfaces/region-service.interface'
import { MainRegionService } from '../services/region-service/main-region-service'
import { AppInsightsService } from "../services/app-insights-service/app-insights-service.interface";
import { AppInsightsNullService } from "../services/app-insights-service/app-insights-null.service";
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { MicrosoftAppInsightsService } from "../services/app-insights-service/microsoft-app-insights.service";

class ServiceLocator {
  private static instance: ServiceLocator
  private readonly analyticsService: AnalyticsService
  private readonly reportService: ReportService
  private readonly sectionService: SectionService
  private readonly regionService: RegionService
  private readonly authService: AuthService
  private appInsightsService: AppInsightsService = new AppInsightsNullService()

  private constructor() {
    this.analyticsService = new GoogleAnalyticsService(process.env.REACT_APP_ANALYTICS_ID!);
    this.reportService = new MainReportService(this.getAuthHttpClient());
    this.sectionService = new MainSectionService(this.getAuthHttpClient());
    this.regionService = new MainRegionService(this.getAuthHttpClient());
    this.authService = new MainAuthService(this.getMainHttpClient(), this.getAuthHttpClient());
  }

  static getInstance() {
    if (!ServiceLocator.instance) {
      ServiceLocator.instance = new ServiceLocator()
      ServiceLocator.instance.initializeAppInsights()
    }
    return ServiceLocator.instance;
  }

  public getReportService() {
    return this.reportService;
  }

  public getSectionService() {
    return this.sectionService;
  }

  public getRegionService() {
    return this.regionService;
  }

  public getAuthService() {
    return this.authService;
  }

  public getAnalyticsService() {
    return this.analyticsService;
  }

  public getAppInsightsService(): AppInsightsService {
    return this.appInsightsService;
  }

  private getMainHttpClient() {
    return new MainHttpClient();
  }

  private getAuthHttpClient() {
    return new AuthHttpClient(this.getMainHttpClient());
  }

  private initializeAppInsights() {
    if (process.env.NODE_ENV !== 'development') {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING!,
          disableFetchTracking: true,
        }
      });
      appInsights.loadAppInsights();

      this.appInsightsService = new MicrosoftAppInsightsService(appInsights)
    }
  }
}

export default ServiceLocator;
