import React from 'react'
import Loader from 'react-loader-spinner'

// components
import BaseButton from './base-button'
import { colorPalette } from '../theme'

interface PrimaryActionButtonProps {
  onClick?: () => void,
  width?: string,
  isLoading?: boolean
}

const PrimaryActionButton: React.FunctionComponent<PrimaryActionButtonProps> = ({ children, onClick, width, isLoading }) => {
  if (isLoading) {
    return (
      <BaseButton
        borderColor="grape"
        backgroundColor="grape"
        fontColor="white"
        width={width}
      >
        <Loader type="ThreeDots" color={colorPalette.purple} height={10} width={30} />
      </BaseButton>
    )
  }

  return (
    <BaseButton
      borderColor="grape"
      backgroundColor="grape"
      fontColor="white"
      onClick={onClick}
      width={width}
    >
      {children?.toString().toUpperCase()} &#8594;
    </BaseButton>
  )
}

export default PrimaryActionButton
