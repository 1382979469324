// dependencies
import React from 'react';
import { createUseStyles } from 'react-jss';
import { BpTheme } from '../../theme';

// constants
type ImpactStatementSize = 'large' | 'medium'

const FONT_SIZE_TABLET: Readonly<Record<ImpactStatementSize, string>> = {
  medium: '3.44rem',
  large: '4rem'
};

const FONT_SIZE_MOBILE: Readonly<Record<ImpactStatementSize, string>> = {
  medium: '2.7rem',
  large: '3.125rem'
};

// props
interface Props {
  size?: ImpactStatementSize
}


// styles
const useStyles = createUseStyles((theme: BpTheme) => {
  return {
    impactStatementHeader: {
      color: theme.colorPalette.navy,
      fontSize: (props: Props) => props.size ? FONT_SIZE_TABLET[props.size] : FONT_SIZE_TABLET.large,
      lineHeight: '70px',
      letterSpacing: '1px',
      margin: 0,
      fontWeight: 500
    },
    [theme.mediaQueries.mobileOnly]: {
      impactStatementHeader: {
        letterSpacing: 0,
        lineHeight: '48px',
        fontSize: (props: Props) => props.size ? FONT_SIZE_MOBILE[props.size] : FONT_SIZE_MOBILE.large,
      }
    }
  };
});

// component definition
export const ImpactStatementHeader: React.FunctionComponent<Props> = (props) => {
  const { impactStatementHeader } = useStyles({ ...props });
  return (
    <h1 className={impactStatementHeader}>{props.children}</h1>
  );
};

ImpactStatementHeader.defaultProps = {
  size: 'large'
};
