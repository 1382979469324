import { createUseStyles } from "react-jss"
import { colorPalette } from "../../../shared/theme"

export const useLayout = createUseStyles({
  description: {
    paddingTop: '16px'
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '120px',
    alignItems: 'flex-end',
    paddingTop: '32px'
  },
  supportBlock: {
    marginTop: '32px'
  },
  sectionsList: {
    paddingTop: '25px',
    paddingLeft: '44px'
  },
  reportCode: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  subdescription: {
    marginTop: '16px'
  }
})

export const useStyle = createUseStyles({
  supportBlock: {
    boxSizing: 'border-box',
    border: `1px solid ${colorPalette.grape}`,
    borderRadius: '1rem',
    padding: '30px',
    height: '280px'
  }
})
