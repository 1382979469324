import React from 'react';
import { createUseStyles } from 'react-jss';

// theme
import { colorPalette } from '../../theme';

type DividerThickness = 'thin' | 'medium' | 'thick'

interface DividerProps {
  thickness?: DividerThickness,
  width?: string
}

const useStyles = createUseStyles({
  divider: {
    borderStyle: 'solid',
    background: colorPalette.purple,
    borderWidth: (props: DividerProps) => props.thickness,
    borderColor: colorPalette.purple,
    margin: 0,
    width: (props: DividerProps) => props.width
  }
});


export const Divider: React.FunctionComponent<DividerProps> = (props) => {
  const {divider} = useStyles({...props});
  return (
    <div className={divider}/>
  );
};

Divider.defaultProps = {
  thickness: 'thin',
  width: '25%'
};

export default Divider;
