import React from 'react';
import { createUseStyles } from 'react-jss';
import { BpTheme } from '../theme';
import { BpLink } from './atomic';

const useStyles = createUseStyles((theme: BpTheme) => ( {
  banner: {
    background: theme.colorPalette.navy,
    padding: [24, 34],
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center',
  },
  bannerPadding: {
    paddingTop: 108
  }
} ));

const ResourcesBanner: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.bannerPadding} />
      <div className={classes.banner}>
        <BpLink to="/resources" invert={true}>
          What can I do if the offence I want to report happened elsewhere? →
        </BpLink>
      </div>
    </>
  );
};

export default ResourcesBanner;
