import React, { useEffect, useState } from 'react'
import { createGrid } from '@nascentdigital/react-lattice';
import Background from '../../shared/components/background';
import backgroundTop from '../../assets/thankyou-top.png';
import backgroundBottom from '../../assets/thankyou-bottom.png';
import { Container, DefaultBodyText, DefaultHeader, Divider, ImpactStatementHeader } from '../../shared/components'
import PrimaryActionButton from '../../shared/components/primary-action-button';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { useThankYouPageStyles } from '../styles/thank-you-page-styles'
import alertService from '../../alerts/services/alert-service';
import HelplineResourcesLink from "../../shared/components/helpline-resources-link/helpline-resources-link"

const Grid = createGrid();

const reportService = ServiceLocator.getInstance().getReportService();
const analyticsService = ServiceLocator.getInstance().getAnalyticsService();

const ThankYouPage: React.FunctionComponent = () => {
  const styles = useThankYouPageStyles();
  const [contactInfo, setContactInfo] = useState()

  const handleGoHome = () => {
    alertService.clearAlert();
    window.location.href = '/';
  };

  useEffect(() => {
    analyticsService.viewPage('/thank-you');
  }, []);

  async function getContactInfo() {
    setContactInfo(`${reportService.region.regionContactName} at ${reportService.region.regionContactNumber}`)
  }

  useEffect(() => {
    getContactInfo()
  }, [])

  return (
    <Background backgroundImage={backgroundBottom} className={styles.backgroundBottom}
                backgroundPosition="center bottom">
      <Background backgroundImage={backgroundTop} className={styles.backgroundTop} opacity={0}>
        <Container>
          <Grid container justify='center'>
            <Grid item flex={{ xs: 12, sm: 8 }} className={styles.wrapper}>
              <div className={styles.impactStatementHeader}>
                <ImpactStatementHeader>Thank you for reporting.</ImpactStatementHeader>
              </div>
              <div className={styles.divider}>
                <Divider thickness="thick" width="12.5%"/>
              </div>
              <div className={styles.defaultHeader1}>
                <DefaultHeader>
                  Your report {reportService.formattedReportCode} has been submitted.
                </DefaultHeader>
              </div>
              <div className={styles.defaultBodyText1}>
                <DefaultBodyText>
                  We know that coming forward is not easy, it takes a lot of courage and strength. Thank you for
                  taking the time to share this information.
                  With this report, we can build clearer profiles against sexual
                  offenders and help stop this from happening to anyone else.
                </DefaultBodyText>
              </div>
              <div className={styles.defaultHeader2}>
                <DefaultHeader>What happens to my report?</DefaultHeader>
              </div>
              <div className={styles.defaultBodyText2}>
                <DefaultBodyText>
                  The information you provide will be entered into your local Police's database. This will allow the
                  relevant Police Unit to identify patterns in the community and ultimately stop offenders from harming
                  anyone else.
                </DefaultBodyText>
                {
                  contactInfo && (
                    <DefaultBodyText>
                      For more information, you can contact:
                      <br/>
                      {contactInfo}.
                    </DefaultBodyText>
                  )
                }
                <DefaultBodyText>
                  For your privacy and security, once your submission has been reviewed, the data may be cleared from
                  the database. As a result, should you return once it's been cleared, you will not be able to view the
                  information previously submitted, however you can continue to add more information to your report and
                  resubmit.
                </DefaultBodyText>
              </div>
              <div className={styles.buttonsContainer}>
                <div style={{ marginBottom: '34px' }}>
                  <PrimaryActionButton onClick={handleGoHome} width="190px">GO TO HOME</PrimaryActionButton>
                </div>
              </div>
              <div className={styles.helplineLink}>
                <HelplineResourcesLink/>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Background>
    </Background>
  );
};

export default ThankYouPage;
