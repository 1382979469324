import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { DefaultBodyText } from '../../shared/components';
import { Subject } from 'rxjs';
import snackBarService from '../services/snackbar-service';
import { takeUntil } from 'rxjs/operators';
import '../animations/snackbar.css';
import snackbarClose from '../../assets/snackbar-close.png';
import { SnackbarModel } from '../models/snackbar.model';
import { snackbarStylesFactory } from './snackbar.styles';

const stylesFactory = snackbarStylesFactory;

const SnackBar = () => {
  const styles = stylesFactory();
  const [model, setModel] = useState<SnackbarModel>();

  const handleCloseButtonClick = () => {
    snackBarService.dismissSnackbar();
  };

  useEffect(() => {
    const unsubscribe: Subject<void> = new Subject();
    snackBarService.model$.pipe(takeUntil(unsubscribe)).subscribe((model) => {
      setModel(model);
    });
  }, []);

  return (
    <CSSTransition
      in={model?.status}
      timeout={800}
      classNames="snackbar"
      unmountOnExit
    >
      <div className={styles.snackbar} role="alert">
        <img src={model?.imageSrc} alt={model?.imageAlt} className={styles.icon} aria-hidden="true"/>
        <div className={styles.snackbarText}>
          <DefaultBodyText fontSize="1rem" letterSpacing="1.14px" display='inherit'>
            <b>{model?.title}</b> {model?.description}
          </DefaultBodyText>
        </div>
        <input type='image' src={snackbarClose} alt='close icon' onClick={handleCloseButtonClick}
               className={styles.closeButton} aria-hidden="true"/>
      </div>
    </CSSTransition>
  );
};

export default SnackBar;
