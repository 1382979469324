import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  backgroundImage: React.ReactNode
  backgroundPosition?: string
  opacity?: number
}

const useStyles = createUseStyles({
  background: {
    backgroundImage: (props: Props) => `url(${props.backgroundImage})`,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: (props: Props) => props.backgroundPosition,
    backgroundColor: (props: Props) => `rgba(255, 255, 255, ${props.opacity !== undefined ? props.opacity : 1})`
  }
});


export const Background: React.FunctionComponent<Props> = (props) => {
  const { background } = useStyles(props);
  return (
    <div className={`${background} ${props.className || ''}`}>
      {props.children}
    </div>
  );
};

Background.defaultProps = {
  backgroundPosition: 'center top'
};

export default Background;
