import React from 'react';
import AnimateHeight from 'react-animate-height';
import FullPageBaseAlert from '../full-page-base-alert';
import { createGrid } from '@nascentdigital/react-lattice';
import { ImpactStatementHeader, DefaultBodyText, Container } from '../../../shared/components';
import ResumeReportOptionButtons from './components/resume-report-options/resume-report-options';
import PrimaryActionButton from '../../../shared/components/primary-action-button';
import AlertCancelButton from '../shared/alert-cancel-button/alert-cancel-button';
import { useResumeReportAlertController } from './resume-report-alert.controller';
import InactiveActionButton from '../../../shared/components/inactive-action-button';
import { resumeReportAlertStylesFactory } from './resume-report-alert.styles';
import AlertInputField from '../shared/alert-input-field/alert-input-field';

const Grid = createGrid();
const useStyles = resumeReportAlertStylesFactory;

const ResumeReportAlert = () => {

  const {
    isContinuable,
    reportCode,
    reportCodeErrorMessage,
    reportPassword,
    reportPasswordErrorMessage,
    buttonSelected,
    isLoading,
    handleCancelClick,
    handleReportCodeChange,
    handleReportPasswordChange,
    handleButtonSelectionChange,
    handleContinue
  } = useResumeReportAlertController();

  const styles = useStyles();

  return (
    <FullPageBaseAlert showReportCodeDescription={buttonSelected === 'reportCodeAndPassword'}>
      <AlertCancelButton buttonLabel="CANCEL" handleCancel={handleCancelClick}/>
      <div className={styles.container}>
        <Container>
          <Grid container justify="center">
            <Grid item flex={{ xs: 11, md: 6 }}>
              <ImpactStatementHeader size="medium">Resume your report</ImpactStatementHeader>
              <div className={styles.selectionDescription} id='loginOption'>
                <DefaultBodyText>To start where you left off, please select an option below:</DefaultBodyText>
              </div>
              <div className={styles.selectionContainer}>
                <ResumeReportOptionButtons buttonSelected={buttonSelected}
                                           setButtonSelected={handleButtonSelectionChange}/>
              </div>
              <div className={styles.reportCodeInputField}>
                <AlertInputField
                  onChange={(e) => handleReportCodeChange(e.target.value)}
                  value={reportCode}
                  label="Report Code"
                  errorMessage={reportCodeErrorMessage}
                  fieldType="reportCode"
                  isInvalid={!!reportPasswordErrorMessage}
                  id='reportCode'
                />
              </div>
              <AnimateHeight
                duration={800}
                height={buttonSelected === 'reportCodeAndPassword' ? 'auto' : 0}
              >
                <div className={styles.passwordInputField}>
                  <AlertInputField
                    onChange={(e) => handleReportPasswordChange(e.target.value)}
                    value={reportPassword}
                    label="Password"
                    errorMessage={reportPasswordErrorMessage}
                    fieldType="reportPassword"
                    id='password'
                  />
                </div>
              </AnimateHeight>
              <div className={styles.continueButton}>
                {
                  isContinuable ? (
                    <PrimaryActionButton
                      onClick={handleContinue}
                      width="180px"
                      isLoading={isLoading}>
                      Continue
                    </PrimaryActionButton>
                  ) : (
                    <InactiveActionButton width="180px">Continue</InactiveActionButton>
                  )
                }
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </FullPageBaseAlert>
  );
};

export default ResumeReportAlert;
