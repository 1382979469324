import { useEffect } from "react"
import vehicleSectionFormService from "../../services/vehicle-section-form-service"
import ServiceLocator from "../../../shared/service-locator/service-locator"

const reportService = ServiceLocator.getInstance().getReportService()

export function useVehicleWasInvolvedController() {

  const vehicleSectionSavedData = reportService.vehicleSectionSavedData

  const initialVehicleMakeValue = vehicleSectionSavedData?.vehicleMake?.inputFieldValue as string || ''
  const initialVehicleModelValue = vehicleSectionSavedData?.vehicleModel?.inputFieldValue as string || ''
  const initialVehicleColourValue = vehicleSectionSavedData?.vehicleColour?.inputFieldValue as string || ''
  const initialVehicleStyleValue = vehicleSectionSavedData?.vehicleStyle?.inputFieldValue as string || ''
  const initialLicensePlateValue = vehicleSectionSavedData?.licensePlate?.inputFieldValue as string || ''
  const initialIssuingProvinceTerritory = vehicleSectionSavedData?.issuingProvinceTerritory?.inputFieldValue as string || ''
  const initialDistinctFeaturesAboutVehicleValue = vehicleSectionSavedData?.distinctFeaturesAboutVehicle?.inputFieldValue as string || ''

  const vehicleMakeHasBeenFilled = !!vehicleSectionSavedData?.vehicleMake
  const vehicleModelHasBeenFilled = !!vehicleSectionSavedData?.vehicleModel
  const vehicleColourHasBeenFilled = !!vehicleSectionSavedData?.vehicleColour
  const vehicleStyleHasBeenFilled = !!vehicleSectionSavedData?.vehicleStyle
  const licensePlateHasBeenFilled = !!vehicleSectionSavedData?.licensePlate
  const issuingProvinceTerritoryHasBeenFilled = !!vehicleSectionSavedData?.issuingProvinceTerritory
  const distintFeaturesAboutVehicleHasBeenFilled = !!vehicleSectionSavedData?.distinctFeaturesAboutVehicle

  const handleVehicleMakeChange = (value: string) => vehicleSectionFormService.setVehicleMake(value)
  const handleVehicleModelChange = (value: string) => vehicleSectionFormService.setVehicleModel(value)
  const handleVehicleColourChange = (value: string) => vehicleSectionFormService.setVehicleColour(value)
  const handleVehicleStyleChange = (value: string) => vehicleSectionFormService.setVehicleStyle(value)
  const handleLicensePlateChange = (value: string) => vehicleSectionFormService.setLicensePlate(value)
  const handleIssuingProvinceTerritoryChange = (value: string) => vehicleSectionFormService.setIssuingProvinceTerritory(value)
  const handleDistinctFeaturesAboutVehicleChange = (value: string) => vehicleSectionFormService.setDistinctFeaturesAboutVehicle(value)

  useEffect(() => {
    return () => { vehicleSectionFormService.clearVehicleWasInvolvedForm() }
  }, [])

  return {
    initialVehicleMakeValue,
    initialVehicleModelValue,
    initialVehicleColourValue,
    initialVehicleStyleValue,
    initialLicensePlateValue,
    initialIssuingProvinceTerritory,
    initialDistinctFeaturesAboutVehicleValue,
    vehicleMakeHasBeenFilled,
    vehicleModelHasBeenFilled,
    vehicleColourHasBeenFilled,
    vehicleStyleHasBeenFilled,
    licensePlateHasBeenFilled,
    issuingProvinceTerritoryHasBeenFilled,
    distintFeaturesAboutVehicleHasBeenFilled,
    handleVehicleMakeChange,
    handleVehicleModelChange,
    handleVehicleColourChange,
    handleVehicleStyleChange,
    handleLicensePlateChange,
    handleIssuingProvinceTerritoryChange,
    handleDistinctFeaturesAboutVehicleChange
  }

}
