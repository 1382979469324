import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { colorPalette } from '../../shared/theme'
import PrimaryActionButton from '../../shared/components/primary-action-button'
import { onboarding } from '../models/onboarding.model'
import ServiceLocator from '../../shared/service-locator/service-locator'
import InactiveActionButton from '../../shared/components/inactive-action-button'
import BaseButton from '../../shared/components/base-button'
import notificationService from '../../notification/services/notification-service'

interface GetStartedButtonProps {
  reporterType: string;
  consentChecked: boolean;
  onStartLoading: () => void;
}

const reportService = ServiceLocator.getInstance().getReportService()
const analyticsService = ServiceLocator.getInstance().getAnalyticsService()

const GetStartedButton: React.FC<GetStartedButtonProps & RouteComponentProps> = ({ consentChecked, reporterType, history, onStartLoading }) => {
  const [ isReportLoading, setIsReportLoading ] = useState<boolean>(false)

  const handleClick = async () => {
    try {
      onStartLoading()
      setIsReportLoading(true)
      await reportService.createReport(reporterType)
      await reportService.resumeReport()
      setIsReportLoading(false)

      analyticsService.logEvent('start_report', {
        region_name: reportService.region.regionName
      })

      history.push('/report/dashboard')
    } catch (e) {
      setIsReportLoading(false)
      notificationService.notifyServerError()
    }
  }

  if (!reporterType || !consentChecked) {
    return (
      <InactiveActionButton width="180px">
        {onboarding.button.label}
      </InactiveActionButton>
    )
  }

  if (isReportLoading) {
    return (
      <BaseButton borderColor="grape" backgroundColor="grape" fontColor="white" width="180px">
        <Loader type="ThreeDots" color={colorPalette.purple} height={10} width={30} />
      </BaseButton>
    )
  }

  return (
    <PrimaryActionButton onClick={handleClick} width="180px">
      {onboarding.button.label}
    </PrimaryActionButton>
  )
}

export default withRouter(GetStartedButton)

