import { Section } from '../../shared/models/section.model'
import { TextInputField, DropdownInputField } from '../../shared/models/question.model'
import { victimSectionContentEn } from './victim-section.content-en'

export interface VictimSectionContent extends Section {
  age: TextInputField,
  gender: DropdownInputField
  victimRaceAppearance: DropdownInputField
}

export const victimSectionContentProvider = (): VictimSectionContent => {
  return victimSectionContentEn
}
