import React from 'react';
import { createUseStyles } from 'react-jss';
import { createGrid } from '@nascentdigital/react-lattice';
import { breakpoints } from '../theme';

// components
import Checkbox from './checkbox';
import { DefaultBodyText } from './atomic';

interface Props {
  options: string[];
  // onChange: (newValue: string) => void;
  selected: string;
  onChange: (value: string) => void;
  hasBeenFilled?: boolean;
  ariaDescribedby: string;
}

const Grid = createGrid({ breakpoints });

const useStyles = createUseStyles({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    marginRight: '16px',
  }
});

const CheckboxSelection: React.FunctionComponent<Props> = ({ options, selected, onChange, hasBeenFilled, ariaDescribedby }) => {

  const styles = useStyles();

  const handleClick = (option: string) => () => {
    let newValue = '';
    // if clicked already checked one, uncheck it
    if (selected !== option) {
      newValue = option;
    }
    onChange(newValue);
  };

  return (
    <Grid container>
      {
        options.map((option) => {
          return (
            <Grid item flex={{ xs: 12, md: 4, lg: 3 }} key={option}>
              <div className={styles.checkboxContainer}>
                <div className={styles.checkbox}>
                  <Checkbox
                    isChecked={option === selected}
                    onClickCallback={handleClick(option)}
                    hasBeenFilled={hasBeenFilled}
                    id={`${ariaDescribedby}${option}`}
                    ariaDescribedby={ariaDescribedby}
                  />
                </div>
                <label htmlFor={`${ariaDescribedby}${option}`}>
                  <DefaultBodyText color={hasBeenFilled ? 'grape' : 'navy'}>{option}</DefaultBodyText>
                </label>
              </div>
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export default CheckboxSelection;
