import { createUseStyles } from "react-jss";
import { BpTheme, colorPalette } from "../../shared/theme";

export const snackbarStylesFactory = createUseStyles((theme: BpTheme) => ({
  snackbar: {
    border: `1px solid ${colorPalette.orangeRed}`,
    borderRadius: '8px',
    background: colorPalette.peach,
    position: 'fixed',
    zIndex: 12,
    bottom: 32,
    boxSizing: 'border-box',
    boxShadow: '0 0 20px #CCCCCC',
    left: 16,
    right: 16,
    padding: 24,
    [theme.mediaQueries.tabletAndAbove]: {
      position: 'fixed',
      padding: [30, 60],
      textAlign: 'center',
      bottom: '5%',
      maxWidth: 850
    },
    [theme.mediaQueries.tabletOnly]: {
      right: '10%',
      left: '35%'
    },
    [theme.mediaQueries.desktopAndAbove]: {
      right: '10%',
      left: '28%'
    }
  },
  closeButton: {
    width: '50px',
    height: '50px',
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  icon: {
    height: '16px'
  },
  snackbarText: {
    display: 'inline',
    paddingLeft: '12px',
    verticalAlign: 'bottom'
  }
}));
