import { Section } from "../../shared/models/section.model";
import { TextInputField } from "../../shared/models/question.model";
import { sequenceOfEventsSectionContentEn } from "./sequence-of-events-section.content-en";

interface AssistanceBlock {
  header: string;
  helpfulQuestions: string[]
}

export interface SequenceOfEventsSectionContent extends Section {
  eventsDescription: TextInputField;
  assistanceBlock: AssistanceBlock;
}

export const sequenceOfEventsSectionContentProvider = (): SequenceOfEventsSectionContent => {
  return sequenceOfEventsSectionContentEn
}
