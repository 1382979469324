import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { BpTheme, colorPalette } from '../../shared/theme';
import AnimateHeight from 'react-animate-height';
import { DefaultBodyText, Background } from '../../shared/components';
import backgroundImageSrc from '../../assets/background-home.png';

const useModalStyles = createUseStyles((theme: BpTheme) => ( {
  fullPageModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    background: theme.colorPalette.white,
    overflow: 'auto',
    paddingBottom: 136,
    [theme.mediaQueries.tabletAndAbove]: {
      paddingBottom: 48
    }
  },
  background: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    opacity: 0.4,
    zIndex: -1
  },
  cont: {
    bottom: 0,
    zIndex: 11,
    left: 0,
    right: 0,
    position: 'fixed',
    background: colorPalette.lavender
  },
  reportCodeDescription: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  descriptionContainer: {
    padding: 24,
    maxWidth: '500px'
  }
} ));

interface IBaseAlert {
  children: React.ReactNode;
  showReportCodeDescription?: boolean;
}

const FullPageBaseAlert: React.FunctionComponent<IBaseAlert> = ({ children, showReportCodeDescription }) => {

  const modalStyles = useModalStyles();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <>
      <div className={modalStyles.fullPageModal} role='dialog' aria-labelledby='resumeReportHeader' aria-modal='true'>
        <Background backgroundImage={backgroundImageSrc} opacity={0.4} className={modalStyles.background}/>
        {children}
      </div>
      <div
        className={modalStyles.cont}>
        <AnimateHeight
          duration={800}
          height={showReportCodeDescription ? 'auto' : 0}>
          <div className={modalStyles.reportCodeDescription}>
            <div className={modalStyles.descriptionContainer}>
              <DefaultBodyText>
                *In the event that you’ve forgotten your password, resume with ‘Report Code Only’ and your report will
                default to write - only.
              </DefaultBodyText>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </>
  );
};

FullPageBaseAlert.defaultProps = {
  showReportCodeDescription: false
};

export default FullPageBaseAlert;
