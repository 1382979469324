import React, { useEffect } from 'react';
import { createUseStyles } from "react-jss";

import Header from './body/home-header';
import { Divider, BrandedContainer } from '../../shared/components';
import Description from './body/home-description';
import ResumeDescription from './body/home-resume-description';
import ResourcesBanner from '../../shared/components/resources-banner';
import NotificationContainer from '../../notification/components/notification-container';
import ResumeReportButton from './body/home-resume-report-button/home-resume-report-button';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { BpTheme } from "../../shared/theme";
import HomeSelectRegion from './body/home-select-region/home-select-region'

const analyticsService = ServiceLocator.getInstance().getAnalyticsService();

export const useStyles = createUseStyles((theme: BpTheme) => ({
  [theme.mediaQueries.mobileOnly]: {
    regionSelect: {
      padding: [0, 31],
      flexDirection: 'column'
    },
    button: {
      padding: [0, 31]
    }
  },
  [theme.mediaQueries.tabletAndAbove]: {
    regionSelect: {
      alignItems: 'flex-end',
    },
    button: {
      justifyContent: 'flex-end',
    }
  },
  divider: {
    marginTop: '12px'
  },
  description: {
    marginTop: '30px'
  },
  resumeDescription: {
    marginTop: '50px'
  },
  regionSelect: {
    display: 'flex',
    '&> *': {
      marginTop: '16px',
    }
  },
  button: {
    display: 'flex',
    marginTop: '16px'
  },
  reportCodeInputField: {
    marginTop: '70px',
    paddingBottom: '50px',
    position: 'relative'
  },
  footer: {
    position: 'relative',
    height: '155px',
    marginTop: '-155px',
    clear: 'both',
    backgroundColor: theme.colorPalette.navy
  }
}));


const HomePage = () => {
  const classes = useStyles();

  useEffect(() => {
    analyticsService.viewPage('/home');
  }, []);

  return (
    <>
      <NotificationContainer width="100%"/>
      <BrandedContainer>
        <Header/>
        <div className={classes.divider}>
          <Divider width="18%" thickness="thick"/>
        </div>
        <div className={classes.description}>
          <Description/>
        </div>
        <div className={classes.regionSelect}>
          <HomeSelectRegion/>
        </div>
        <div className={classes.resumeDescription}>
          <ResumeDescription/>
        </div>
        <div className={classes.button}>
          <ResumeReportButton/>
        </div>
        <div style={{paddingBottom: '30px'}}/>
      </BrandedContainer>
      <ResourcesBanner/>
    </>
  );
};

export default HomePage;
