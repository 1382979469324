export interface Region {
  regionId: number
  regionName: string
  regionContactName: string
  regionContactNumber: string
  isOther: boolean
}

export interface RegionService {
  getRegions(): Promise<Region[]>
  getRegion(id: number): Promise<Region>
}

export class OtherRegion implements Region {
  regionId: number = -1
  regionName: string = 'Other'
  regionContactName: string = ''
  regionContactNumber: string = ''
  isOther = true
}
