import { createUseStyles } from 'react-jss'

export const useReportSectionHeaderStyles = createUseStyles(() => ({
  header: {
    paddingTop: '55px'
  },
  link: {
    marginTop: '16px'
  }
}))
