import { FirstTimeSaveAlertContent } from './first-time-save-alert.content'
import iconWriteOnly from '../assets/no-password/iconWriteOnly.png'
import iconHidden from '../assets/no-password/iconHidden.png'
import iconLogin from '../assets/no-password/iconLogin.png'
import iconCheck from '../assets/with-password/iconCheck.png'
import iconShown from '../assets/with-password/iconShown.png'
import iconKey from '../assets/with-password/iconKey.png'
import closePurple from '../assets/closePurple.png'

export const firstTimeSaveAlertContentEn: FirstTimeSaveAlertContent = {
    closeImgSrc: closePurple,
    closeImgAlt: 'closePurple',
    notNowButtonLabel: 'NOT NOW',
    header: 'Your report, your choice.',
    description: 'If you choose to create a password, you’ll be able to return to your report and see what you’ve previously entered. This is a one-time selection, or you can select ‘Not Now’ and decide later.',
    noPasswordModel: {
        header: 'No password',
        items: [{
            imgSrc: iconWriteOnly,
            imgAlt: 'iconWriteOnly',
            label: 'Write-only'
        }, {
            imgSrc: iconHidden,
            imgAlt: 'iconHidden',
            label: 'Cannot see previous answers'
        }, {
            imgSrc: iconLogin,
            imgAlt: 'iconWriteOnly',
            label: 'Report code only to login'
        }]
    },
    withPasswordModel: {
        header: 'With a password',
        items: [{
            imgSrc: iconCheck,
            imgAlt: 'iconCheck',
            label: 'Read & write*'
        }, {
            imgSrc: iconShown,
            imgAlt: 'iconShown',
            label: 'Can read/edit previous answers'
        }, {
            imgSrc: iconKey,
            imgAlt: 'iconKey',
            label: 'Report code + password '
        }]
    },
    footNote: '*If you forget your password, your report defaults to write-only',
    createPasswordButtonLabel: 'CREATE PASSWORD',
    doNotCreatePasswordButtonLabel: 'NO PASSWORD'
}
