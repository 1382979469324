import React from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import { SuspectForm } from '../../services/suspect-section-form-service';
import { useFormStyles } from '../../../shared/theme/form-styles';
import { breakpoints } from '../../../shared/theme';
import { suspectSection } from '../../models/suspect-section.model';
// components
import { DefaultBodyText } from '../../../shared/components';
import TextInputField from '../../../shared/components/text-input-field';
import Dropdown from '../../../shared/components/dropdown';
import { usePhysicalFeaturesController } from './physical-features.controller';

const Grid = createGrid({ breakpoints });
const model = suspectSection.physicalFeatures;

interface Props {
  form: SuspectForm
}

const PhysicalFeatures: React.FunctionComponent<Props> = ({ form }) => {
  const formStyles = useFormStyles();
  const {
    initialApproximateAge,
    initialApproximateHeight,
    initialRaceAppearance,
    initialSkinColour,
    initialHairColour,
    initialSuspectGender,
    initialBuildDescription,
    initialDistinctFeatures,
    handleApproximateAgeChange,
    handleApproximateHeightChange,
    handleRaceAppearanceChange,
    handleSkinColourChange,
    handleHairColourChange,
    handleSuspectGenderChange,
    handleBuildDescriptionChange,
    handleDistinctFeaturesChange
  } = usePhysicalFeaturesController(form);

  return (
    <div>
      <div className={formStyles.questionSectionTitle}>
        <DefaultBodyText isBolded={true}>{model.header}</DefaultBodyText>
      </div>
      <div className={formStyles.questionSectionFirstQuestion}>
        <DefaultBodyText>{model.description}</DefaultBodyText>
      </div>
      <Grid container justify='center'>
        <Grid item flex={{ xs: 12, md: 10 }}>
          <div className={formStyles.conditionalQuestionContainer}>
            <Grid container
                  className={formStyles.questionGroupInternalPadding}>
              <Grid item flex={{ xs: 12, md: 6 }}>
                <Dropdown
                  initialValue={initialApproximateAge}
                  label={model.approximateAge.label}
                  options={model.approximateAge.options}
                  onChange={handleApproximateAgeChange}
                  hasBeenFilled={form.hasBeenFilled.approximateAge}
                  id='age'
                />
              </Grid>
              <Grid item flex={{ xs: 12, md: 6 }}>
                <Dropdown
                  initialValue={initialApproximateHeight}
                  label={model.approximateHeight.label}
                  options={model.approximateHeight.options}
                  onChange={handleApproximateHeightChange}
                  hasBeenFilled={form.hasBeenFilled.approximateHeight}
                  id='height'
                />
              </Grid>
            </Grid>
            <Grid container
                  className={formStyles.questionGroupInternalPadding}>
              <Grid item flex={{ xs: 12, md: 6 }}>
                <Dropdown
                  initialValue={initialRaceAppearance}
                  label={model.raceAppearance.label}
                  options={model.raceAppearance.options}
                  onChange={handleRaceAppearanceChange}
                  hasBeenFilled={form.hasBeenFilled.raceAppearance}
                  isComboBox={true}
                  id='raceAppearance'
                />
              </Grid>
              <Grid item flex={{ xs: 12, md: 6 }}>
                <Dropdown
                  initialValue={initialSkinColour}
                  label={model.skinColour.label}
                  options={model.skinColour.options}
                  onChange={handleSkinColourChange}
                  hasBeenFilled={form.hasBeenFilled.skinColour}
                  id='skinColor'
                />
              </Grid>
            </Grid>
            <Grid container
                  className={formStyles.questionGroupInternalPadding}>
              <Grid item flex={{ xs: 12, md: 6 }}>
                <Dropdown
                  initialValue={initialHairColour}
                  label={model.hairColour.label}
                  options={model.hairColour.options}
                  onChange={handleHairColourChange}
                  hasBeenFilled={form.hasBeenFilled.hairColour}
                  isComboBox={true}
                  id='hairColor'
                />
              </Grid>
              <Grid item flex={{ xs: 12, md: 6 }}>
                <Dropdown
                  initialValue={initialSuspectGender}
                  label={model.suspectGender.label}
                  options={model.suspectGender.options}
                  onChange={handleSuspectGenderChange}
                  hasBeenFilled={form.hasBeenFilled.suspectGender}
                  isComboBox={true}
                  id='suspectGender'
                />
              </Grid>
            </Grid>
            <div className={formStyles.question}>
              <TextInputField
                initialValue={initialBuildDescription}
                label={model.descriptionOfBuild.label}
                onChange={handleBuildDescriptionChange}
                hasBeenFilled={form.hasBeenFilled.buildDescription}
                id='build'
              />
            </div>
            <div className={formStyles.question}>
              <TextInputField
                initialValue={initialDistinctFeatures}
                label={model.distinctFeatures.label}
                onChange={handleDistinctFeaturesChange}
                hasBeenFilled={form.hasBeenFilled.distinctFeatures}
                id='feature'
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhysicalFeatures;
