import React from 'react';
import { useFormStyles } from '../../shared/theme/form-styles';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { vehicleSection as model } from '../models/vehicle-section.model';
import FormCtaButtons from '../../shared/components/form-cta-buttons';
import InfoBlock from '../../shared/components/info-block';
import { DefaultBodyText, Divider } from '../../shared/components'
import BackToDashboardButton from '../../shared/components/back-to-dashboard-button';
import WasVehicleInvolvedQuestion from './was-vehicle-involved-question/was-vehicle-involved-question';
import { useVehicleSectionController } from './vehicle-section.controller';
import ReportSectionHeader from "../../shared/components/report-section-header/report-section-header"

const reportService = ServiceLocator.getInstance().getReportService();

const VehicleSection: React.FunctionComponent = () => {

  const formStyles = useFormStyles();

  const {
    isSaveAndCloseLoading,
    isSaveAndNextSectionLoading,
    isReadyToSave,
    handleSaveAndClose,
    handleSaveAndNextSection,
    showReturnCallOut
  } = useVehicleSectionController();

  return (
    <div className={formStyles.page}>
      <div className={formStyles.backButton}>
        <BackToDashboardButton leavingWhileDataIsUnsaved={isReadyToSave}/>
      </div>

      <ReportSectionHeader headerName={model.sectionHeader}></ReportSectionHeader>

      <div className={formStyles.infoBlock}>
        <InfoBlock>
          <DefaultBodyText>{model.description}</DefaultBodyText>
          {showReturnCallOut && (
            <>
              <br/>
              <DefaultBodyText>
                <b
                  style={{display: 'contents'}}>{reportService.hasReportPassword ? model.returnCallOutWithPassword[0].text : model.returnCallOutWithoutPassword[0].text}</b>
                <p
                  style={{display: 'contents'}}>{reportService.hasReportPassword ? model.returnCallOutWithPassword[1].text : model.returnCallOutWithoutPassword[1].text}</p>
              </DefaultBodyText>
            </>
          )}
        </InfoBlock>
      </div>
      <div className={formStyles.divider}>
        <Divider width="100px" thickness="medium"/>
      </div>
      <div className={formStyles.question}>
        <WasVehicleInvolvedQuestion/>
      </div>
      <div className={formStyles.buttonsContainer}>
        <FormCtaButtons
          isReadyToSave={isReadyToSave}
          onSaveAndClose={handleSaveAndClose}
          isSaveAndCloseLoading={isSaveAndCloseLoading}
          onSaveAndNextSection={handleSaveAndNextSection}
          isSaveAndNextSectionLoading={isSaveAndNextSectionLoading}
        />
      </div>
    </div>
  );
};

export default VehicleSection;
