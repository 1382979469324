import { Button } from "../../shared/models/button.model";
import { Content } from '../../shared/models/content.model';

interface Onboarding {
  heading: string;
  headingDescription: Content[];
  subhead1: string;
  subhead1Description: string;
  subhead2: string;
  subhead2Description: string;
  anonymityStatement: string;
  ageStatement: string;
  button: Button
}

export const onboarding: Onboarding = {
  heading: 'Before you get started',
  headingDescription: [
    {
      text: 'Speak Out is a private and secure reporting tool piloting in ',
      isBolded: false
    },
    {
      text: 'select cities in Ontario. ',
      isBolded: true
    },
    {
      text: 'All reports will be submitted anonymously; you never have to provide your name or identifying information. You will be issued a confidential report code for your reference.',
      isBolded: false
    }
  ],
  subhead1: 'Creating a report',
  subhead1Description: 'You may choose to complete this report in its entirety, or in phases if you would prefer to do so. Any information you provide will be privately and securely stored until you are ready to submit. With your report code, you can come back to your report to add any additional information, until the report has been submitted.',
  subhead2: 'What happens when I submit a report?',
  subhead2Description: 'The information you provide will be entered into a secure Police database. This will allow relevant Police Units to identify patterns in the community and ultimately stop suspects from harming anyone else.',
  anonymityStatement: 'I understand that if I choose to make a formal report to police in the future this report will no longer be anonymous and will be a part of the police report.',
  ageStatement: 'By continuing, I declare that I am 18 years or older.',
  button: {
    label: 'Get started'
  }
};
