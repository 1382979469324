import React from 'react';
import { createUseStyles } from 'react-jss';

// theme
import { Color, BpTheme } from '../../theme';

interface Props extends React.ComponentPropsWithoutRef<'h2'> {
  color?: Color
}

const useStyles = createUseStyles((theme: BpTheme) => ( {
  defaultHeader: {
    color: (props: Props) => props.color ? theme.colorPalette[props.color] : theme.colorPalette.navy,
    ...theme.fontStylesMobile.subHeading,
    [theme.mediaQueries.tabletAndAbove]: {
      ...theme.fontStylesTablet.subHeading
    }
  }
} ));


export const DefaultHeader: React.FunctionComponent<Props> = (props) => {
  const { children, className, color } = props;
  const classes = useStyles({ color });
  const classNames = [classes.defaultHeader];
  if (className) {
    classNames.push(className)
  }
  return (
    <h2 className={`${classNames.join(' ')}`}>{children}</h2>
  );
};

DefaultHeader.defaultProps = {
  color: 'navy'
};
