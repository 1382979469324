import React from 'react'

// components
import BaseButton from './base-button'

// theme
import { Color } from '../theme'

interface TertiaryButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  color: Color;
  width?: string;
  inactive?: boolean;
  isUnderlined?: boolean;
  className?: string
}

const TertiaryButton: React.FunctionComponent<TertiaryButtonProps> = ({ children, color, width, onClick, inactive, isUnderlined , className}) => {
  return (
    <BaseButton
      onClick={onClick}
      fontColor={color}
      borderColor="none"
      backgroundColor="none"
      isUnderlined={isUnderlined}
      width={width}
      padding="5px"
      inactive={inactive}
      className={className}
    >
      {children?.toString().toUpperCase()}
    </BaseButton>
  )
}

TertiaryButton.defaultProps = {
  width: 'fit-content',
  inactive: false,
  isUnderlined: true
}

export default TertiaryButton
