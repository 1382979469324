import React, { createRef, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { BpTheme, breakpoints } from '../../shared/theme';
import { createGrid } from '@nascentdigital/react-lattice';

// theme
import Background from '../../shared/components/background';
import AlertBackground from '../../assets/alert-background.png';
import alertService from '../services/alert-service';

const useModalStyles = createUseStyles((theme: BpTheme) => ( {
  baseModal: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 20
  },
  blur: {
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(12px)',
  },
  opaque: {
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    background: theme.colorPalette.white,
    opacity: '0.97',
  },
  background: {
    overflow: 'auto',
    backgroundColor: 'rgba(255,255,255, 0.97)',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  contentWrapper: {
    width: '100%',
    maxWidth: theme.breakpoints.md,
    margin: 'auto',
    padding: 32,
    [theme.mediaQueries.tabletAndAbove]: {
      padding: [64, 36]
    },
    [theme.mediaQueries.desktopAndAbove]: {
      paddingTop: 182
    }
  }
} ));

const Grid = createGrid({ breakpoints });

const BaseAlert = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ children }, ref) => {
    const modalStyles = useModalStyles();
    const modal = createRef<HTMLDivElement>();

    // must focus the element for the key down to immediately trigger
    useEffect(() => {
      modal.current?.focus();
    }, []);

    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === 27) {
        alertService.clearAlert();
      }
    };

    return (
        <div ref={modal} className={modalStyles.baseModal} role='dialog' aria-modal='true' onKeyUp={handleKeyUp}
             tabIndex={0}>
          <div className={modalStyles.blur}>
            <Background backgroundImage={AlertBackground} backgroundPosition="center bottom"
                        className={modalStyles.background}>
              <Grid container justify="center" className={modalStyles.contentWrapper}>
                <Grid item flex={{ xs: 12 }}>
                  {children}
                </Grid>
              </Grid>
            </Background>
          </div>
        </div>
    );
  }
);

export default BaseAlert;
