import { DropdownOption } from "../components/dropdown";

export const genderOptions: DropdownOption[] = [
  { value: 'Agender', displayText: 'Agender' },
  { value: 'Bigender', displayText: 'Bigender' },
  { value: 'Cis Man (Male)', displayText: 'Cis Man (Male)' },
  { value: 'Cis Woman (Female)', displayText: 'Cis Woman (Female)' },
  { value: 'Gender Fluid', displayText: 'Gender Fluid' },
  { value: 'Gender Nonconforming', displayText: 'Gender Nonconforming' },
  { value: 'Trans', displayText: 'Trans' },
  { value: 'Other', displayText: 'Other' },
]
