import React from 'react'
import SecondaryActionButton from '../../../../shared/components/secondary-action-button'
import { homeContentProvider } from '../../../contents/home.content'
import { useHomeResumeReportButtonController } from './home-resume-report-button.controller'

const content = homeContentProvider()

const ResumeReportButton = () => {

  const { handleButtonClick } = useHomeResumeReportButtonController()

  return (
    <SecondaryActionButton width="210px" arrowDirection="right" onClick={handleButtonClick}>
      {content.resumeReportButtonLabel}
    </SecondaryActionButton>
  )
}

export default ResumeReportButton
