import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'

// theme
import { colorPalette } from '../../shared/theme'

const useStyles = createUseStyles({
  checkbox: {
    outline: 'none',
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    border: `1px solid ${colorPalette.grape}`,
    borderRadius: '0.5rem',
    minWidth: '40px',
    minHeight: '40px',
    '&:hover': {
      'background': colorPalette.lavender,
    },
    '&:focus': {
      outline: 'none',
      transform: 'translateX(-2%)',
      backgroundColor: colorPalette.lavender,
    },
    '&:checked': {
      'background': colorPalette.lavender,
      'background-image': `url(${process.env.PUBLIC_URL}/check.png)`,
      'background-repeat': 'no-repeat',
      'background-size': '20px 20px',
      'background-position': 'center'
    }
  }
})

interface ConsentCheckboxProps {
  onChange: (isChecked: boolean) => void
  locked: boolean
  id: string
}

const ConsentCheckbox: React.FC<ConsentCheckboxProps> = ({ onChange, locked, id}) => {
  const { checkbox } = useStyles()
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const handleChange = () => {
    onChange(!isChecked)
    setIsChecked(!isChecked)
  }
  return (
    <input disabled={locked} type="checkbox" checked={isChecked} className={checkbox} onChange={handleChange} aria-disabled={locked} id={id} />
  )
}

export default ConsentCheckbox

