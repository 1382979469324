import React from 'react'
import { createUseStyles } from 'react-jss'
import PrimaryActionButton from './primary-action-button'
import SecondaryButton from './secondary-button'
import InactiveActionButton from './inactive-action-button'
import InactiveButton from './inactive-button'
import PrimaryButton from './primary-button'

const useStyles = createUseStyles({
  formCtaButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '& > *:first-child': {
      margin: [0, 0, 16, 0]
    }
  }
})

interface FormCtaButtonsProps {
  onSaveAndNextSection?: () => void;
  onSaveAndClose?: () => void;
  isSaveAndNextSectionLoading?: boolean;
  isSaveAndCloseLoading?: boolean;
  isReadyToSave?: boolean;
  isLastSection?: boolean;
}

const FormCtaButtons: React.FunctionComponent<FormCtaButtonsProps> = ({ onSaveAndNextSection, onSaveAndClose, isSaveAndNextSectionLoading, isSaveAndCloseLoading, isReadyToSave, isLastSection }) => {
  const styles = useStyles()
  if (isReadyToSave) {
    return (
      <div className={styles.formCtaButtons}>
        {!isLastSection && (
          <PrimaryActionButton isLoading={isSaveAndNextSectionLoading} onClick={onSaveAndNextSection} width="250px">
            Save, next section
          </PrimaryActionButton>
        )}
        {isLastSection ? (
          <PrimaryButton isLoading={isSaveAndCloseLoading} onClick={onSaveAndClose} width="250px">Save & close</PrimaryButton>
        ) : (
          <SecondaryButton isLoading={isSaveAndCloseLoading} onClick={onSaveAndClose} width="250px">Save & close</SecondaryButton>
        )}
      </div>
    )
  } else {
    return (
      <div className={styles.formCtaButtons}>
        {!isLastSection && (
          <InactiveActionButton width="250px">Save, next section</InactiveActionButton>
        )}
        <InactiveButton width="250px">Save & close</InactiveButton>
      </div>
    )
  }
}

FormCtaButtons.defaultProps = {
  isReadyToSave: false,
  isLastSection: false
}

export default FormCtaButtons
