import React from 'react'
import { DefaultHeader } from "../atomic"
import { createGrid } from "@nascentdigital/react-lattice"
import { useReportSectionHeaderStyles } from "./report-section-header.styles"
import HelplineResourcesLink from "../helpline-resources-link/helpline-resources-link"
import { ReportSectionHeaderProps } from "./report-section-header.props"

const Grid = createGrid()

const ReportSectionHeader: React.FunctionComponent<ReportSectionHeaderProps> = ({ headerName }) => {
  const styles = useReportSectionHeaderStyles()

  return (
    <Grid container direction={{ xs: 'columnReverse', sm: 'columnReverse', md: 'columnReverse', lg: 'row' }}
          justify='spaceBetween'
          alignItems='baseline'>
      <DefaultHeader className={styles.header}>{headerName}</DefaultHeader>
      <HelplineResourcesLink className={styles.link}/>
    </Grid>
  )
}

export default ReportSectionHeader
