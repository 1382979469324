import alertService from "../../../../alerts/services/alert-service"

export function useHomeResumeReportButtonController() {
  const handleButtonClick = () => {
    alertService.alertResumeReportButtonClick()
  }
  
  return {
    handleButtonClick
  }
}
