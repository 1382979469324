import { useEffect, useState } from "react"
import suspectSectionFormService from "../services/suspect-section-form-service"
import ServiceLocator from "../../shared/service-locator/service-locator"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { useHistory } from "react-router-dom"
import { REPORT_ROUTES } from "../../constants"

type OpenForms = {
  [formId: string]: boolean;
}

const analyticsService = ServiceLocator.getInstance().getAnalyticsService()
const reportService = ServiceLocator.getInstance().getReportService()

export function useSuspectSectionController() {

  const history = useHistory()

  const suspectSectionData = reportService.suspectSectionData
  const showReturnCallOut = suspectSectionData?.showReturnCallOut
  const isWithPassword = reportService.hasReportPassword

  const [ isSaveAndNextSectionLoading, setIsSaveAndNextSectionLoading ] = useState<boolean>(false)
  const [ isSaveAndCloseLoading, setIsSaveAndCloseLoading ] = useState<boolean>(false)
  const [ isReadyToSave , setIsReadyToSave ] = useState<boolean>(false)
  const [ formIds, setFormIds ] = useState<number[]>([])
  const [ openForms, setOpenForms ] = useState<OpenForms>({})

  useEffect(() => {

    analyticsService.viewPage('/report/suspect-section')

    return () => {
      const existingFormIds = suspectSectionFormService.getFormIds()
      existingFormIds.forEach((id) => suspectSectionFormService.deleteForm(id))
      suspectSectionFormService.notifyFormChanged()
    }
  }, [])

  useEffect(() => {
    // get suspect section data on mount
    const suspectSectionData = reportService.suspectSectionData

    // if there are more than one suspect forms, open the last form
    if (suspectSectionData?.suspects && suspectSectionData?.suspects.length > 0) {

      const formIds = suspectSectionFormService.generateSavedSuspectForms(suspectSectionData.suspects, reportService.hasReportPassword)

      setOpenForms({
        [formIds[formIds.length-1]]: true
      })

      setFormIds(formIds)

    }
    // else, create a new suspect form
    else {

      const newFormId = suspectSectionFormService.createForm(reportService.hasReportPassword)

      setOpenForms({
        [newFormId]: true
      })

      setFormIds([newFormId])
    }
    suspectSectionFormService.notifyFormChanged()
  }, [])


  useEffect(() => {
    const unsubscribe: Subject<void> = new Subject();

    suspectSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setIsReadyToSave(isReadyToSave)
    })

    return () => {
      unsubscribe.next()
      unsubscribe.complete()
    }
  }, [])

  const handleSaveAndClose = async () => {
    setIsSaveAndCloseLoading(true)
    analyticsService.logEvent(
      'save_report',
      {
        section: 'suspect'
      }
    )
    try {
      await suspectSectionFormService.submit()
      setIsSaveAndCloseLoading(false)
      history.push(REPORT_ROUTES.DASHBOARD)
    } catch (e) {
      setIsSaveAndCloseLoading(false)
    }
  }

  const handleSaveAndNextSection = async () => {
    setIsSaveAndNextSectionLoading(true)
    analyticsService.logEvent(
      'save_report',
      {
        section: 'suspect'
      }
    )
    try {
      await suspectSectionFormService.submit()
      setIsSaveAndNextSectionLoading(false)
      history.push(REPORT_ROUTES.EVENTS_SECTION)
    } catch (e) {
      setIsSaveAndNextSectionLoading(false)
    }
  }

  const handleDeleteForm = (formId: number) => {
    // filter out the matching form
    setFormIds(formIds.filter((existingFormId: number) => existingFormId !== formId))
    suspectSectionFormService.deleteForm(formId)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleToggleView = (formId: number) => {
    const newOpenForms = {...openForms}
    newOpenForms[formId] = !newOpenForms[formId]
    setOpenForms(newOpenForms)
  }

  const handelCreateForm = () => {
    const newFormId = suspectSectionFormService.createForm(reportService.hasReportPassword)
    suspectSectionFormService.notifyFormChanged()

    const newOpenForms = {...openForms}
    // close all forms
    Object.keys(newOpenForms).forEach((key) => {
      newOpenForms[key] = false
    })
    newOpenForms[newFormId] = true
    setOpenForms(newOpenForms)

    const newFormIds = [...formIds]
    newFormIds.push(newFormId)
    setFormIds(newFormIds)

    // newOpenForms[newFormId] = true
    // setOpenForms(newOpenForms)
  }

  return {
    formIds,
    openForms,
    isSaveAndCloseLoading,
    isSaveAndNextSectionLoading,
    isReadyToSave,
    showReturnCallOut,
    isWithPassword,
    handleSaveAndClose,
    handleSaveAndNextSection,
    handleDeleteForm,
    handleToggleView,
    handelCreateForm
  }

}
