import { useState, useEffect } from "react"
import ServiceLocator from "../../../shared/service-locator/service-locator"
import vehicleSectionFormService from "../../services/vehicle-section-form-service"

const reportService = ServiceLocator.getInstance().getReportService()

export function useWasVehicleInvolvedQuestionController() {

  const vehicleSectionSavedData = reportService.vehicleSectionSavedData

  const initialWasVehicleInvolvedValue = vehicleSectionSavedData?.wasVehicleInvolved?.inputFieldValue as string || ''

  const [wasVehicleInvolved, setWasVehicleInvolved] = useState<string>(initialWasVehicleInvolvedValue)

  const handleWasVehicleInvolvedChange = (value: string) => {
    vehicleSectionFormService.setWasVehicleInvolved(value)
    // to trigger rerender
    setWasVehicleInvolved(value)
  }

  useEffect(() => {
    return () => vehicleSectionFormService.setWasVehicleInvolved('')
  }, [])

  return {
    showVehicleWasInvolvedQuestions: wasVehicleInvolved === 'Yes' || wasVehicleInvolved === '',
    wasVehicleInvolved,
    handleWasVehicleInvolvedChange,
    wasVehicleInvolvedHasBeenFillled: !!vehicleSectionSavedData?.wasVehicleInvolved
  }
}
