import React, { useState, useEffect } from 'react'
import formService from '../services/suspect-section-form-service'
import FormCtaButtons from '../../shared/components/form-cta-buttons'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'

interface Props {
  onSaveAndClose: () => void;
  isSaveAndCloseLoading: boolean;
  onSaveAndNextSection: () => void;
  isSaveAndNextSectionLoading: boolean;
}

const SuspectSectionFormCtaButtons: React.FunctionComponent<Props> = ({ 
  onSaveAndClose,
  isSaveAndCloseLoading,
  onSaveAndNextSection,
  isSaveAndNextSectionLoading
}) => {

  const [ isReadyToSave, setIsReadyToSave ] = useState<boolean>(false)
  const unsubscribe: Subject<void> = new Subject()

  useEffect(() => {
    formService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setIsReadyToSave(isReadyToSave)
    })
    return () => {
      unsubscribe.next()
      unsubscribe.complete()
    }
  }, [])

  return (
    <FormCtaButtons
      isReadyToSave={isReadyToSave}
      onSaveAndClose={onSaveAndClose}
      isSaveAndCloseLoading={isSaveAndCloseLoading}
      onSaveAndNextSection={onSaveAndNextSection}
      isSaveAndNextSectionLoading={isSaveAndNextSectionLoading}
    />
  )
}

export default SuspectSectionFormCtaButtons