import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import { ThemeProvider } from 'react-jss'
import './reset.css'
import { ROUTES } from './constants';
import * as theme from './shared/theme';
import Home from './home/components/home-page';
import Onboarding from './onboarding/components/onboarding-page';
import ReportContainer from './report-container/report-container';
import Login from './auth/components/login-page';
import ThankYouPage from './thank-you/components/thank-you-page';
import AlertContainer from './alerts/components/alert-container';
import ResourcesPage from './resources/components/resources-page';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <Router>
          <Route path={ROUTES.LOGIN}>
            <Login/>
          </Route>

          <AlertContainer/>
          <Switch>
            <Route path={ROUTES.HOME}>
              <Home/>
            </Route>
            <Route path={ROUTES.ONBOARDING}>
              <Onboarding/>
            </Route>
            <Route path={ROUTES.RESOURCES}>
              <ResourcesPage/>
            </Route>
            <Route path={ROUTES.REPORT}>
              <ReportContainer/>
            </Route>
            <Route path={ROUTES.THANK_YOU}>
              <ThankYouPage/>
            </Route>
            <Route path="/">
              <Redirect to="/home"/>
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
