import React, { useState, useEffect } from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import AnimateHeight from 'react-animate-height';

import formService from '../services/date-and-location-section-form-service';
import ServiceLocator from '../../shared/service-locator/service-locator';
import {
  CHECKBOX_YES_OPTION,
  CHECKBOX_NO_OPTION,
  CHECKBOX_I_DONT_RECALL_OPTION
} from '../../constants';
import { useFormStyles } from '../../shared/theme/form-styles';
import { dateAndLocationSection } from '../models/date-and-location-section.model';
import { breakpoints } from '../../shared/theme';
// components
import { DefaultBodyText } from '../../shared/components';
import CheckboxSelection from '../../shared/components/checkbox-selection';
import DidHappenMoreThanOnce from './did-happen-more-than-once';
import DidNotHappenMoreThanOnce from './did-not-happen-more-than-once';
import { usePrevious } from '../../shared/hooks/use-previous';

const Grid = createGrid({ breakpoints });
const model = dateAndLocationSection.happenedMoreThanOnce;
const reportService = ServiceLocator.getInstance().getReportService();

const HappenedMoreThanOnceQuestion = () => {
  const dateAndLocationSectionData = reportService.dateAndLocationSectionData;

  const [happenedMoreThanOnce, setHappenedMoreThanOnce] =
    useState<string>(dateAndLocationSectionData?.multipleOccurrencesRecall?.inputFieldValue);

  const previousHappenedMoreThanOnce = usePrevious(happenedMoreThanOnce);
  const styles = useFormStyles();
  const hasBeenFilled: boolean = !!dateAndLocationSectionData?.multipleOccurrencesRecall;

  useEffect(() => {
    return () => formService.setMultipleOccurrencesRecall('');
  }, []);

  return (
    <div>
      <div id='didItHappenMoreThanOnce'>
        <DefaultBodyText color={hasBeenFilled ? 'grape' : 'navy'}>
          {model.didItHappenMoreThanOnce.label}
        </DefaultBodyText>
      </div>
      <Grid container justify='center'>
        <Grid item flex={{ xs: 12, md: 10 }}>
          <div className={styles.conditionalQuestionContainer}>
            <CheckboxSelection
              options={model.didItHappenMoreThanOnce.options}
              selected={happenedMoreThanOnce}
              onChange={(value) => {
                formService.setMultipleOccurrencesRecall(value);
                setHappenedMoreThanOnce(value);
              }}
              hasBeenFilled={hasBeenFilled}
              ariaDescribedby='didItHappenMoreThanOnce'
            />
            <AnimateHeight
              duration={800}
              height={happenedMoreThanOnce === CHECKBOX_YES_OPTION || happenedMoreThanOnce === CHECKBOX_NO_OPTION ? 'auto' : 0}
            >
              {happenedMoreThanOnce === CHECKBOX_YES_OPTION && <DidHappenMoreThanOnce/>}
              {happenedMoreThanOnce === CHECKBOX_NO_OPTION && <DidNotHappenMoreThanOnce/>}
              {( happenedMoreThanOnce === CHECKBOX_I_DONT_RECALL_OPTION || happenedMoreThanOnce === '' )
              && previousHappenedMoreThanOnce === CHECKBOX_YES_OPTION && <DidHappenMoreThanOnce/>
              }
              {( happenedMoreThanOnce === CHECKBOX_I_DONT_RECALL_OPTION || happenedMoreThanOnce === '' )
              && previousHappenedMoreThanOnce === CHECKBOX_NO_OPTION && <DidNotHappenMoreThanOnce/>
              }
            </AnimateHeight>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HappenedMoreThanOnceQuestion;
