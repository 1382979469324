import alertService from './alert-service';

type Seconds = Number

class TimeoutService {
  private incrementor: any;
  private warningTimeout: Seconds;
  private expireTimeout: Seconds;
  private startTime: number;
  private timeDifference: number = 0;

  /**
   * configure with the timout thresholds
   * @param warningTimeout timeout warning in seconds
   * @param expireTimeout session expire timeout in secondsß
   */
  constructor(warningTimeout: Seconds, expireTimeout: Seconds) {
    this.warningTimeout = warningTimeout;
    this.expireTimeout = expireTimeout;
    this.startTime = Date.now();
  }

  public resetTimer() {
    this.startTime = Date.now();

  }

  public startTimer() {
    this.startTime = Date.now();

    this.incrementor = setInterval(() => {
      // calculate the time difference between start time and current time
      this.timeDifference = ( Date.now() - this.startTime ) / 1000;
      // check timeout
      this.checkTimeout();
    }, 1000);
  }

  public clear() {
    this.startTime = Date.now();
    clearInterval(this.incrementor);
  }

  private checkTimeout() {
    // timer has not reached warning zone
    if (this.timeDifference < this.warningTimeout) {
      return;
    }
    // timer has not reached expire zone
    else if (this.timeDifference < this.expireTimeout) {
      alertService.alertSessionExpireWarning();
    }
    // timer has past expire zone
    else {
      alertService.alertSessionExpired();
    }
  }
}

const timeoutService = new TimeoutService(900, 960);

export default timeoutService;
