import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import alertService from "../../services/alert-service"
import ServiceLocator from "../../../shared/service-locator/service-locator"

const reportService = ServiceLocator.getInstance().getReportService()
const analyticsService = ServiceLocator.getInstance().getAnalyticsService()

export function useResumeReportAlertController() {

  const history = useHistory()

  const [ reportCode, setReportCode ] = useState<string>('')
  const [ reportCodeErrorMessage, setReportCodeErrorMessage ] = useState<string>('')
  const [ reportPassword, setReportPassword ] = useState<string>('')
  const [ reportPasswordErrorMessage, setReportPasswordErrorMessage ] = useState<string>('')
  const [ buttonSelected, setButtonSelected ] = useState<'reportCodeOnly'|'reportCodeAndPassword'>('reportCodeAndPassword')
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  // on button selection change, clear the error messages
  useEffect(() => {
    setReportPasswordErrorMessage('')
    setReportCodeErrorMessage('')
  }, [buttonSelected])

  const handleCancelClick = () => {
    alertService.clearAlert()
  }

  const handleReportCodeChange = (value: string) => {
    setReportCode(value)
    if (reportCodeErrorMessage !== '') {
      setReportCodeErrorMessage('')
    }
  }

  const handleReportPasswordChange = (value: string) => {
    setReportPassword(value)
    if (reportPasswordErrorMessage !== '') {
      setReportPasswordErrorMessage('')
    }
  }

  const handleButtonSelectionChange = (value: 'reportCodeOnly'|'reportCodeAndPassword') => {
    setButtonSelected(value)
    if (value === 'reportCodeOnly') {
      setReportPassword('')
    }
  }

  const handleContinue = async () => {
    setIsLoading(true)

    const password = buttonSelected === 'reportCodeAndPassword' ? reportPassword : ''

    if (password) {
      analyticsService.logEvent(
        'resume_report',
        {
          with_password: true,
        }
      )
    } else {
      analyticsService.logEvent(
        'resume_report',
        {
          with_password: false,
        }
      )
    }

    try {
      const reportCodeNormalized = reportCode.toUpperCase().replace(/-/g, '')
      await reportService.resumeReportWithReportCodeAndPassword(reportCodeNormalized, password)

      // upon success, clear the alert and move to the dashboard
      alertService.clearAlert()
      history.push('/report/dashboard')

    } catch (e) {

      if (buttonSelected === 'reportCodeOnly') {
        setReportCodeErrorMessage('Report code is invalid. Please try again.')
        setReportPasswordErrorMessage('')
      }

      else {
        setReportCodeErrorMessage('')
        setReportPasswordErrorMessage('Report code and/or password is invalid. Please try again.')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const isFormSubmittable = () => {
    // non-empty report code is the only constraint to submit the form if the user selected 'reportCodeOnly'
    if (buttonSelected === 'reportCodeOnly') {
      return reportCode !== ''
    }
    // else, both report code and report password are required
    else {
      return reportCode !== '' && reportPassword !== ''
    }
  }

  return {
    isContinuable: isFormSubmittable(),
    reportCode,
    reportCodeErrorMessage,
    reportPassword,
    reportPasswordErrorMessage,
    buttonSelected,
    isLoading,
    handleCancelClick,
    handleReportCodeChange,
    handleReportPasswordChange,
    handleButtonSelectionChange,
    handleContinue
  }
}
