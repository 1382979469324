export function numberValidator(value: string, maxNumberOfDigits?: number): boolean {
  // empty values are considered valid
  if (value === '') return true

  // all values must be raw strings
  if (typeof value !== 'string') return false

  if (value.trim() === '') return false

  // check if the value is castable to Number
  if (isNaN(Number(value))) return false

  // check the number of digits if it is provided
  if (maxNumberOfDigits && value.length > maxNumberOfDigits) return false

  return true
}
