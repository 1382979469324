import { createUseStyles } from "react-jss";

export const sequenceOfEventsSectionStylesFactory = createUseStyles({
  infoBlockHelpfulQuestionsContainer: {
    marginTop: '16px'
  },
  infoBlockHelpfulQuestion: {
    marginTop: '8px'
  },
  assistanceBlock: {
    marginTop: '16px'
  }
})
