export type FontFamily = 'AvenirRoman' | 'Avenir' | 'AvenirBlack' | 'Times' | 'TimesBold' | 'SansSerif'

export type FontFamilies = Record<FontFamily, string>

export const fontFamilies: Record<FontFamily, string> = {
  AvenirRoman: 'Avenir-Roman',
  Avenir: 'Avenir',
  AvenirBlack: 'Avenir-Black',
  Times: 'Times',
  TimesBold: 'Times-Bold',
  SansSerif: 'Sans-Serif'
};

type FontStyle = 'body' | 'textInput' | 'link' | 'heading' | 'subHeading' | 'buttonLink'

export type FontStyles = Record<FontStyle, Record<string, any>>

export const fontStylesMobile: FontStyles = {
  body: {
    fontFamily: [fontFamilies.AvenirRoman, fontFamilies.Avenir, fontFamilies.SansSerif],
    fontSize: '1rem',
    lineHeight: '1.43rem'
  },
  textInput: {
    fontFamily: [fontFamilies.AvenirRoman, fontFamilies.Avenir, fontFamilies.SansSerif],
    fontSize: '1rem',
    lineHeight: '1.43rem'
  },
  heading: {
    fontFamily: fontFamilies.Times,
    fontSize: '3.125rem',
    lineHeight: '3rem'
  },
  subHeading: {
    fontFamily: fontFamilies.Times,
    lineHeight: '2rem',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginBottom: 6
  },
  link: {
    fontFamily: [fontFamilies.AvenirRoman, fontFamilies.Avenir, fontFamilies.SansSerif],
    fontSize: '0.875rem',
    letterSpacing: '1px',
    lineHeight: '1.25rem',
    fontWeight: 'bold'
  },
  buttonLink: {
    fontFamily: [fontFamilies.AvenirBlack, fontFamilies.Avenir, fontFamilies.SansSerif],
    fontSize: '1rem',
    lineHeight: '1.43rem',
    letterSpacing: '1.14px',
    fontWeight: 'bold'
  }
};

export const fontStylesTablet: FontStyles = {
  body: fontStylesMobile.body,
  textInput: fontStylesMobile.textInput,
  heading: {
    fontFamily: fontFamilies.Times,
    fontSize: '4rem',
    lineHeight: '4.375rem'
  },
  subHeading: {
    ...fontStylesMobile.subHeading,
    fontSize: '1.625rem',
    lineHeight: '1.875rem'
  },
  link: fontStylesMobile.link,
  buttonLink: fontStylesMobile.buttonLink
};
