import { Region, RegionService } from './interfaces/region-service.interface'
import { HttpClient } from '../../clients/http-client.interface'

export class MainRegionService implements RegionService {
  private readonly httpClient: HttpClient;
  private readonly baseUrl: string;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.baseUrl = process.env.REACT_APP_API_ENDPOINT!
  }

  async getRegions(): Promise<Region[]> {
    try {
      return this.httpClient.get(this.baseUrl + '/region')
    } catch (error) {
      throw new Error('Region Service Error. Failed to get regions.')
    }
  }

  async getRegion(id: number): Promise<Region> {
    const regionList = await this.getRegions()
    const region = regionList.find(({ regionId }) => id === regionId)

    if (!region) {
      throw new Error('Region Service Error. Failed to get region.')
    }

    return region
  }
}
