import { Subject, Observable } from 'rxjs'
import { SnackbarModel } from '../models/snackbar.model'
import iconSnackbarCheck from '../assets/iconSnackbarCheck.png'
import iconSnackbarKey from '../assets/iconSnackbarKey.png'
import iconSnackbarWriteOnly from '../assets/iconSnackbarWriteOnly.png'

interface SnackbarServiceConfig {
  displayDuration: number; // how long the snackbar should be displayed in seconds
}

class SnackbarService {
  private displayDuration: number
  private model?: SnackbarModel
  private _model$: Subject<SnackbarModel> = new Subject()
  public model$: Observable<SnackbarModel> = this._model$.asObservable()
  private reservedDismissal: NodeJS.Timeout | undefined = undefined;

  constructor(config: SnackbarServiceConfig) {
    this.displayDuration = config.displayDuration
  }

  public displaySectionSavedSnackbar() {
    this.displaySnackbar('INFORMATION SAVED', '', iconSnackbarCheck, 'iconSnackbarCheck')
  }

  public displaySaveWithoutPasswordSnackbar() {
    this.displaySnackbar('NO PASSWORD:', 'Your report will be write-only.', iconSnackbarWriteOnly, 'iconSnackbarCheck')
  }

  public displayPasswordCreatedSnackbar() {
    this.displaySnackbar('PASSWORD CREATED:', 'Your report will be read & write.', iconSnackbarKey, 'iconSnackbarKey')
  }

  public dismissSnackbar() {
    // if there is a reserved dismissal in place, clear it first
    if (this.reservedDismissal) {
      clearTimeout(this.reservedDismissal)
      this.reservedDismissal = undefined
    }

    this.model = {
      status: false,
      title: this.model?.title ?? '',
      description: this.model?.description ?? '',
      imageSrc: this.model?.imageSrc ?? iconSnackbarCheck,
      imageAlt: this.model?.imageAlt ?? 'iconSnackbarCheck'
    }

    // set display status to false
    this._model$.next(this.model)
  }

  private displaySnackbar(title: string, description: string, imageSrc: string, imageAlt: string) {
    // only trigger the snackbar when there is none being shown at the moment
    if (!this.model?.status) {
      this.model = { status: true, title, description, imageSrc, imageAlt }
      this._model$.next(this.model)

      // after a specified amount of time, dismiss the snackbar
      this.reservedDismissal = setTimeout(() => {
        this.model = { status: false, title, description, imageSrc, imageAlt }
        this._model$.next(this.model)
      }, this.displayDuration * 1000)
    }
  }
}

const snackBarService = new SnackbarService({ displayDuration: 5 })

export default snackBarService
