import React from 'react';
import { createUseStyles } from 'react-jss';
import { colorPalette } from '../theme';

interface SupportBlockProps {
  children: React.ReactNode;
  isOnTheRight?: boolean;
  height?: string;
  width?: string;
}

interface StyleProps {
  isOnTheRight: boolean,
  height: string,
  width: string
}

const useStyles = createUseStyles({
  supportBlock: {
    boxSizing: 'border-box',
    border: `1px solid ${colorPalette.grape}`,
    borderRadius: '1rem',
    padding: '20px 25px',
    height: (props: StyleProps) => props.height || 'initial',
    width: (props: StyleProps) => props.width,
    marginLeft: (props: StyleProps) => props.isOnTheRight ? 'auto' : 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
});

const SupportBlock: React.FunctionComponent<SupportBlockProps> = ({ height, children, isOnTheRight, width }) => {
  const { supportBlock } = useStyles({ isOnTheRight, height, width });
  return (
    <div className={supportBlock}>{children}</div>
  );
};

SupportBlock.defaultProps = {
  width: '90%'
};

export default SupportBlock;
