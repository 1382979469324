import React, { useState, useEffect } from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import formService from '../services/date-and-location-section-form-service';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { CHECKBOX_YES_OPTION } from '../../constants';
import { useFormStyles } from '../../shared/theme/form-styles';
import { dateAndLocationSection } from '../models/date-and-location-section.model';
// components
import { DefaultBodyText, Divider } from '../../shared/components';
import CheckboxSelection from '../../shared/components/checkbox-selection';
import TextInputField from '../../shared/components/text-input-field';
import Dropdown from '../../shared/components/dropdown';
import AnimateHeight from 'react-animate-height';
import { capitalizeString } from '../../shared/utils/capitalize-string'

const Grid = createGrid();
const reportService = ServiceLocator.getInstance().getReportService()
const model = dateAndLocationSection.knowAddressOfThisLocation;

const DEFAULT_PROVINCE = 'Ontario';

interface Props {
  addressRecall: string
}

const AddressDetails = (props: Props) => {
  const formStyles = useFormStyles();

  const [cityLoaded, setCityLoaded] = useState(false);
  const [city, setCity] = useState(reportService.dateAndLocationSectionData?.city?.inputFieldValue ?? '');

  const [province, setProvince] = useState(() => {
    const savedProvince = reportService.dateAndLocationSectionData?.provinceTerritory?.inputFieldValue;

    return savedProvince === undefined ? DEFAULT_PROVINCE : savedProvince;
  });

  async function getCityName() {
    const savedCity = reportService.dateAndLocationSectionData?.city?.inputFieldValue;
    try {
      if (!savedCity) {
        setCity(capitalizeString(reportService.region.regionName))
      }
    } finally {
      setCityLoaded(true)
    }
  }

  useEffect(() => {
    getCityName()
  }, [])

  const onCityChange = (city: string) => {
    formService.setCity(city);
  }

  useEffect(() => {
    formService.setProvinceTerritory(province);
  }, [province, props.addressRecall]);

  useEffect(() => {
    return () => {
      formService.clearAddressDetails();
    };
  }, []);

  return (
    <div>
      <div className={formStyles.question}>
        <TextInputField
          initialValue={reportService.dateAndLocationSectionData?.address?.inputFieldValue}
          hasBeenFilled={!!reportService.dateAndLocationSectionData?.address}
          label={model.YES_address.label}
          placeholder={model.YES_address.placeholder}
          onChange={(address) => formService.setAddress(address)}
          id='address'
        />
      </div>
      <Grid container className={formStyles.questionGroupInternalPadding}>
        <Grid item flex={{ xs: 12, md: 6 }}>
          {
            cityLoaded && (
              <TextInputField
                initialValue={city}
                hasBeenFilled={!!reportService.dateAndLocationSectionData?.city}
                label={model.YES_city.label}
                onChange={onCityChange}
                id='city'
              />
            )
          }
        </Grid>
        <Grid item flex={{ xs: 12, md: 6 }}>
          <Dropdown
            initialValue={province}
            hasBeenFilled={!!reportService.dateAndLocationSectionData?.provinceTerritory}
            label={model.YES_province.label}
            onChange={setProvince}
            options={model.YES_province.options}
            isComboBox={true}
            id='province'
          />
        </Grid>
      </Grid>
      <div className={formStyles.divider}>
        <Divider width="100%" thickness="thin"/>
      </div>
    </div>
  );
};

const KnowTheAddressQuestion = (props: any) => {
  const styles = useFormStyles();
  const dateAndLocationSectionData = reportService.dateAndLocationSectionData;
  const hasBeenFilled = !!dateAndLocationSectionData?.addressRecall;

  const [knowTheAddress, setKnowTheAddress] = useState<string>(
    dateAndLocationSectionData?.addressRecall?.inputFieldValue
  );

  useEffect(() => {
    return () => formService.setAddressRecall('');
  }, []);

  return (
    <div>
      <div id='knowTheAddressOfThisLocation'>
        <DefaultBodyText
          color={hasBeenFilled ? 'grape' : 'navy'}>{model.knowTheAddressOfThisLocation.label}</DefaultBodyText>
      </div>
      <Grid container justify='center'>
        <Grid item flex={{ xs: 12, md: 10 }}>
          <div className={styles.conditionalQuestionContainer}>
            <CheckboxSelection
              options={model.knowTheAddressOfThisLocation.options}
              selected={knowTheAddress}
              onChange={(addressRecall) => {
                formService.setAddressRecall(addressRecall);
                setKnowTheAddress(addressRecall);
              }}
              hasBeenFilled={hasBeenFilled}
              ariaDescribedby='knowTheAddressOfThisLocation'
            />
            <AnimateHeight
              duration={800}
              height={knowTheAddress === CHECKBOX_YES_OPTION ? 'auto' : 0}
            >
              <AddressDetails addressRecall={knowTheAddress} />
            </AnimateHeight>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default KnowTheAddressQuestion;
