import { BehaviorSubject, Observable } from 'rxjs'
import ServiceLocator from '../../shared/service-locator/service-locator'
import snackBarService from '../../snackbar/services/snackbar-service';
import alertService from '../../alerts/services/alert-service';
import { SectionFormService } from '../../shared/interfaces/section-form-service.interface';
import { SectionFormBaseService } from '../../shared/services/section-form-base-service/section-form-base.service';

const reportService = ServiceLocator.getInstance().getReportService()
const sectionService = ServiceLocator.getInstance().getSectionService()

class VehicleSectionFormService extends SectionFormBaseService implements SectionFormService {
  private wasVehicleInvolved: string = '';
  private vehicleMake: string = '';
  private vehicleModel: string = '';
  private vehicleColour: string = '';
  private vehicleStyle: string = '';
  private licensePlate: string = '';
  private issuingProvinceTerritory: string = '';
  private distinctFeaturesAboutVehicle: string = '';
  private _isReadyToSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public isReadyToSave: Observable<boolean> = this._isReadyToSave.asObservable()

  private updateIsReadyToSaveFlag() {
    if (this.wasVehicleInvolved) {
      this._isReadyToSave.next(true)
    } else {
      this._isReadyToSave.next(false)
    }
  }

  public initialize() {
    this.wasVehicleInvolved = reportService.vehicleSectionSavedData?.wasVehicleInvolved?.inputFieldValue
    this.vehicleMake = reportService.vehicleSectionSavedData?.vehicleMake?.inputFieldValue
    this.vehicleModel = reportService.vehicleSectionSavedData?.vehicleModel?.inputFieldValue
    this.vehicleColour = reportService.vehicleSectionSavedData?.vehicleColour?.inputFieldValue
    this.vehicleStyle = reportService.vehicleSectionSavedData?.vehicleStyle?.inputFieldValue
    this.licensePlate = reportService.vehicleSectionSavedData?.licensePlate?.inputFieldValue
    this.issuingProvinceTerritory = reportService.vehicleSectionSavedData?.issuingProvinceTerritory?.inputFieldValue
    this.distinctFeaturesAboutVehicle = reportService.vehicleSectionSavedData?.distinctFeaturesAboutVehicle?.inputFieldValue
  }

  public setWasVehicleInvolved(wasVehicleInvolved: string) {
    this.wasVehicleInvolved = this.compare(
      wasVehicleInvolved,
      reportService.vehicleSectionSavedData?.wasVehicleInvolved?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public setVehicleMake(vehicleMake: string) {
    this.vehicleMake = this.compare(
      vehicleMake,
      reportService.vehicleSectionSavedData?.vehicleMake?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public setVehicleModel(vehicleModel: string) {
    this.vehicleModel = this.compare(
      vehicleModel,
      reportService.vehicleSectionSavedData?.vehicleModel?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public setVehicleColour(vehicleColour: string) {
    this.vehicleColour = this.compare(
      vehicleColour,
      reportService.vehicleSectionSavedData?.vehicleColour?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public setVehicleStyle(vehicleStyle: string) {
    this.vehicleStyle = this.compare(
      vehicleStyle,
      reportService.vehicleSectionSavedData?.vehicleStyle?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public setLicensePlate(licensePlate: string) {
    this.licensePlate = this.compare(
      licensePlate,
      reportService.vehicleSectionSavedData?.licensePlate?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public setIssuingProvinceTerritory(issuingProvinceTerritory: string) {
    this.issuingProvinceTerritory = this.compare(
      issuingProvinceTerritory,
      reportService.vehicleSectionSavedData?.issuingProvinceTerritory?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public setDistinctFeaturesAboutVehicle(distinctFeaturesAboutVehicle: string) {
    this.distinctFeaturesAboutVehicle = this.compare(
      distinctFeaturesAboutVehicle,
      reportService.vehicleSectionSavedData?.distinctFeaturesAboutVehicle?.inputFieldValue,
      reportService.hasReportPassword
    )
    this.updateIsReadyToSaveFlag()
  }

  public clearForm() {
    this.wasVehicleInvolved = '';
    this.vehicleMake = '';
    this.vehicleModel = '';
    this.vehicleColour = '';
    this.vehicleStyle = '';
    this.licensePlate = '';
    this.issuingProvinceTerritory = '';
    this.distinctFeaturesAboutVehicle = '';
    this.updateIsReadyToSaveFlag()
  }

  public clearVehicleWasInvolvedForm() {
    this.vehicleMake = '';
    this.vehicleModel = '';
    this.vehicleColour = '';
    this.vehicleStyle = '';
    this.licensePlate = '';
    this.issuingProvinceTerritory = '';
    this.distinctFeaturesAboutVehicle = '';
    this.updateIsReadyToSaveFlag()
  }

  public isFormReadyToBeSaved(): boolean {
    return this._isReadyToSave.value
  }

  public async submit() {
    const reportCode = reportService.reportCode
    await sectionService.createVehicleSection(reportCode, {
      wasVehicleInvolved: this.wasVehicleInvolved,
      vehicleMake: this.vehicleMake,
      vehicleModel: this.vehicleModel,
      vehicleColour: this.vehicleColour,
      vehicleStyle: this.vehicleStyle,
      licensePlate: this.licensePlate,
      issuingProvinceTerritory: this.issuingProvinceTerritory,
      distinctFeaturesAboutVehicle: this.distinctFeaturesAboutVehicle
    })
    await reportService.resumeReport()
    snackBarService.displaySectionSavedSnackbar()
    alertService.alertFirstTimeSave()
    return
  }
}

const vehicleSectionFormService = new VehicleSectionFormService()

export default vehicleSectionFormService
