import React from 'react'
import Loader from 'react-loader-spinner'

// components
import BaseButton from './base-button'
import { colorPalette } from '../theme'

interface SecondaryButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  width?: string;
  isLoading?: boolean;
}

export const SecondaryButton: React.FunctionComponent<SecondaryButtonProps> = ({ children, width, onClick, isLoading }) => {
  if (isLoading) {
    return (
      <BaseButton borderColor="grape" backgroundColor="white" fontColor="grape" width={width}>
        <Loader type="ThreeDots" color={colorPalette.purple} height={10} width={30} />
      </BaseButton>
    )
  }

  return (
    <BaseButton onClick={onClick} borderColor="grape" backgroundColor="white" fontColor="grape" width={width}>
      {children?.toString().toUpperCase()}
    </BaseButton>
  )
}

export default SecondaryButton
