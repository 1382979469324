import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BaseAlert from '../base-alert';
import ServiceLocator from '../../../shared/service-locator/service-locator';
import { breakpoints } from '../../../shared/theme';
import {
  CreateReportPasswordAlertContent,
  createReportPasswordAlertContentProvider
} from './content/create-report-password-alert.content';
import { createReportPasswordAlertStylesFactory } from './create-report-password-alert.styles';
import { ImpactStatementHeader, DefaultBodyText, DefaultHeader } from '../../../shared/components';
import AlertInputField from '../shared/alert-input-field/alert-input-field';
import SupportBlock from '../../../shared/components/support-block';
import { useCreateReportPasswordAlertController } from './create-report-password-alert.controller';
import { createGrid } from '@nascentdigital/react-lattice';
import SecondaryActionButton from '../../../shared/components/secondary-action-button';
import PrimaryActionButton from '../../../shared/components/primary-action-button';

const content: CreateReportPasswordAlertContent = createReportPasswordAlertContentProvider();
const reportService = ServiceLocator.getInstance().getReportService();

const stylesFactory = createReportPasswordAlertStylesFactory;
const Grid = createGrid({ breakpoints });

const CreateReportPasswordAlert: React.FunctionComponent<RouteComponentProps> = () => {
  const classes = stylesFactory();

  const {
    onBackButtonClicked,
    isExecuting,
    onCreateButtonClicked,
    passwordErrorMessage,
    confirmPasswordErrorMessage,
    onPasswordChanged,
    onConfirmPasswordChanged,
    passwordValue,
    confirmPasswordValue
  } = useCreateReportPasswordAlertController();

  return (
    <BaseAlert>
      <Grid container>
        <Grid item flex={{ xs: 12 }}>
          <ImpactStatementHeader>{content.header}</ImpactStatementHeader>
        </Grid>
        <Grid item flex={{ xs: 12 }}>
          <div className={classes.description}>
            <DefaultBodyText fontSize="1rem">
              {content.descriptionPart1}
              <b>
                {reportService.formattedReportCode}
              </b>
              {content.descriptionPart2}
            </DefaultBodyText>
          </div>
        </Grid>
        <Grid item flex={{ xs: 12 }}>
          <div className={classes.passwordRequirementsBlock}>
            <SupportBlock width="100%">
              <DefaultHeader>{content.passwordRequirementsHeader}</DefaultHeader>
              <div className={classes.passwordRequirementsItems}>
                {content.passwordRequirements.map((requirement, index) =>
                  <div key={index} className={classes.passwordRequirementsItem}>
                    <DefaultBodyText>{requirement}</DefaultBodyText>
                  </div>
                )}
              </div>
            </SupportBlock>
          </div>
          <div className={classes.password}>
            <AlertInputField
              autoFocus={true}
              label={content.passwordLabel}
              errorMessage={passwordErrorMessage}
              onChange={onPasswordChanged}
              value={passwordValue.value}
              id='createPassword'/>
          </div>
          <div className={classes.confirmPassword}>
            <AlertInputField
              label={content.confirmPasswordLabel}
              errorMessage={confirmPasswordErrorMessage}
              onChange={onConfirmPasswordChanged}
              value={confirmPasswordValue.value}
              id='confirmPassword'/>
            <Grid container className={classes.buttons} justify={{ xs: 'center', md: 'spaceBetween' }}
                  direction={{ xs: 'columnReverse', md: 'row' }}>
              <SecondaryActionButton onClick={onBackButtonClicked}>
                {content.backButtonLabel}
              </SecondaryActionButton>
                <PrimaryActionButton isLoading={isExecuting} onClick={onCreateButtonClicked}>
                  {content.createButtonLabel}
                </PrimaryActionButton>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </BaseAlert>
  );
};

export default withRouter(CreateReportPasswordAlert);
