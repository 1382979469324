import { BehaviorSubject, Observable, Subject } from 'rxjs';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { SuspectDto } from '../../shared/data-transfer-objects/section-dto';
import snackBarService from '../../snackbar/services/snackbar-service';
import alertService from '../../alerts/services/alert-service';
import { SectionFormBaseService } from '../../shared/services/section-form-base-service/section-form-base.service';

const reportService = ServiceLocator.getInstance().getReportService();
const sectionService = ServiceLocator.getInstance().getSectionService();

class SuspectSectionFormService {
  constructor(
    private _isReadyToSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false),
    public isReadyToSave: Observable<boolean> = _isReadyToSave.asObservable(),
    private _formUpdated: Subject<void> = new Subject<void>(),
    private forms: SuspectForm[] = [],
    public formUpdated: Observable<void> = _formUpdated.asObservable(),
  ) {
  }

  public isFormReadyToBeSaved(): boolean {
    // if all forms have been saved, at least one form needs to have some new change
    if (this.forms.every((form) => form.hasBeenPreviouslySaved())) {
      return this.forms.some((form) => {
        return form.isReadyToSave() && form.hasChanged;
      });
    }
    // if not all forms have been saved, check if each form has either previously been saved or has some data
    if (this.forms.every((form) => form.hasBeenPreviouslySaved() || form.isReadyToSave())) {
      return true;
    }
    // else it is not ready to be saved
    return false;
  }

  public notifyFormChanged() {
    if (this.isFormReadyToBeSaved()) {
      this._isReadyToSave.next(true);
    } else {
      this._isReadyToSave.next(false);
    }
    this._formUpdated.next();
  }

  public getFormIds(): number[] {
    return this.forms.map((form) => form.getId());
  }

  public getFormById(id: number) {
    return this.forms.find((form) => form.getId() === id);
  }

  public createForm(hasReportPassword?: boolean, data?: SuspectDto): number {
    const newId = this.forms.length + 1;
    this.forms.push(new SuspectForm(newId, hasReportPassword, data));
    return newId;
  }

  public generateSavedSuspectForms(suspects: SuspectDto[], hasReportPassword?: boolean): number[] {
    return suspects.map((suspect) => {
      const suspectForm = SuspectForm.generateSavedSuspectForm(suspect, hasReportPassword);
      this.forms.push(suspectForm);
      return suspectForm.getId();
    });
  }

  public deleteForm(id: number) {
    this.forms = this.forms.filter((form) => form.getId() !== id);
  }

  public async submit() {
    const reportCode = reportService.reportCode;
    await sectionService.createSuspectSection(reportCode, {
      suspects: this.forms.map((form) => form.getSubmissionData())
    });
    await reportService.resumeReport();
    snackBarService.displaySectionSavedSnackbar();
    alertService.alertFirstTimeSave();
    return;
  }
}

export class SuspectForm extends SectionFormBaseService {

  private hasReportPassword: boolean;
  private suspectId: number;

  private isSuspectKnown?: string;
  private suspectNames?: string;
  private howDidSuspectKnowYou?: string;
  private approximateAge?: string;
  private approximateHeight?: string;
  private raceAppearance?: string;
  private skinColour?: string;
  private hairColour?: string;
  private suspectGender?: string;
  private buildDescription?: string;
  private distinctFeatures?: string;
  private accentsLanguage?: string;
  private distinctWordsPhrases?: string;

  private savedIsSuspectKnown?: string;
  private savedSuspectNames?: string;
  private savedHowDidSuspectKnowYou?: string;
  private savedApproximateAge?: string;
  private savedApproximateHeight?: string;
  private savedRaceAppearance?: string;
  private savedSkinColour?: string;
  private savedHairColour?: string;
  private savedSuspectGender?: string;
  private savedBuildDescription?: string;
  private savedDistinctFeatures?: string;
  private savedAccentsLanguage?: string;
  private savedDistinctWordsPhrases?: string;

  public hasBeenFilled: { [inputFieldName: string]: boolean } = {
    isSuspectKnown: false,
    suspectNames: false,
    howDidSuspectKnowYou: false,
    approximateAge: false,
    approximateHeight: false,
    raceAppearance: false,
    skinColour: false,
    hairColour: false,
    suspectGender: false,
    buildDescription: false,
    distinctFeatures: false,
    accentsLanguage: false,
    distinctWordsPhrases: false
  };
  public hasChanged: boolean = false;

  static generateSavedSuspectForm(data: SuspectDto, hasReportPassword?: boolean): SuspectForm {
    const suspectForm = new SuspectForm(data.suspectId, hasReportPassword, data);
    data?.inputFields?.forEach((inputField) => {
      if (inputField.inputFieldName === 'suspectDistinctFeatures') {
        suspectForm.hasBeenFilled['distinctFeatures'] = true;
      } else {
        suspectForm.hasBeenFilled[inputField.inputFieldName] = true;
      }
    });
    return suspectForm;
  }

  constructor(suspectId: number, hasReportPassword?: boolean, data?: SuspectDto) {
    super();

    this.suspectId = suspectId;
    this.hasReportPassword = hasReportPassword || false;

    if (data) {
      const isSuspectKnown = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'isSuspectKnown')?.inputFieldValue;
      this.isSuspectKnown = isSuspectKnown;
      this.savedIsSuspectKnown = isSuspectKnown;

      const suspectNames = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'suspectNames')?.inputFieldValue;
      this.suspectNames = suspectNames;
      this.savedSuspectNames = suspectNames;

      const howDidSuspectKnowYou = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'howDidSuspectKnowYou')?.inputFieldValue;
      this.howDidSuspectKnowYou = howDidSuspectKnowYou;
      this.savedHowDidSuspectKnowYou = howDidSuspectKnowYou;

      const approximateAge = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'approximateAge')?.inputFieldValue;
      this.approximateAge = approximateAge;
      this.savedApproximateAge = approximateAge;

      const approximateHeight = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'approximateHeight')?.inputFieldValue;
      this.approximateHeight = approximateHeight;
      this.savedApproximateHeight = approximateHeight;

      const raceAppearance = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'raceAppearance')?.inputFieldValue;
      this.raceAppearance = raceAppearance;
      this.savedRaceAppearance = raceAppearance;

      const skinColour = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'skinColour')?.inputFieldValue;
      this.skinColour = skinColour;
      this.savedSkinColour = skinColour;

      const hairColour = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'hairColour')?.inputFieldValue;
      this.hairColour = hairColour;
      this.savedHairColour = hairColour;

      const suspectGender = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'suspectGender')?.inputFieldValue;
      this.suspectGender = suspectGender;
      this.savedSuspectGender = suspectGender;

      const buildDescription = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'buildDescription')?.inputFieldValue;
      this.buildDescription = buildDescription;
      this.savedBuildDescription = buildDescription;

      const distinctFeatures = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'suspectDistinctFeatures')?.inputFieldValue;
      this.distinctFeatures = distinctFeatures;
      this.savedDistinctFeatures = distinctFeatures;

      const accentsLanguage = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'accentsLanguage')?.inputFieldValue;
      this.accentsLanguage = accentsLanguage;
      this.savedAccentsLanguage = accentsLanguage;

      const distinctWordsPhrases = data.inputFields?.find(({ inputFieldName }) => inputFieldName === 'distinctWordsPhrases')?.inputFieldValue;
      this.distinctWordsPhrases = distinctWordsPhrases;
      this.savedDistinctWordsPhrases = distinctWordsPhrases;
    }
  }

  getId() {
    return this.suspectId;
  }

  hasBeenPreviouslySaved(): boolean {
    if (Object.values(this.hasBeenFilled).some((value) => value === true)) {
      return true;
    }
    return false;
  }

  isAtLeastOneFieldFilled() {
    return (
      this.isSuspectKnown
      || this.suspectNames
      || this.howDidSuspectKnowYou
      || this.approximateAge
      || this.approximateHeight
      || this.raceAppearance
      || this.skinColour
      || this.hairColour
      || this.suspectGender
      || this.buildDescription
      || this.distinctFeatures
      || this.accentsLanguage
      || this.distinctWordsPhrases
    );
  }

  isReadyToSave() {
    if (
      ( this.compare(this.isSuspectKnown, this.savedIsSuspectKnown, this.hasReportPassword) !== undefined
        || this.compare(this.suspectNames, this.savedSuspectNames, this.hasReportPassword) !== undefined
        || this.compare(this.howDidSuspectKnowYou, this.savedHowDidSuspectKnowYou, this.hasReportPassword) !== undefined
        || this.compare(this.approximateAge, this.savedApproximateAge, this.hasReportPassword) !== undefined
        || this.compare(this.approximateHeight, this.savedApproximateHeight, this.hasReportPassword) !== undefined
        || this.compare(this.raceAppearance, this.savedRaceAppearance, this.hasReportPassword) !== undefined
        || this.compare(this.skinColour, this.savedSkinColour, this.hasReportPassword) !== undefined
        || this.compare(this.hairColour, this.savedHairColour, this.hasReportPassword) !== undefined
        || this.compare(this.suspectGender, this.savedSuspectGender, this.hasReportPassword) !== undefined
        || this.compare(this.buildDescription, this.savedBuildDescription, this.hasReportPassword) !== undefined
        || this.compare(this.distinctFeatures, this.savedDistinctFeatures, this.hasReportPassword) !== undefined
        || this.compare(this.accentsLanguage, this.savedAccentsLanguage, this.hasReportPassword) !== undefined
        || this.compare(this.distinctWordsPhrases, this.savedDistinctWordsPhrases, this.hasReportPassword) !== undefined )
      && this.isAtLeastOneFieldFilled()
    ) {
      return true;
    }
    return false;
  }

  getSubmissionData() {
    return {
      suspectId: this.suspectId,
      isSuspectKnown: this.compare(this.isSuspectKnown, this.savedIsSuspectKnown, this.hasReportPassword),
      suspectNames: this.compare(this.suspectNames, this.savedSuspectNames, this.hasReportPassword),
      howDidSuspectKnowYou: this.compare(this.howDidSuspectKnowYou, this.savedHowDidSuspectKnowYou, this.hasReportPassword),
      approximateAge: this.compare(this.approximateAge, this.savedApproximateAge, this.hasReportPassword),
      approximateHeight: this.compare(this.approximateHeight, this.savedApproximateHeight, this.hasReportPassword),
      raceAppearance: this.compare(this.raceAppearance, this.savedRaceAppearance, this.hasReportPassword),
      skinColour: this.compare(this.skinColour, this.savedSkinColour, this.hasReportPassword),
      hairColour: this.compare(this.hairColour, this.savedHairColour, this.hasReportPassword),
      suspectGender: this.compare(this.suspectGender, this.savedSuspectGender, this.hasReportPassword),
      buildDescription: this.compare(this.buildDescription, this.savedBuildDescription, this.hasReportPassword),
      distinctFeatures: this.compare(this.distinctFeatures, this.savedDistinctFeatures, this.hasReportPassword),
      accentsLanguage: this.compare(this.accentsLanguage, this.savedAccentsLanguage, this.hasReportPassword),
      distinctWordsPhrases: this.compare(this.distinctWordsPhrases, this.savedDistinctWordsPhrases, this.hasReportPassword)
    };
  }

  initialize(data: {
    isSuspectKnown?: string,
    suspectNames?: string,
    howDidSuspectKnowYou?: string,
    approximateAge?: string,
    approximateHeight?: string,
    raceAppearance?: string,
    skinColour?: string,
    hairColour?: string,
    suspectGender?: string,
    buildDescription?: string,
    distinctFeatures?: string,
    accentsLanguage?: string,
    distinctWordsPhrases?: string
  }) {
    if (data.isSuspectKnown) {
      this.isSuspectKnown = data.isSuspectKnown;
    }
    if (data.suspectNames) {
      this.suspectNames = data.suspectNames;
    }
    if (data.howDidSuspectKnowYou) {
      this.howDidSuspectKnowYou = data.howDidSuspectKnowYou;
    }
  }

  setIsSuspectKnown(isSuspectKnown: string) {
    this.isSuspectKnown = isSuspectKnown;
    this.hasChanged = true;
  }

  setSuspectNames(suspectNames: string) {
    this.suspectNames = suspectNames;
    this.hasChanged = true;
  }

  setHowDidSuspectKnowYou(howDidSuspectKnowYou: string) {
    this.howDidSuspectKnowYou = howDidSuspectKnowYou;
    this.hasChanged = true;
  }

  setApproximateAge(approximateAge: string) {
    this.approximateAge = approximateAge;
    this.hasChanged = true;
  }

  setApproximateHeight(approximateHeight: string) {
    this.approximateHeight = approximateHeight;
    this.hasChanged = true;
  }

  setRaceAppearance(raceAppearance: string) {
    this.raceAppearance = raceAppearance;
    this.hasChanged = true;
  }

  setSkinColour(skinColour: string) {
    this.skinColour = skinColour;
    this.hasChanged = true;
  }

  setHairColour(hairColour: string) {
    this.hairColour = hairColour;
    this.hasChanged = true;
  }

  setSuspectGender(gender: string) {
    this.suspectGender = gender;
    this.hasChanged = true;
  }

  setBuildDescription(buildDescription: string) {
    this.buildDescription = buildDescription;
    this.hasChanged = true;
  }

  setDistinctFeatures(distinctFeatures: string) {
    this.distinctFeatures = distinctFeatures;
    this.hasChanged = true;
  }

  setAccentsLanguage(accentsLanguage: string) {
    this.accentsLanguage = accentsLanguage;
    this.hasChanged = true;
  }

  setDistinctWordsPhrases(distinctWordsPhrases: string) {
    this.distinctWordsPhrases = distinctWordsPhrases;
    this.hasChanged = true;
  }
}

const suspectSectionFormService = new SuspectSectionFormService();

export default suspectSectionFormService;
