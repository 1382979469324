import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BaseAlert from '../base-alert';
import { DefaultBodyText, ImpactStatementHeader } from '../../../shared/components';
import PrimaryButton from '../../../shared/components/primary-button';
import SecondaryButton from '../../../shared/components/secondary-button';
import { sectionLeaveAlertContentProvider } from './content/section-leave-alert.content';
import { sectionLeaveAlertStyleFactory } from './section-leave-alert.styles';
import { SECTION_TITLE_BY_ROUTE } from './section-leave-alert.constants';
import { useSectionLeaveAlertController } from './section-leave-alert.controller';

const content = sectionLeaveAlertContentProvider();
const stylesFactory = sectionLeaveAlertStyleFactory;

const SectionLeaveAlert: React.FunctionComponent<{
  nextRoute: string;
} & RouteComponentProps> = ({location, nextRoute}) => {

  const layout = stylesFactory();

  const {
    handleLeaveSection,
    handleCancel
  } = useSectionLeaveAlertController(nextRoute);

  return (
    <BaseAlert aria-labelledby='leaveSessionHeader'>
      <div id='leaveSessionHeader'>
        <ImpactStatementHeader>{content.header}</ImpactStatementHeader>
      </div>
      <div className={layout.description}>
        <DefaultBodyText fontSize="1rem">
          {content.descriptionPart1}
          <b>{SECTION_TITLE_BY_ROUTE[location.pathname]}</b>
          {content.descriptionPart2}
        </DefaultBodyText>
      </div>
      <div className={layout.description}>
        <DefaultBodyText fontSize="1rem">{content.warning}</DefaultBodyText>
      </div>
      <div className={layout.ctaButtons}>
        <PrimaryButton onClick={handleLeaveSection} width="230px"
                       isActive={true}>{content.leaveSectionButtonLabel}</PrimaryButton>
        <SecondaryButton onClick={handleCancel} width="230px">{content.cancelButtonLabel}</SecondaryButton>
      </div>
    </BaseAlert>
  );
};

export default withRouter(SectionLeaveAlert);
