import { CreateVictimSectionDto } from "../data-transfer-objects/create-victim-section.dto";
import { CreateVictimSectionOptions } from "../interfaces/section-service.interface";

export function createVictimSectionRequestBody(
  minimumRequestBody: CreateVictimSectionDto,
  data: CreateVictimSectionOptions
): CreateVictimSectionDto {
  let requestBody = { ...minimumRequestBody }
  if (data.age !== undefined) {
    requestBody.age = {
      inputFieldName: 'age',
      inputFieldValue: data.age
    }
  }
  if (data.gender) {
    requestBody.gender = {
      inputFieldName: 'gender',
      inputFieldValue: data.gender
    }
  }
  if (data.victimRaceAppearance) {
    requestBody.victimRaceAppearance = {
      inputFieldName: 'victimRaceAppearance',
      inputFieldValue: data.victimRaceAppearance
    }
  }
  return requestBody
}
