import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { CSSTransition } from 'react-transition-group';
import '../animations/notification.css';

// components
import { DefaultBodyText } from '../../shared/components';
import { colorPalette } from '../../shared/theme';
import ServiceLocator from '../../shared/service-locator/service-locator';

const useStyles = createUseStyles({
  notification: {
    background: colorPalette.lavender,
    padding: '1.5rem 2.5rem',
    borderRadius: '0.5rem'
  }
});

const reportService = ServiceLocator.getInstance().getReportService();

const ReturnCallOut = () => {
  const {notification} = useStyles();
  const [showNotification, setShowNotification] = useState<boolean>(false);
  useEffect(() => {
    if (reportService.dashboardData?.showReturnCallOut) {
      setShowNotification(true);
    }
  }, []);

  return (
    <CSSTransition
      in={showNotification}
      timeout={800}
      classNames="notification"
      unmountOnExit
    >
      <div className={notification}>
        <DefaultBodyText>
          <b>Sections that contain information entered will appear in purple. </b>
          {reportService.hasReportPassword
            ? "You can always add or edit your information."
            : "For your privacy, previously entered details will be hidden but you can add more information to these questions again — new answers will be added to your existing report."}
        </DefaultBodyText>
      </div>
    </CSSTransition>
  );
};

ReturnCallOut.defaultProps = {
  showReturnCallOut: false
};

export default ReturnCallOut;
