import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BaseAlert from "../base-alert";
import { ImpactStatementHeader, DefaultBodyText, DefaultHeader } from "../../../shared/components";
import PrimaryButton from '../../../shared/components/primary-button';
import SecondaryButton from '../../../shared/components/secondary-button';
import ServiceLocator from '../../../shared/service-locator/service-locator';
import { useLayout, useStyle } from './styles';
import SectionTitleListItem from './section-title-list-item';
import alertService from '../../services/alert-service';
import { ROUTES } from '../../../constants'

const reportService = ServiceLocator.getInstance().getReportService();
const analyticsService = ServiceLocator.getInstance().getAnalyticsService();

const SubmitFormAlert: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const layout = useLayout();
  const style = useStyle();
  const savedSections = reportService.savedSections;

  const handleSubmit = async () => {
    setIsLoading(true)

    analyticsService.logEvent(
      'submit_report',
      {
        region_name: reportService.region.regionName
      },
    )

    try {
      await reportService.submitReport();
      setIsLoading(false);
      history.push(ROUTES.THANK_YOU);
      alertService.clearAlert();
    } catch (e) {
      setIsLoading(false);
    }
    return;
  };

  const handleClose = () => {
    alertService.clearAlert();
  };

  return (
    <BaseAlert aria-labelledby='submitHeader'>
      <ImpactStatementHeader>Ready to submit?</ImpactStatementHeader>
      <div className={layout.description}>
        <DefaultBodyText fontSize="1rem">We understand it’s a difficult thing to share; thank you for taking the time
          to provide these details.</DefaultBodyText>
      </div>
      <div className={layout.supportBlock}>
        <div className={style.supportBlock}>
          <DefaultHeader>You have input information in the following sections of your report:</DefaultHeader>
          <div className={layout.sectionsList}>
            <ul style={{ margin: 0 }}>
              {
                savedSections?.map((sectionType) => {
                  return (
                    <SectionTitleListItem key={sectionType} sectionType={sectionType}/>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </div>
      <div className={layout.ctaButtons}>
        <PrimaryButton isLoading={isLoading} onClick={handleSubmit} width="170px">SUBMIT NOW</PrimaryButton>
        <SecondaryButton onClick={handleClose} width="170px">CANCEL</SecondaryButton>
      </div>
    </BaseAlert>
  );
};

export default withRouter(SubmitFormAlert);
