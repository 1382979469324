import React, { useEffect } from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import formService from '../services/date-and-location-section-form-service';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { dateAndLocationSection } from '../models/date-and-location-section.model';
// components
import { DefaultBodyText } from '../../shared/components';
import TextAreaInputField from '../../shared/components/text-area-input-field';
import { useFormStyles } from '../../shared/theme/form-styles';

const Grid = createGrid();
const reportService = ServiceLocator.getInstance().getReportService();
const model = dateAndLocationSection.distinctFeaturesAboutTheLocation;

const DistinctFeaturesAboutLocationQuestion = (props: any) => {
  const styles = useFormStyles();
  const dateAndLocationSectionData = reportService.dateAndLocationSectionData;
  const hasBeenFilled = !!dateAndLocationSectionData?.distinctFeatures;

  useEffect(() => {
    return () => formService.setDistinctFeatures('');
  }, []);

  return (
    <div>
      <label htmlFor='locationFeature' style={{margin: 'unset'}}>
        <DefaultBodyText
          color={hasBeenFilled ? 'grape' : 'navy'}>{model.distinctFeaturesAboutTheLocation.label}</DefaultBodyText>
      </label>
      <Grid container justify='center'>
        <Grid item flex={{xs: 12, md: 10}}>
          <div className={styles.conditionalQuestionContainer}>
            <TextAreaInputField
              initialValue={reportService.dateAndLocationSectionData?.distinctFeatures?.inputFieldValue}
              onChange={(distinctFeatures) => formService.setDistinctFeatures(distinctFeatures)}
              placeholder={model.distinctFeaturesAboutTheLocation.placeholder}
              hasBeenFilled={hasBeenFilled}
              id='locationFeature'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DistinctFeaturesAboutLocationQuestion;
