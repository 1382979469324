import React from 'react';
import { createUseStyles } from 'react-jss';
import { BpTheme, fontFamilies } from '../../../../shared/theme';
import closeImg from './closePurple.png';

const useStyles = createUseStyles((theme: BpTheme) => ( {
  cancelButtonWrap: {
    position: 'absolute',
    right: '5%',
    paddingTop: '20px',
  },
  cancelButton: {
    transition: 'transform .2s ease',
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.colorPalette.grape,
    fontSize: '0.875rem',
    fontFamily: [theme.fontFamilies.AvenirBlack, theme.fontFamilies.Avenir, fontFamilies.SansSerif],
    fontWeight: 'bold',
    letterSpacing: '1px',
    padding: '10px 20px',
    '&:hover': {
      cursor: 'pointer',
      transform: 'translateX(-1.5%)'
    },
    '&:focus': {
      transform: 'translateX(-2%)',
      outline: 'none',
      textDecoration: 'underline',
    },
  },
  closeImg: {
    width: '18px',
    height: '18px',
    verticalAlign: 'top',
    marginLeft: '10px',
  },
} ));

const AlertCancelButton: React.FunctionComponent<{
  handleCancel: () => void,
  buttonLabel: string
}> = ({ handleCancel, buttonLabel }) => {
  const styles = useStyles();
  return (
    <div className={styles.cancelButtonWrap}>
      <button onClick={handleCancel} className={styles.cancelButton}>
        {buttonLabel}
        <img src={closeImg} alt="close icon" className={styles.closeImg} aria-hidden="true"/>
      </button>
    </div>

  );
};

export default AlertCancelButton;
