import { BehaviorSubject, Observable } from 'rxjs'
import ServiceLocator from '../shared/service-locator/service-locator'
import snackBarService from '../snackbar/services/snackbar-service';
import alertService from '../alerts/services/alert-service';

const reportService = ServiceLocator.getInstance().getReportService()
const sectionService = ServiceLocator.getInstance().getSectionService()

class SequenceOfEventsSectionFormService {
  private description?: string
  private _isReadyToSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public isReadyToSave: Observable<boolean> = this._isReadyToSave.asObservable()

  private updateIsReadyToSaveFlag() {
    if (this.description) {
      this._isReadyToSave.next(true)
    } else {
      this._isReadyToSave.next(false)
    }
  }

  public setDescription(value: string) {
    this.description = value
    this.updateIsReadyToSaveFlag()
  }

  public clearForm() {
    this.description = ''
    this._isReadyToSave.next(false)
  }

  public isFormReadyToBeSaved(): boolean {
    return this._isReadyToSave.value
  }

  public async submit() {
    const reportCode = reportService.reportCode
    await sectionService.createSequenceOfEventsSection(reportCode, { description: this.description })
    await reportService.resumeReport()
    snackBarService.displaySectionSavedSnackbar()
    alertService.alertFirstTimeSave()
    return
  }
}

const sequenceOfEventsSectionFormService = new SequenceOfEventsSectionFormService()

export default sequenceOfEventsSectionFormService
