import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import victimSectionFormService from "./victim-section.form-service";
import ServiceLocator from "../shared/service-locator/service-locator";
import { REPORT_ROUTES } from "../constants";

const analyticsService = ServiceLocator.getInstance().getAnalyticsService()
const reportService = ServiceLocator.getInstance().getReportService()

export function useVictimSectionController() {
  const history = useHistory()
  const victimSectionData = reportService.victimSectionSavedData

  const initialAgeValue = victimSectionData?.age?.inputFieldValue as string || ''
  const initialGenderValue = victimSectionData?.gender?.inputFieldValue as string || ''
  const initialVictimRaceAppearanceValue = victimSectionData?.victimRaceAppearance?.inputFieldValue as string || ''
  const showReturnCallOut = victimSectionData?.showReturnCallOut
  const isWithPassword =  reportService.hasReportPassword
  const ageHasBeenFilled = !!victimSectionData?.age
  const genderHasBeenFilled = !!victimSectionData?.gender
  const victimRaceAppearanceHasBeenFilled = !!victimSectionData?.victimRaceAppearance

  const [ isReadyToSave, setIsReadyToSave ] = useState<boolean>(false)
  const [ isSaveAndNextSectionLoading, setIsSaveAndNextSectionLoading ] = useState<boolean>(false)
  const [ isSaveAndCloseLoading, setIsSaveAndCloseLoading ] = useState<boolean>(false)

  const handleAgeChange = (value: string) => {
    victimSectionFormService.setAge(value)
  }

  const handleGenderChange = (value: string) => {
    victimSectionFormService.setGender(value)
  }

  const handleVictimRaceAppearanceChange = (value: string) => {
    victimSectionFormService.setVictimRaceAppearance(value)
  }

  const handleSaveAndNext = async () => {
    if (isSaveAndCloseLoading) {
      return
    }
    analyticsService.logEvent(
      'save_report',
      {
        section: 'victim'
      }
    )
    setIsSaveAndNextSectionLoading(true)
    try {
      await victimSectionFormService.submit()
      setIsSaveAndNextSectionLoading(false)
      history.push(REPORT_ROUTES.DATE_AND_LOCATION_SECTION)
    } catch (e) {
      setIsSaveAndNextSectionLoading(false)
    }
  }

  // redirect to dashboard
  const handleSaveAndClose = async () => {
    if (isSaveAndCloseLoading) {
      return
    }
    analyticsService.logEvent(
      'save_report',
      {
        section: 'victim'
      }
    )
    setIsSaveAndCloseLoading(true)
    try {
      await victimSectionFormService.submit()
      setIsSaveAndCloseLoading(false)
      history.push(REPORT_ROUTES.DASHBOARD)
    } catch (e) {
      setIsSaveAndCloseLoading(false)
    }
  }

  useEffect(() => {
    analyticsService.viewPage('/report/victim-section')

    const unsubscribe: Subject<void> = new Subject()

    victimSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe(isReadyToSave => {
      setIsReadyToSave(isReadyToSave)
    })

    victimSectionFormService.initialize()

    return () => {
      victimSectionFormService.clearForm()
      unsubscribe.next()
      unsubscribe.complete()
    }
  }, [])

  return {
    initialAgeValue,
    initialGenderValue,
    initialVictimRaceAppearanceValue,
    ageHasBeenFilled,
    genderHasBeenFilled,
    victimRaceAppearanceHasBeenFilled,
    isSaveAndNextSectionLoading,
    isSaveAndCloseLoading,
    isReadyToSave,
    showReturnCallOut,
    isWithPassword,
    handleAgeChange,
    handleGenderChange,
    handleVictimRaceAppearanceChange,
    handleSaveAndNext,
    handleSaveAndClose
  }
}
