import suspectSectionFormService, { SuspectForm } from "../../../services/suspect-section-form-service";
import { useEffect } from "react";
import ServiceLocator from "../../../../shared/service-locator/service-locator";

const reportService = ServiceLocator.getInstance().getReportService();

export function useDoKnowThisPersonController(form: SuspectForm) {

  const suspectSavedData = reportService.suspectSectionData?.suspects?.find(({ suspectId }) => suspectId === form.getId());

  // using directly from saved data to avoid field getting cleared
  const initialHowDidSuspectKnowYou = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'howDidSuspectKnowYou')?.inputFieldValue;
  const initialSuspectNames = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'suspectNames')?.inputFieldValue;

  const handleSuspectNamesChange = (value: string) => {
    form.setSuspectNames(value);
    suspectSectionFormService.notifyFormChanged();
  };

  const handleHowDidSuspectKnowYouChange = (value: string) => {
    form.setHowDidSuspectKnowYou(value);
    suspectSectionFormService.notifyFormChanged();
  };

  useEffect(() => {
    // initialize on mount
    form.initialize({
      suspectNames: initialSuspectNames,
      howDidSuspectKnowYou: initialHowDidSuspectKnowYou
    });

    return () => {
      form.setHowDidSuspectKnowYou('');
      form.setSuspectNames('');
      suspectSectionFormService.notifyFormChanged();
    };
  }, [form]);

  return {
    initialHowDidSuspectKnowYou,
    initialSuspectNames,
    handleHowDidSuspectKnowYouChange,
    handleSuspectNamesChange
  };
}
