import React from 'react';
import { Content as ContentModel } from '../models/content.model';

interface Props {
  content: ContentModel[]
}

export const Content: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      {props.content.map((description: ContentModel, index: number) => {
        return (
          <span key={index}>
            {
              description.isBolded ? (
                <b>{description.text}</b>
              ) : (
                <>{description.text}</>
              )
            }
          </span>
        );
      })}
    </>
  );
};


