import cookie from 'react-cookies'

import { HttpClient, RequestOptions } from "./http-client.interface";
import { AUTH_TOKEN_NAME } from '../../auth/services/main-auth-service';

class AuthHttpClient implements HttpClient {
  private client: HttpClient;

  public constructor(client: HttpClient) {
    this.client = client
  }

  public async get(url: string, options: RequestOptions = {}) {
    const response = await this.client.get(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${cookie.load(AUTH_TOKEN_NAME)}`
      }
    })
    return response
  }
  
  public async post(url: string, options: RequestOptions = {}) {
    const response = await this.client.post(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${cookie.load(AUTH_TOKEN_NAME)}`
      }
    })
    return response
  }

  public async put(url: string, options: RequestOptions = {}) {
    const response = await this.client.put(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${cookie.load(AUTH_TOKEN_NAME)}`
      }
    })
    return response
  }
}

export default AuthHttpClient;
