import { createUseStyles } from "react-jss";
import { BpTheme } from "../../../../shared/theme";

interface StyleProps {
  isValid: boolean,
  isHidden: boolean
}

export const passwordInputFieldStylesFactory = createUseStyles((theme: BpTheme) => ( {
  inputFieldContainer: {
    borderBottom: (props: StyleProps) => `1px solid ${props.isValid ? theme.colorPalette.orangeRed : theme.colorPalette.grape}`,
    alignItems: 'center',
    position: 'relative'
  },
  inputField: {
    ...theme.fontStylesMobile.textInput,
    background: 'none',
    padding: '1px 0',
    border: 'none',
    color: (props: StyleProps) => props.isValid ? theme.colorPalette.orangeRed : theme.colorPalette.navy,
    width: '100%',
    height: '46px',
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.colorPalette.lavender,
    },
    fontWeight: 'bold',
    textDecoration: 'italics',
    '&::placeholder': {
      fontWeight: 'normal',
      color: theme.colorPalette.brownGrey,
      fontStyle: 'italic'
    },
  },
  passwordImg: {
    position: 'absolute',
    right: 0,
    width: 44,
    '&:hover': {
      cursor: 'pointer'
    },
    '&:focus': {
      transform: 'translateX(-2%)',
      outline: 'none',
    }
  },
  labelErrorMessage: {
    paddingTop: '4px'
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  inputLabel: {
    margin: 'unset',
  },
} ));
