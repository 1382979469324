import React from 'react';

import TextInputField from "../../../../shared/components/text-input-field";
import { useFormStyles } from '../../../../shared/theme/form-styles';
import { SuspectForm } from '../../../services/suspect-section-form-service';

import { suspectSection as model } from '../../../models/suspect-section.model';
import { Divider } from '../../../../shared/components';
import { useDoKnowThisPersonController } from './do-know-this-person.controller';

const content = model.suspectIdentity;

const DoKnowThisPerson: React.FunctionComponent<{ form: SuspectForm }> = ({form}) => {
  const formStyles = useFormStyles();

  const {
    initialHowDidSuspectKnowYou,
    initialSuspectNames,
    handleHowDidSuspectKnowYouChange,
    handleSuspectNamesChange
  } = useDoKnowThisPersonController(form);

  return (
    <div>
      <div className={formStyles.question}>
        <TextInputField
          initialValue={initialSuspectNames}
          label={content.YES_suspectNames.label}
          onChange={handleSuspectNamesChange}
          hasBeenFilled={form.hasBeenFilled.suspectNames}
          id='name'
        />
      </div>
      <div className={formStyles.question}>
        <TextInputField
          initialValue={initialHowDidSuspectKnowYou}
          label={content.YES_howDidSuspectKnowYou.label}
          onChange={handleHowDidSuspectKnowYouChange}
          hasBeenFilled={form.hasBeenFilled.howDidSuspectKnowYou}
          id='howDidSuspectKnowYou'
        />
      </div>
      <div className={formStyles.divider}>
        <Divider width="100%" thickness="thin"/>
      </div>
    </div>
  );
};

export default DoKnowThisPerson;
