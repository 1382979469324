// dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { BpTheme } from '../../theme';

// component definition
interface Props extends React.HTMLProps<HTMLLinkElement> {
  to?: string | Record<string, any>,
  invert?: boolean
}

// styling
const useStyles = createUseStyles((theme: BpTheme) => ( {
  link: {
    color: (props: Props) => props.invert ? theme.colorPalette.white : theme.colorPalette.grape,
    ...theme.fontStylesMobile.link
  }
} ));

export const BpLink: React.FunctionComponent<Props> = (props: Props) => {
  if (( !props.href && !props.to ) || ( props.href && props.to )) {
    throw new Error('Only one of `href` or `to` must be provided');
  }

  const classes = useStyles({ ...props });

  const classNames = [classes.link];
  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <>
      {
        props.to ? (
          <Link to={props.to} className={classNames.join(' ')}>{props.children}</Link>

        ) : (
          <a href={props.href} className={classNames.join(' ')} target={props.target}>{props.children}</a>
        )
      }
    </>
  );
};

BpLink.defaultProps = {
  invert: false
};
