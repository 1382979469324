import React from 'react';
import Loader from 'react-loader-spinner';

// theme
import { Color, colorPalette } from '../theme';

// components
import BaseButton from './base-button';

interface PrimaryButtonProps {
  children: React.ReactNode;
  isActive?: boolean;
  width?: string;
  onClick?: () => void;
  isLoading?: boolean;
}

export const PrimaryButton: React.FunctionComponent<PrimaryButtonProps> = ({ children, onClick, isActive, width, isLoading }) => {
  const borderColor: Color = isActive ? "grape" : "lightGrey";
  const fontColor: Color = isActive ? "white" : "lightGrey";

  if (isLoading) {
    return (
      <BaseButton
        borderColor="grape"
        backgroundColor="grape"
        fontColor="white"
        width={width}
      >
        <Loader type="ThreeDots" color={colorPalette.purple} height={10} width={30}/>
      </BaseButton>
    );
  }

  return (
    <BaseButton onClick={onClick} borderColor={borderColor} backgroundColor="grape" fontColor={fontColor} width={width}>
      {children?.toString().toUpperCase()}
    </BaseButton>
  );
};

PrimaryButton.defaultProps = {
  width: '100%',
  isLoading: false,
  isActive: true
};

export default PrimaryButton;
