import React, { useState, useEffect, useRef, useContext } from 'react';
import ReactToPrint from 'react-to-print';
import { createUseStyles } from 'react-jss';
import { createGrid } from '@nascentdigital/react-lattice';
import CopyToClipboard from 'react-copy-to-clipboard';
import { dashboard } from '../models/dashboard.model';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { BpTheme, breakpoints } from '../../shared/theme';

// components
import { ImpactStatementHeader, DefaultHeader, DefaultBodyText } from '../../shared/components';
import Subheading from './dashboard-subheading';
import TertiaryButton from '../../shared/components/tertiary-button';
import ReportCode from './report-code';
import { PrimaryButton } from '../../shared/components';
import ReturnCallOut from './dashboard-return-call-out';
import { PrintTemplate } from './print-template';
import { formatReportCode } from '../../shared/utils/format-report-code';
import { useFormStyles } from '../../shared/theme/form-styles';
import { NavigationContext } from '../../report-container/navigation-context';
import { useScrollToElementTop } from '../../shared/hooks/use-scroll-top-element-top'
import HelplineResourcesLink from "../../shared/components/helpline-resources-link/helpline-resources-link"

// styling
const useLayout = createUseStyles((theme: BpTheme) => ( {
  impactStatement: {
    paddingTop: 19,
  },
  reportCode: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  subhead: {
    marginTop: '20px'
  },
  supportBlockDescription: {
    margin: [12, 0]
  },
  returnCallOut: {
    marginTop: '30px'
  },
  supportBlocks: {
    marginTop: '30px',
    padding: [16, 12],
    border: `1px solid ${theme.colorPalette.grape}`,
    borderRadius: '8px'
  },
  highlight: {
    borderRadius: '8px',
    background: theme.colorPalette.lavender
  },
  code: {
    margin: [16, 0]
  },
  button: {
    [theme.mediaQueries.tabletAndAbove]: {
      margin: [0, 0, 0, 24]
    }
  },
  fixedBanner: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    padding: [32, 64],
    backdropFilter: 'blur(1px)',
    background: 'linear-gradient(to top, rgb(246, 244, 255), rgba(247, 245, 255, 0.76), rgba(251, 250, 255, 0))',
    [theme.mediaQueries.tabletAndAbove]: {
      display: 'none'
    }
  }
} ));

const Grid = createGrid({ breakpoints });

const reportService = ServiceLocator.getInstance().getReportService();
const analyticsService = ServiceLocator.getInstance().getAnalyticsService();


// component definition
const DashboardPage: React.FunctionComponent = () => {
  const [reportCode] = useState<string>(reportService.reportCode);
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const showReturnCallOut = reportService.dashboardData?.showReturnCallOut;
  const layout = useLayout();
  const formClasses = useFormStyles();
  const printComponentRef = useRef(null);

  const scrollTopRef = useRef(null);
  useScrollToElementTop(scrollTopRef);

  useEffect(() => {
    analyticsService.viewPage('/report/dashboard');
  }, []);

  useEffect(() => {
    if (recentlyCopied) {
      setTimeout(() => setRecentlyCopied(false), 1000);
    }
  }, [recentlyCopied]);

  const navContext = useContext(NavigationContext);

  return (
    <div className={formClasses.page} ref={scrollTopRef}>
      <div className={layout.impactStatement}>
        <ImpactStatementHeader size="medium">{dashboard.impactStatement}</ImpactStatementHeader>
      </div>

      <HelplineResourcesLink/>

      <Grid container direction={{ xs: 'columnReverse', md: 'column' }}>
        <Grid container direction={{ xs: 'column', md: 'row' }} className={layout.subhead}>
          {
            dashboard.subheads.map((subhead, index) => <Subheading key={`subhead-${index}`} subhead={subhead}/>)
          }
        </Grid>
        <div className={showReturnCallOut ? layout.returnCallOut : ''}>
          <ReturnCallOut/>
        </div>
        <Grid container alignItems="center" className={layout.supportBlocks}>
          <Grid item flex={{ xs: 12, md: 6 }}>
            <DefaultHeader>{dashboard.supportBlock2.heading}</DefaultHeader>
            <div className={layout.supportBlockDescription}>
              <DefaultBodyText>{dashboard.supportBlock2.description}</DefaultBodyText>
            </div>
          </Grid>
          <Grid item flex={{ xs: 12, md: 6 }} className={layout.highlight} container justify="center"
                direction="column">
            <Grid item container justify="center" className={layout.code}>
              <ReportCode shortCode={formatReportCode(reportCode)}/>
            </Grid>
            <Grid container wrap="none">
              <Grid item flex={{ xs: 6 }}>
                <div className={layout.button}>
                  <CopyToClipboard text={reportCode.toUpperCase()}>
                    <TertiaryButton onClick={() => setRecentlyCopied(true)}
                                    color={recentlyCopied ? 'mediumGrey' : 'grape'}>{dashboard.supportBlock2.copyCodeButton.label}</TertiaryButton>
                  </CopyToClipboard>
                </div>
              </Grid>
              <Grid item flex={{ xs: 6 }}>
                <div className={layout.button}>
                  <ReactToPrint
                    trigger={() => <TertiaryButton
                      color="grape">{dashboard.supportBlock2.printCodeButton.label}</TertiaryButton>}
                    content={() => printComponentRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <PrintTemplate ref={printComponentRef} reportCode={reportCode}/>
                  </div>
                </div>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <section className={layout.fixedBanner}>
        <PrimaryButton onClick={() => {
          if (!navContext.showNav) {
            navContext.toggleNav();
          }
        }}>START MY REPORT</PrimaryButton>
      </section>
    </div>
  );
};

export default DashboardPage;
