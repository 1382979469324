import { createUseStyles } from 'react-jss';
import { BpTheme } from './';
import colorPalette from './color-palette';

export const useFormStyles = createUseStyles((theme: BpTheme) => ( {
  page: {
    padding: [0, 32, 100, 64],
    position: 'relative',
    [theme.mediaQueries.tabletAndAbove]: {
      padding: [0, 64]
    }
  },
  backButton: {
    paddingTop: 16,
    [theme.mediaQueries.tabletAndAbove]: {
      paddingTop: 64
    }
  },
  header: {
    paddingTop: '55px'
  },
  infoBlock: {
    paddingTop: '24px'
  },
  divider: {
    paddingTop: '32px'
  },
  question: {
    paddingTop: '32px'
  },
  buttonsContainer: {
    paddingTop: '60px',
    paddingBottom: '60px',
  },
  questionGroup: {
    marginTop: 24
  },
  questionGroupInternalPadding: {
    marginTop: 24,
    '&>div:not(:first-child)': {
      marginTop: 24
    },
    [theme.mediaQueries.tabletAndAbove]: {
      '&>div:not(:first-child)': {
        marginTop: 0
      },
      '&>div:not(:last-child)': {
        marginTop: 0,
        paddingRight: 16
      }
    }
  },
  textInputGroup: {
    marginTop: 24,
    '&>div:not(:last-child)': {
      marginTop: 0,
      paddingRight: 16
    }
  },
  questionSectionTitle: {
    paddingTop: '8px'
  },
  questionSectionFirstQuestion: {
    paddingTop: '12px'
  },
  conditionalQuestionContainer: {
    paddingTop: '16px'
  },
  addAnotherSuspectButton: {
    borderTop: `1px solid ${colorPalette.purple}`,
    paddingTop: '24px'
  }
} ));
