import { createUseStyles } from "react-jss"

export const useThankYouPageStyles = createUseStyles({
  backgroundTop: {
    height: '100vh',
    overflow: 'auto'
  },
  backgroundBottom: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  },
  wrapper: {
    marginBottom: 32,
  },
  impactStatementHeader: {
    marginTop: '125px'
  },
  divider: {
    marginTop: '28px'
  },
  defaultHeader1: {
    marginTop: '64px'
  },
  defaultBodyText1: {
    marginTop: '8px'
  },
  defaultHeader2: {
    marginTop: '32px'
  },
  defaultBodyText2: {
    marginTop: '8px'
  },
  buttonsContainer: {
    marginTop: '34px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  helplineLink: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  secondaryButton: {
    marginTop: '12px'
  }
})
