import { SequenceOfEventsSectionContent } from "./sequence-of-events-section.content";
import { returnCallOutWithPassword, returnCallOutWithoutPassword } from "../../shared/models/return-call-out.model";

export const sequenceOfEventsSectionContentEn: SequenceOfEventsSectionContent = {
  sectionHeader: 'Description of What Happened',
  description: "We understand that this may be a difficult thing to describe, but anything you remember and are willing to share helps identify the suspect/offender. The profile may be used to predict the their future actions.",
  returnCallOutWithPassword,
  returnCallOutWithoutPassword,
  eventsDescription: {
    label: "As much as you're able to share, please describe what happened step by step."
  },
  assistanceBlock: {
    header: "If you are not sure where to start or need help putting what happened into words, here are some questions that may help:",
    helpfulQuestions: [
      'How did the suspect/offender first approach you?',
      'What did they tell you to do or say?',
      'Do you recall any words or phrases they used?',
      'What happened?',
      'Did they make contact with you afterwards? What was said?'
    ]
  }
}
