// dependencies
import React from 'react';
import { createUseStyles } from 'react-jss';
import { createGrid } from '@nascentdigital/react-lattice';
import { useHistory } from 'react-router-dom';
import { BpTheme, breakpoints } from '../../shared/theme';
import {
  ImpactStatementHeader,
  DefaultHeader,
  Divider,
  DefaultBodyText,
  BpLink,
  BrandedContainer,
  SecondaryActionButton
} from '../../shared/components';

// styles
const useStyles = createUseStyles((theme: BpTheme) => ( {
  description: {
    marginTop: 16,
    marginBottom: 16
  },
  resource: {
    marginTop: 32
  },
  resourceHeading: {
    marginTop: 4
  },
  contactInfo: {
    marginTop: 8
  },
  backButton: {
    margin: [48, 0],
    [theme.mediaQueries.tabletAndAbove]: {
        marginTop: 0,
        marginBottom: 32
    }
  },
  container: {
    marginBottom: 32
  }
} ));

const Grid = createGrid({ breakpoints });

// component definition
const ResourcesPage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const onBack = () => {
    // @ts-ignore
    if (history.length === 1) {
      history.push('/home');
    } else {
      // @ts-ignore
      history.goBack();
    }
  };

  return (
    <BrandedContainer className={classes.container}>
      <Grid container direction={{ xs: 'columnReverse', md: 'column' }}>
        <Grid item>
          <div className={classes.backButton}>
            <SecondaryActionButton onClick={onBack}>{content.back}</SecondaryActionButton>
          </div>
        </Grid>
        <Grid item>
          <ImpactStatementHeader>{content.heading}</ImpactStatementHeader>
          <div className={classes.description}>
            <DefaultBodyText>{content.description}</DefaultBodyText>
          </div>
          <Divider thickness="thick" width="100px"/>
          {
            content.resources.map((resource, i) => {
              return (
                <div className={classes.resource} key={`resource-${i}`}>
                  <div className={classes.resourceHeading}>
                    <DefaultHeader>{resource.heading}</DefaultHeader>
                  </div>
                  <DefaultBodyText>{resource.description}</DefaultBodyText>
                  <div className={classes.contactInfo}>
                    {
                      resource.phone?.map((phone, i) => (
                        <DefaultBodyText key={`phone-${i}`}>
                          {phone.label}: <BpLink href={`tel:${phone.number}`}>{phone.number}</BpLink>
                        </DefaultBodyText>
                      ))
                    }
                  </div>
                  <div className={classes.contactInfo}>
                    <BpLink href={resource.web.url} target="_blank">{resource.web.text.toUpperCase()}</BpLink>
                  </div>
                </div>
              );
            })
          }
        </Grid>
      </Grid>
    </BrandedContainer>
  );
};

// content
const content = {
  heading: 'We\'re here to help',
  description: 'Speak Out is currently piloting in select cities in Ontario. We know that coming forward is not easy, it takes a lot of courage and strength. If you’re looking to report an assault that happened outside those cities, please consider the following resources.',
  back: 'Go back',
  resources: [
    {
      heading: 'Assaulted Women\'s Helpline',
      description: '24/7 Live crisis counselling support in over 200 languages',
      phone: [
        {
          label: 'Toll-Free',
          number: '1-866-863-0511'
        }, {
          label: 'TTY',
          number: '1-866-863-7868'
        }
      ],
      web: {
        text: 'https://www.awhl.org/home →',
        url: 'https://www.awhl.org/home'
      }
    },
    {
      heading: 'Women and Children\'s Shelter of Barrie',
      description: 'The Women & Children’s Shelter of Barrie has been providing shelter and support services to abused women and children since 1981.',
      phone: [
        {
          label: 'Crisis Line',
          number: '705-728-6300'
        }, {
          label: 'Toll-Free',
          number: '1-800-461-1716'
        }
      ],
      web: {
        text: 'https://barrieshelter.com →',
        url: 'https://barrieshelter.com'
      }
    },
    {
      heading: 'London Police Sexual Assault FAQs and Resources',
      description: 'The London Police website has several resources for those looking for support in the London area.',
      web: {
        text: 'London Police Website →',
        url: 'https://www.londonpolice.ca/en/services/reporting-sexual-assaults.aspx'
      }
    },
  ]
};


export default ResourcesPage;
