import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SecondaryActionButton from './secondary-action-button';
import alertService from '../../alerts/services/alert-service';
import { useBreakpoint } from '@nascentdigital/react-lattice';
import { BpTheme, breakpoints } from '../theme';
import { createUseStyles } from 'react-jss';

interface Props {
  leavingWhileDataIsUnsaved?: boolean
}

const useStyles = createUseStyles((theme: BpTheme) => ( {
  button: {
    [theme.mediaQueries.mobileOnly]: {
      maxWidth: 183
    }
  }
} ));

const BackToDashboardButton: React.FunctionComponent<RouteComponentProps & Props> = ({ history, leavingWhileDataIsUnsaved }) => {
  const breakpoint = useBreakpoint(breakpoints);
  const classes = useStyles();

  const handleClick = () => {
    if (leavingWhileDataIsUnsaved) {
      alertService.alertLeavingSection();
    } else {
      history.push('/report');
    }
  };
  return (
    <SecondaryActionButton onClick={handleClick} className={classes.button}>
      {
        breakpoint === 'xs' || breakpoint === 'sm' ?
          'dashboard' : 'back to dashboard'
      }
    </SecondaryActionButton>
  );
};

BackToDashboardButton.defaultProps = {
  leavingWhileDataIsUnsaved: false
};

export default withRouter(BackToDashboardButton);
