import { DropdownOption } from "../components/dropdown";

export const raceAppearanceOptions: DropdownOption[] = [
  { value: 'White', displayText: 'White' },
  { value: 'Asian', displayText: 'Asian' },
  { value: 'East Indian', displayText: 'East Indian' },
  { value: 'Black', displayText: 'Black' },
  { value: 'Hispanic', displayText: 'Hispanic' },
  { value: 'Middle Eastern', displayText: 'Middle Eastern' },
  { value: 'Indigenous', displayText: 'Indigenous' },
  { value: 'Other', displayText: 'Other' },
]
