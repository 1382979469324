import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ServiceLocator from "../shared/service-locator/service-locator"
import sequenceOfEventsFormService from './sequence-of-events-section.form-service'
import { REPORT_ROUTES } from '../constants'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

const reportService = ServiceLocator.getInstance().getReportService()
const analyticsService = ServiceLocator.getInstance().getAnalyticsService()

export function useSequenceOfEventsSectionController() {
  const history = useHistory()
  const sequenceOfEventsSectionData = reportService.sequenceOfEventsSectionSavedData

  const initialDescriptionValue = sequenceOfEventsSectionData?.description?.inputFieldValue as string || ''
  const descriptionHasBeenFilled = !!sequenceOfEventsSectionData?.description
  const showReturnCallOut = sequenceOfEventsSectionData?.showReturnCallOut
  const isWithPassword = reportService.hasReportPassword
  const [ isSaveAndCloseLoading, setIsSaveAndCloseLoading ] = useState<boolean>(false)
  const [ isReadyToSave, setIsReadyToSave ] = useState<boolean>(false)


  const handleSaveAndClose = async () => {
    setIsSaveAndCloseLoading(true)
    analyticsService.logEvent(
      'save_report',
      {
        section: 'sequence_of_events'
      }
    )
    try {
      await sequenceOfEventsFormService.submit()
      setIsSaveAndCloseLoading(false)
      history.push(REPORT_ROUTES.DASHBOARD)
    } catch (e) {
      setIsSaveAndCloseLoading(false)
    }
  }

  const handleDescriptionChange = (value: string) => {
    sequenceOfEventsFormService.setDescription(value)
  }

  useEffect(() => {

    analyticsService.viewPage('/report/events-section')

    const unsubscribe: Subject<void> = new Subject()

    sequenceOfEventsFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe(isReadyToSave => {
      setIsReadyToSave(isReadyToSave)
    })

    return () => {
      sequenceOfEventsFormService.clearForm()
    }
  }, [])

  return {
    initialDescriptionValue,
    descriptionHasBeenFilled,
    isSaveAndCloseLoading,
    showReturnCallOut,
    isWithPassword,
    isReadyToSave,
    handleSaveAndClose,
    handleDescriptionChange
  }
}
