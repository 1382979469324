import React from 'react'
import { createGrid } from '@nascentdigital/react-lattice'
import ResumeReportOptionButton from '../resume-report-option-button/resume-report-option-button'
import {breakpoints} from '../../../../../shared/theme';

const Grid = createGrid({ breakpoints })

const ResumeReportOptions: React.FunctionComponent<{
  buttonSelected: 'reportCodeOnly'|'reportCodeAndPassword';
  setButtonSelected: (buttonSelected: 'reportCodeOnly'|'reportCodeAndPassword') => void;
}> = ({ buttonSelected, setButtonSelected }) => {

  return (
    <Grid container spacing={{xs: 2, md: 0}} direction={{xs: 'column', md: 'row'}} justify="spaceAround">
      <Grid item flex={{ xs: 12, md: 5 }} spacing={{xs: 2, md: 0}}>
        <ResumeReportOptionButton
          selected={buttonSelected === 'reportCodeAndPassword'}
          label="report code & password"
          handleClick={() => setButtonSelected('reportCodeAndPassword')}
          position="left"
          id='reportCodeAndPassword'
        />
      </Grid>
      <Grid item flex={{ xs: 12, md: 5 }} spacing={{xs: 2, md: 0}}>
        <ResumeReportOptionButton
          selected={buttonSelected === 'reportCodeOnly'}
          label="report code only"
          handleClick={() => setButtonSelected('reportCodeOnly')}
          position="right"
          id='reportCodeOnly'
        />
      </Grid>
    </Grid>
  )
}

export default ResumeReportOptions
