import { CreateVehicleSectionDto } from "../data-transfer-objects/create-vehicle-section.dto";
import { CreateVehicleSectionOptions } from "../interfaces/section-service.interface";

export function createVehicleSectionRequestBody(
  minimumRequestBody: CreateVehicleSectionDto,
  data: CreateVehicleSectionOptions
): CreateVehicleSectionDto {
  let requestBody = { ...minimumRequestBody }
  if (data.wasVehicleInvolved) {
    requestBody.wasVehicleInvolved = {
      inputFieldName: 'wasVehicleInvolved',
      inputFieldValue: data.wasVehicleInvolved
    }
  }
  if (data.vehicleMake !== undefined) {
    requestBody.vehicleMake = {
      inputFieldName: 'vehicleMake',
      inputFieldValue: data.vehicleMake
    }
  }
  if (data.vehicleModel !== undefined) {
    requestBody.vehicleModel = {
      inputFieldName: 'vehicleModel',
      inputFieldValue: data.vehicleModel
    }
  }
  if (data.vehicleColour !== undefined) {
    requestBody.vehicleColour = {
      inputFieldName: 'vehicleColour',
      inputFieldValue: data.vehicleColour
    }
  }
  if (data.vehicleStyle !== undefined) {
    requestBody.vehicleStyle = {
      inputFieldName: 'vehicleStyle',
      inputFieldValue: data.vehicleStyle
    }
  }
  if (data.licensePlate !== undefined) {
    requestBody.licensePlate = {
      inputFieldName: 'licensePlate',
      inputFieldValue: data.licensePlate
    }
  }
  if (data.issuingProvinceTerritory !== undefined) {
    requestBody.issuingProvinceTerritory = {
      inputFieldName: 'issuingProvinceTerritory',
      inputFieldValue: data.issuingProvinceTerritory
    }
  }
  if (data.distinctFeaturesAboutVehicle !== undefined) {
    requestBody.distinctFeaturesAboutVehicle = {
      inputFieldName: 'distinctFeaturesAboutVehicle',
      inputFieldValue: data.distinctFeaturesAboutVehicle
    }
  }
  return requestBody
}