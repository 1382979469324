import { sectionLeaveAlertContentEn } from "./section-leave-alert.content-en"

export interface SectionLeaveAlertContent {
  header: string;

  descriptionPart1: string;
  descriptionPart2: string;

  warning: string;

  leaveSectionButtonLabel: string;
  cancelButtonLabel: string;
}

export const sectionLeaveAlertContentProvider = (): SectionLeaveAlertContent => {
  return sectionLeaveAlertContentEn
}
