import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultBodyText } from './atomic';
import { BpTheme } from '../theme';

interface StyleProps {
  inputFieldWidth: string,
  centerPlaceholder: boolean;
  label: string;
}

const useLayout = createUseStyles({
  input: {
    marginTop: (props: StyleProps) => props.label ? '8px' : '0'
  }
});

interface StyleProps {
  hasBeenFilled: boolean;
}

const useInputFieldStyles = createUseStyles((theme: BpTheme) => ({
  inputField: {
    ...theme.fontStylesMobile.textInput,
    background: (props: StyleProps) => props.hasBeenFilled ? theme.colorPalette.lavender : theme.colorPalette.white,
    color: (props: StyleProps) => props.hasBeenFilled ? theme.colorPalette.grape : theme.colorPalette.navy,
    boxSizing: 'border-box',
    border: `1px solid ${theme.colorPalette.grape}`,
    borderRadius: '8px',
    height: '40px',
    textIndent: '10px',
    '&:focus': {
      // outline: 'none'
    },
    width: '100%',
    [theme.mediaQueries.tabletAndAbove]: {
      width: (props: StyleProps) => props.inputFieldWidth,
    },
    '&::placeholder': {
      color: theme.colorPalette.mediumGrey,
      fontStyle: 'italic',
      textIndent: (props: StyleProps) => props.centerPlaceholder ? '0' : '10px',
      textAlign: (props: StyleProps) => props.centerPlaceholder ? 'center' : 'left'
    }
  }
}));

interface TextInputFieldProps {
  label?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  inputFieldWidth?: string | number;
  centerPlaceholder?: boolean;
  hasBeenFilled?: boolean;
  validator?: (value: string) => boolean;
  isPassword?: boolean;
  initialValue?: string;
  id: string;
}

const TextInputField: React.FunctionComponent<TextInputFieldProps> = ({
                                                                        label,
                                                                        onChange,
                                                                        inputFieldWidth,
                                                                        placeholder,
                                                                        centerPlaceholder,
                                                                        hasBeenFilled,
                                                                        validator,
                                                                        isPassword,
                                                                        initialValue,
                                                                        id
                                                                      }) => {
  const layout = useLayout({label});
  const inputFieldStyles = useInputFieldStyles({inputFieldWidth, centerPlaceholder, hasBeenFilled});
  const [value, setValue] = useState(initialValue || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    // check validation if there is validation requirement
    if (validator && !validator(value)) {
      return;
    }
    setValue(value);
    onChange(value);
  };

  return (
    <div>
      <label htmlFor={id}>
        <DefaultBodyText color={hasBeenFilled ? 'grape' : 'navy'}>{label}</DefaultBodyText>
      </label>
      <div className={layout.input}>
        <input
          type={isPassword ? "password" : undefined}
          placeholder={placeholder}
          className={inputFieldStyles.inputField}
          value={value}
          onChange={handleChange}
          id={id}
          autoCapitalize="off"
        />
      </div>
    </div>
  );
};

TextInputField.defaultProps = {
  label: '',
  inputFieldWidth: '100%',
  centerPlaceholder: false,
  validator: (value: string) => true,
  isPassword: false,
  initialValue: ''
};

export default TextInputField;
