export class ReportPassword {
  constructor(public readonly value: string) { }

  updateValue(value: string): ReportPassword {
    return new ReportPassword(value)
  }

  isValid(): boolean {
    if (!this.longEnough() ||
      !this.atLeastOneUppercase() ||
      !this.atLeastOneLowercase() ||
      !this.atLeastOneNumber()) {
      return false
    }

    return true
  }

  equals(object: ReportPassword) {
    return this.value === object.value
  }

  private longEnough(): boolean {
    return this.value.length >= 6
  }

  private atLeastOneUppercase(): boolean {
    const regex = /[A-Z]/
    return !!this.value.match(regex)
  }

  private atLeastOneLowercase(): boolean {
    const regex = /[a-z]/
    return !!this.value.match(regex)
  }

  private atLeastOneNumber(): boolean {
    const regex = /[0-9]/
    return !!this.value.match(regex)
  }
}
