import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultBodyText } from './atomic';

// assets
import downArrow from '../../assets/downArrow.png';

// theme
import { BpTheme, fontFamilies } from '../theme';
import TextInputField from './text-input-field';

export type DropdownOption = {
  displayText: string;
  value: string
}

const useLayout = createUseStyles({
  input: {
    marginTop: '8px',
  }
});

interface StyleProps {
  dropdownWidth: string,
  isSelected: boolean,
  hasBeenFilled: boolean
}

const useDropdownStyles = createUseStyles((theme: BpTheme) => ( {
  dropdown: {
    boxSizing: 'border-box',
    border: `1px solid ${theme.colorPalette.grape}`,
    borderRadius: '0.5rem',
    color: (props: StyleProps) => ( props.hasBeenFilled ? theme.colorPalette.grape : theme.colorPalette.navy ),
    fontFamily: [theme.fontFamilies.Avenir, fontFamilies.SansSerif],
    fontSize: '1rem',
    height: '40px',
    paddingLeft: '15px',
    'background-color': (props: StyleProps) => props.hasBeenFilled ? theme.colorPalette.lavender : theme.colorPalette.white,
    'background-position-x': '97%',
    'background-image': `url(${downArrow})`,
    'background-repeat': 'no-repeat',
    'background-position': 'right',
    'background-size': '30px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    width: '100%',
    [theme.mediaQueries.tabletAndAbove]: {
      width: (props: StyleProps) => props.dropdownWidth
    }
  }
} ));

interface DropdownProps {
  label?: string;
  options: DropdownOption[];
  onChange: (value: string) => void;
  dropdownWidth?: string;
  hasBeenFilled?: boolean;
  initialValue?: string;
  isComboBox?: boolean;
  id: string;
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
                                                            label,
                                                            options,
                                                            onChange,
                                                            dropdownWidth,
                                                            hasBeenFilled,
                                                            initialValue,
                                                            isComboBox,
                                                            id,
                                                          }) => {
  const optionValues = options.map((option) => option.value);

  let initValue = "";
  let initOtherValue = "";

  if (initialValue && initialValue !== "") {
    if (optionValues.includes(initialValue)) {
      initValue = initialValue;
    } else {
      initValue = "Other";
      initOtherValue = initialValue;
    }
  }

  const [selected, setSelected] = useState<string>(initValue);
  const layout = useLayout();
  const { dropdown } = useDropdownStyles({
    dropdownWidth,
    isSelected: selected !== '',
    hasBeenFilled
  });


  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setSelected(value);
    onChange(value);
  };

  const handleOtherChange = (value: string) => {
    if (value === '') {
      onChange('Other');
    } else {
      onChange(value);
    }
  };

  return (
    <div>
      <label htmlFor={id}>
        <DefaultBodyText color={hasBeenFilled ? 'grape' : 'navy'}>{label}</DefaultBodyText>
      </label>
      <div className={layout.input}>
        <select className={dropdown} value={selected} onChange={handleChange} id={id}>
          <option value="" disabled hidden style={{ fontWeight: 'bold' }}>Select</option>
          {
            options.map((option) => (
              <option key={option.value} value={option.value}>{option.displayText}</option>
            ))
          }
        </select>
      </div>
      {isComboBox && selected === 'Other' && (
        <div className={layout.input}>
          <TextInputField
            initialValue={initOtherValue}
            onChange={handleOtherChange}
            hasBeenFilled={hasBeenFilled}
            inputFieldWidth={dropdownWidth}
            placeholder="Brief detail"
            validator={(value: string) => value.length < 100}
            id={id}
          />
        </div>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  dropdownWidth: '100%',
  hasBeenFilled: false,
  initialValue: '',
  isComboBox: false
};

export default Dropdown;
