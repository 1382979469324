import React from 'react';

import { useFormStyles } from '../shared/theme/form-styles';
import { victimSectionContentProvider } from './contents/victim-section.content';
// components
import BackToDashboardButton from '../shared/components/back-to-dashboard-button';
import { DefaultBodyText, Divider } from '../shared/components'
import TextInputField from '../shared/components/text-input-field';
import Dropdown from '../shared/components/dropdown';
import InfoBlock from '../shared/components/info-block';
import FormCtaButtons from '../shared/components/form-cta-buttons';
import { useVictimSectionController } from './victim-section.controller';
import ReportSectionHeader from "../shared/components/report-section-header/report-section-header"

const content = victimSectionContentProvider();

const VictimSection: React.FunctionComponent = () => {
  const formStyles = useFormStyles();

  const {
    initialAgeValue,
    initialGenderValue,
    initialVictimRaceAppearanceValue,
    isSaveAndNextSectionLoading,
    isSaveAndCloseLoading,
    isReadyToSave,
    showReturnCallOut,
    isWithPassword,
    ageHasBeenFilled,
    genderHasBeenFilled,
    victimRaceAppearanceHasBeenFilled,
    handleAgeChange,
    handleGenderChange,
    handleVictimRaceAppearanceChange,
    handleSaveAndNext,
    handleSaveAndClose
  } = useVictimSectionController();

  return (
    <div className={formStyles.page}>
      <div className={formStyles.backButton}>
        <BackToDashboardButton leavingWhileDataIsUnsaved={isReadyToSave}/>
      </div>

      <ReportSectionHeader headerName={content.sectionHeader}></ReportSectionHeader>

      <div className={formStyles.infoBlock}>
        <InfoBlock>
          <DefaultBodyText>{content.description}</DefaultBodyText>
          {showReturnCallOut && (
            <>
              <br/>
              <DefaultBodyText>
                <b
                  style={{ display: 'contents' }}>{isWithPassword ? content.returnCallOutWithPassword[0].text : content.returnCallOutWithoutPassword[0].text}</b>
                <p
                  style={{ display: 'contents' }}>{isWithPassword ? content.returnCallOutWithPassword[1].text : content.returnCallOutWithoutPassword[1].text}</p>
              </DefaultBodyText>
            </>
          )}
        </InfoBlock>
      </div>
      <div className={formStyles.divider}>
        <Divider width="100px" thickness="medium"/>
      </div>
      <div className={formStyles.question}>
        <TextInputField
          inputFieldWidth={250}
          initialValue={initialAgeValue}
          hasBeenFilled={ageHasBeenFilled}
          label={content.age.label}
          onChange={handleAgeChange}
          id='age'
        />
      </div>
      <div className={formStyles.question}>
        <Dropdown
          initialValue={initialGenderValue}
          hasBeenFilled={genderHasBeenFilled}
          label={content.gender.label}
          onChange={handleGenderChange}
          options={content.gender.options}
          dropdownWidth="250px"
          isComboBox={true}
          id='gender'
        />
      </div>
      <div className={formStyles.question}>
        <Dropdown
          initialValue={initialVictimRaceAppearanceValue}
          hasBeenFilled={victimRaceAppearanceHasBeenFilled}
          label={content.victimRaceAppearance.label}
          onChange={handleVictimRaceAppearanceChange}
          options={content.victimRaceAppearance.options}
          dropdownWidth="250px"
          isComboBox={true}
          id='victimRaceAppearance'
        />
      </div>
      <div className={formStyles.buttonsContainer}>
        <FormCtaButtons
          isReadyToSave={isReadyToSave}
          onSaveAndNextSection={handleSaveAndNext}
          onSaveAndClose={handleSaveAndClose}
          isSaveAndNextSectionLoading={isSaveAndNextSectionLoading}
          isSaveAndCloseLoading={isSaveAndCloseLoading}
        />
      </div>
    </div>
  );
};

export default VictimSection;
