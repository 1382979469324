import { createUseStyles } from "react-jss";
import { BpTheme } from '../../../shared/theme';

export const createReportPasswordAlertStylesFactory = createUseStyles((theme: BpTheme) => ( {
  passwordRequirementsBlock: {
    marginTop: '32px'
  },
  description: {
    marginTop: 16
  },
  passwordRequirementsItems: {
    padding: 16,
    [theme.mediaQueries.tabletAndAbove]: {
      width: '50%',
      margin: 'auto'
    }
  },
  passwordRequirementsItem: {
    display: 'list-item'
  },
  password: {
    paddingTop: '30px',
    width: '100%'
  },
  confirmPassword: {
    paddingTop: '20px',
    width: '100%'
  },
  buttons: {
    paddingTop: '36px',
    paddingBottom: '50px',
    '& button': {
      marginBottom: 16
    }
  }
} ));
