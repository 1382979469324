import React from 'react'
import { createUseStyles } from 'react-jss'
import { colorPalette, Color } from '../theme'

interface StyleProps {
  backgroundColor: Color;
}

const useStyles = createUseStyles({
  infoBlock: {
    background: (props: StyleProps) => colorPalette[props.backgroundColor],
    borderRadius: '15px',
    padding: '32px',
  }
})

interface InfoBlockProps {
  children: React.ReactNode;
  backgroundColor?: Color;
}

const InfoBlock: React.FunctionComponent<InfoBlockProps> = ({ children, backgroundColor }) => {
  const styles = useStyles({ backgroundColor })
  return (
    <div className={styles.infoBlock}>{children}</div>
  )
}

InfoBlock.defaultProps = {
  backgroundColor: 'lavender'
}

export default InfoBlock
