import React, { RefObject, useRef, useState, useEffect } from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import { useInView } from 'react-intersection-observer';
import BaseAlert from '../base-alert';
import { ImpactStatementHeader, DefaultBodyText } from '../../../shared/components';
import SecondaryButton from '../../../shared/components/secondary-button';
import { firstTimeSaveAlertContentProvider, FirstTimeSaveAlertContent } from './content/first-time-save-alert.content';
import PrimaryActionButton from '../../../shared/components/primary-action-button';
import { firstTimeSaveAlertStylesFactory } from './first-time-save-alert.styles';
import { useFirstTimeSaveAlertController } from './first-time-save-alert.controller';
import SupportBlock from '../../../shared/components/support-block';
import ImageLabelList from '../image-label-list/image-label-list';
import { breakpoints } from '../../../shared/theme';

const content: FirstTimeSaveAlertContent = firstTimeSaveAlertContentProvider();
const stylesFactory = firstTimeSaveAlertStylesFactory;
const Grid = createGrid({ breakpoints });


const FirstTimeSaveAlert: React.FunctionComponent = () => {
  // hooks
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [displayScrollBanner, setDisplayScrollBanner] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);

  const { ref, inView, entry } = useInView();

  const styles = stylesFactory({ displayScrollBanner });

  useEffect(() => {
    const scrollElement = scrollContainerRef.current;

    if (scrollElement) {
      scrollElement.onscroll = () => {
        setDisplayScrollBanner(false);
        setIsScrolling(true);
      };
    }

    return () => {
      if (scrollElement) {
        scrollElement.onscroll = null;
      }
    };
  }, [scrollContainerRef.current]);

  useEffect(() => {
    let timeOutId: number | null;
    if (isScrolling) {
      timeOutId = window.setInterval(() => {
        setIsScrolling(false);
      }, 300);
    } else {
      setDisplayScrollBanner(!inView);
    }

    return () => {
      if (timeOutId) {
        window.clearInterval(timeOutId);
      }
    };
  }, [isScrolling, inView]);
  const {
    handleCreatePassword,
    handleDoNotCreatePassword,
    onNotNowButtonClicked,
    isSaveWithoutPasswordExecuting
  } = useFirstTimeSaveAlertController();

  return (
    <BaseAlert aria-labelledby='firstTimeSaveHeader' ref={scrollContainerRef}>
      <Grid container justify="flexEnd">
        <button onClick={onNotNowButtonClicked} className={styles.notNowButton}>
          {content.notNowButtonLabel}
          <img src={content.closeImgSrc} alt="close icon" className={styles.closeImg} aria-hidden="true"/>
        </button>
      </Grid>
      <ImpactStatementHeader size="medium">{content.header}</ImpactStatementHeader>
      <div className={styles.description}>
        <DefaultBodyText fontSize="1rem">
          {content.description}
        </DefaultBodyText>
      </div>
      <div className={styles.supportBlock}>
        <SupportBlock width="100%">
          <Grid container>
            <Grid item flex={{ xs: 12, md: 6 }}>
              <ImageLabelList model={content.noPasswordModel}/>
            </Grid>
            <Grid item flex={{ xs: 12, md: 6 }}>
              <ImageLabelList model={content.withPasswordModel}/>
            </Grid>
          </Grid>
          <div className={styles.footNote}>
            <DefaultBodyText fontSize="0.75rem">
              {content.footNote}
            </DefaultBodyText>
          </div>
        </SupportBlock>
      </div>
      <div className={styles.ctaButtons} ref={ref}>
        <PrimaryActionButton onClick={handleCreatePassword} width="240px">
          {content.createPasswordButtonLabel}
        </PrimaryActionButton>
        <SecondaryButton onClick={handleDoNotCreatePassword} width="240px" isLoading={isSaveWithoutPasswordExecuting}>
          {content.doNotCreatePasswordButtonLabel}
        </SecondaryButton>
      </div>

      {renderScrollBanner(styles, entry?.target)}

    </BaseAlert>
  );
};

const renderScrollBanner = (classes: Record<string, string>, scrollToEl?: Element) => {
  const onClick = () => {
    if (scrollToEl) {
      scrollToEl.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.scrollBanner}>
      <button className={classes.buttonLink} onClick={onClick}>Show more</button>
    </div>
  );
};

export default FirstTimeSaveAlert;
