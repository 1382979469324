import React from 'react'
import { createUseStyles } from 'react-jss'

// theme
import { colorPalette } from '../theme'

interface StyleProps {
  hasBeenFilled: boolean;
}

const useStyles = createUseStyles({
  checkbox: {
    outline: 'none',
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    border: (props: StyleProps) => props.hasBeenFilled ? `1px solid ${colorPalette.grape}` : `1px solid ${colorPalette.grape}`,
    borderRadius: '5px',
    minWidth: '30px',
    minHeight: '30px',
    background: (props: StyleProps) => props.hasBeenFilled ? colorPalette.lavender : colorPalette.white,
    '&:hover': {
      'background': colorPalette.lavender,
    },
    '&:checked': {
      'background': colorPalette.purple
    },
    '&:focus': {
      // /* Adds a white border around the button, along with a blue glow. The white and blue have a color contrast ratio of at least 3:1, which means this will work against any background color. */
      // boxShadow: '0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9',
      // /* NOTE: box-shadow is invisible in Windows high-contrast mode, so we need to add a transparent outline, which actually does show up in high-contrast mode. */
      // outline: '2px dotted transparent',
      // outlineOffset: '2px',
      outline: '-webkit-focus-ring-color auto 1px',
    },
  }
})

interface CheckboxProps {
  isChecked: boolean;
  onClickCallback: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => any;
  hasBeenFilled?: boolean;
  id: string;
  ariaDescribedby: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ isChecked, onClickCallback, hasBeenFilled, id, ariaDescribedby }) => {
  const { checkbox } = useStyles({ hasBeenFilled })

  return (
    <input type="checkbox" checked={isChecked} className={checkbox} onChange={() => {
    }} onClick={onClickCallback} id={id} aria-describedby={ariaDescribedby}/>
  )
}

Checkbox.defaultProps = {
  hasBeenFilled: false
}

export default Checkbox

