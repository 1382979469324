export abstract class SectionFormBaseService {
  protected compare(current: any, previous: any, hasReportPassword: boolean): any {
    if (current || hasReportPassword) {
      if (current || previous) {
        return current
      }
      else {
        return undefined
      }
    }
    else {
      return undefined
    }
  } 
}
