import { createUseStyles } from "react-jss";
import { BpTheme, colorPalette } from "../../../shared/theme";

export const useLayout = createUseStyles((theme: BpTheme) => ( {
  description: {
    paddingTop: '16px'
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '120px',
    alignItems: 'flex-end',
    paddingTop: '32px'
  },
  supportBlock: {
    paddingTop: '32px'
  },
  sectionsList: {
    paddingTop: '25px',
    [theme.mediaQueries.tabletAndAbove]: {
      paddingLeft: '44px'
    }
  }
} ));

export const useStyle = createUseStyles({
  supportBlock: {
    boxSizing: 'border-box',
    border: `1px solid ${colorPalette.grape}`,
    borderRadius: '1rem',
    padding: '30px'
  }
});
