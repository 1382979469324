import React from 'react';
import { createUseStyles } from 'react-jss';
import { createGrid } from '@nascentdigital/react-lattice';
import { DefaultHeader, DefaultBodyText } from '../../shared/components';
import { BpTheme, breakpoints } from '../../shared/theme';
// models
import { Subhead } from '../models/dashboard.model';
import { Content } from '../../shared/models/content.model';

const Grid = createGrid({ breakpoints });

const useLayout = createUseStyles((theme: BpTheme) => ( {
  container: {
    padding: [16, 20],
    height: '100%',
    borderRadius: '8px',
    boxSizing: 'border-box',
    [theme.mediaQueries.tabletAndAbove]: {
      margin: [0, 12, 12, 0],
      background: 'rgba(0,0,0,0.02)'
    }
  },
  subheading: {
    [theme.mediaQueries.tabletAndAbove]: {
      marginBottom: 16
    }
  },
  iconWrapper: {
    minWidth: 50
  },
  icon: {
    height: 32,
    marginRight: 16
  },
  content: {
    [theme.mediaQueries.tabletAndAbove]: {
      marginTop: '10px'
    }
  },
  body: {
    marginTop: '16px'
  }
} ));

interface SubheadingProps {
  subhead: Subhead
}

const DashboardSubheading: React.FunctionComponent<SubheadingProps> = ({ subhead }) => {
  const layout = useLayout();
  return (
    <Grid item flex={{ xs: 12, md: 4 }}>
      <div className={layout.container}>
        <Grid container direction={{ xs: 'row', md: 'column' }} alignItems={{ xs: 'flexStart' }} wrap="none">
          <Grid item className={layout.iconWrapper}>
            <img src={subhead.icon} alt={subhead.heading} className={layout.icon}/>
          </Grid>
          <Grid item className={layout.content}>
            <DefaultHeader className={layout.subheading}>{subhead.heading}</DefaultHeader>
            {
              subhead.description.map((content: Content, i) => {
                return (
                  <DefaultBodyText key={`content-${i}`} isBolded={content.isBolded}
                                   display="inline">{content.text}</DefaultBodyText>
                );
              })
            }
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default DashboardSubheading;
