import React from 'react';
import { DefaultBodyText } from '../../../shared/components';
import { UISectionType } from '../../../shared/types/section-type';

const SectionTitleListItem: React.FunctionComponent<{ sectionType: UISectionType }> = ({sectionType}) => {
  switch (sectionType) {
    case 'dateAndLocation':
      return (
        <li style={{paddingTop: '5px '}}>
          <DefaultBodyText fontSize="1rem">Description of Date and Location</DefaultBodyText>
        </li>
      );
    case 'sequenceOfEvents':
      return (
        <li style={{paddingTop: '5px '}}>
          <DefaultBodyText fontSize="1rem">Description of What Happened</DefaultBodyText>
        </li>
      );
    case 'suspect':
      return (
        <li style={{paddingTop: '5px '}}>
          <DefaultBodyText fontSize="1rem">Description of the Suspect/Offender</DefaultBodyText>
        </li>
      );
    case 'vehicle':
      return (
        <li style={{paddingTop: '5px '}}>
          <DefaultBodyText fontSize="1rem">Description of the Vehicle</DefaultBodyText>
        </li>
      );
    case 'victim':
      return (
        <li style={{paddingTop: '5px '}}>
          <DefaultBodyText fontSize="1rem">Description of Victim/Survivor</DefaultBodyText>
        </li>
      );
  }
};

export default SectionTitleListItem;
