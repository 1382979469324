import { Observable, BehaviorSubject } from 'rxjs';
import qs from 'query-string';
import { ReportService, SideNavData } from './interfaces/report-service.interface';
import { ReportCreatedDto } from '../../data-transfer-objects/report-created-dto';
import { ResumeReportDto } from '../../data-transfer-objects/resume-report-dto';
import { DateAndLocationSectionData } from '../../../date-and-location-section/models/date-and-location-section-data.model';
import { DashboardData } from '../../../dashboard/models/dashboard-data.model';
import { HttpClient, RequestOptions } from '../../clients/http-client.interface';
import { InvalidReportCode } from '../../errors/invalid-report-code';
import { ServerError } from '../../errors/server-error';
import { UISectionType } from '../../types/section-type';
import { formatReportCode } from '../../utils/format-report-code';
import { VictimSectionSavedData } from './interfaces/victim-section-saved-data.interface';
import { SequenceOfEventsSectionSavedData } from './interfaces/sequence-of-events-section-saved-data.interface';
import { InvalidReportPassword } from '../../errors/invalid-report-password';
import { VehicleSectionSavedData } from './interfaces/vehicle-section-saved-data.interface';
import { SuspectSectionSavedData } from './interfaces/suspect-section-saved-data.interface'
import { OtherRegion, Region } from "../region-service/interfaces/region-service.interface"

const initialReportCode: string = '';
const initialReportData: ResumeReportDto = {
  shortCode: '',
  reporterType: 'counsellor',
  sections: [],
  hasRespondedToSaveWithPassword: false
};
const initialSideNavData: BehaviorSubject<SideNavData> = new BehaviorSubject<SideNavData>({
  victim: { hasBeenFilled: false },
  date: { hasBeenFilled: false },
  location: { hasBeenFilled: false },
  vehicle: { hasBeenFilled: false },
  suspect: { hasBeenFilled: false },
  sequenceOfEvents: { hasBeenFilled: false }
});

export class MainReportService implements ReportService {
  private httpClient: HttpClient;
  private readonly baseUrl: string;
  private reportPassword: string | null = null;
  private _reportCode: string = initialReportCode;
  private _reportData: ResumeReportDto = initialReportData;
  private _sideNavData: BehaviorSubject<SideNavData> = initialSideNavData;
  public sideNavData: Observable<SideNavData> = this._sideNavData.asObservable();

  private _canSubmit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public canSubmit: Observable<boolean> = this._canSubmit.asObservable();

  public region: Region = new OtherRegion()

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.baseUrl = process.env.REACT_APP_API_ENDPOINT!
  }

  public async getReporterTypes(): Promise<string[]> {
    return ['counsellor', 'onBehalfOfSomeone', 'myself'];
  }

  public async createReport(reporterType: string): Promise<ReportCreatedDto> {
    try {
      const report: ReportCreatedDto = await this.httpClient.post(this.baseUrl + '/report/create', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          reporterType,
          regionId: this.region.regionId
        })
      });

      // persist the report code as a state in the service instance
      this.reportCode = report.shortCode;

      return report;
    } catch (e) {
      throw new ServerError('Service Error. Could not create the report.');
    }
  }

  public async resumeReport(): Promise<ResumeReportDto> {
    let response;

    const queryOptions: { [key: string]: string } = {
      'short-code': this.reportCode
    };

    const options: RequestOptions = {};
    if (this.reportPassword) {
      options.headers = { 'Content-Type': 'application/json' };
      options.body = JSON.stringify({ reportPassword: this.reportPassword });
    }

    try {
      response = await this.httpClient.put(this.baseUrl + `/report/resume?${qs.stringify(queryOptions)}`, options);
    } catch (e) {
      throw new ServerError(`Service Error: ${e.message}`);
    }

    if (response.statusCode === 404) {
      throw new InvalidReportCode('report code does not exist');
    }

    this.reportData = response;
    this.region = response.region;
    this.notifySideNavDataObservers();
    this._canSubmit.next(response.canSubmit);
    return response;
  }

  public async resumeReportWithReportCodeAndPassword(reportCode: string, reportPassword?: string): Promise<ResumeReportDto> {

    let response;

    const queryOptions: { [key: string]: string } = {
      'short-code': reportCode
    };

    const options: RequestOptions = {};
    if (reportPassword) {
      options.headers = { 'Content-Type': 'application/json' };
      options.body = JSON.stringify({ reportPassword });
    }

    try {
      response = await this.httpClient.put(this.baseUrl + `/report/resume?${qs.stringify(queryOptions)}`, options);
    } catch (e) {
      throw new ServerError(`Service Error: ${e.message}`);
    }

    if (response.statusCode === 400) {
      throw new InvalidReportPassword('report password is incorrect');
    }

    if (response.statusCode === 404) {
      throw new InvalidReportCode('report code does not exist');
    }

    // upon success persist the report code and the report password
    this.reportCode = reportCode;

    if (reportPassword) {
      this.reportPassword = reportPassword;
    }

    this.reportData = response;
    this.region = response.region;
    this.notifySideNavDataObservers();
    this._canSubmit.next(response.canSubmit);
    return response;
  }

  public async submitReport() {
    try {
      await this.httpClient.put(this.baseUrl + `/report/submit`, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shortCode: this.reportCode })
      });
      return;
    } catch (e) {
      throw new ServerError('Service Error. Could not submit report.');
    }
  }

  public clear() {
    this._reportCode = initialReportCode;
    this._reportData = initialReportData;
    this._sideNavData = initialSideNavData;
    this.reportPassword = null;
  }

  public isReportCodePresent(): boolean {
    return this._reportCode !== '';
  }

  public async createReportPassword(reportPassword: string): Promise<void> {
    try {
      await this.httpClient.put(this.baseUrl + `/report/create-report-password`, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shortCode: this.reportCode, reportPassword })
      });
      // upon success, set the reportPassword which will later be used by resumeReport
      this.reportPassword = reportPassword;
    } catch (e) {
      throw new ServerError('Service Error. Could not create report password.');
    }
  }

  public async saveWithoutPassword(): Promise<void> {
    try {
      await this.httpClient.put(this.baseUrl + `/report/save-without-password`, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shortCode: this.reportCode })
      });
    } catch (e) {
      throw new ServerError('Service Error. Could not set save without password preference.');
    }
  }

  get reportCode(): string {
    if (this._reportCode === '') {
      window.location.href = '/';
    }
    return this._reportCode;
  }

  get formattedReportCode(): string {
    return formatReportCode(this.reportCode);
  }

  set reportCode(reportCode: string) {
    this._reportCode = reportCode;
  }

  get reportData(): ResumeReportDto {
    return this._reportData;
  }

  set reportData(reportData: ResumeReportDto) {
    this._reportData = reportData;
  }

  get dashboardData(): DashboardData {
    return {
      showReturnCallOut: Boolean(this.reportData?.sections.length > 0)
    };
  }

  get victimSectionSavedData(): VictimSectionSavedData {
    const sectionData = this.reportData?.sections.find(({ sectionType }) => sectionType === 'victim');
    return {
      age: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'age'),
      gender: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'gender'),
      victimRaceAppearance: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'victimRaceAppearance'),
      showReturnCallOut: !!sectionData
    };
  }

  get dateAndLocationSectionData(): DateAndLocationSectionData {
    const dateSectionData = this.reportData?.sections.find(({ sectionType }) => sectionType === 'date');
    const locationSectionData = this.reportData?.sections.find(({ sectionType }) => sectionType === 'location');
    return {
      multipleOccurrencesRecall: dateSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'multipleOccurrencesRecall'),
      approximateDate: dateSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'approximateDate'),
      year: dateSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'year'),
      month: dateSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'month'),
      day: dateSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'day'),
      timeOfDay: dateSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'timeOfDay'),
      addressRecall: locationSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'addressRecall'),
      address: locationSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'address'),
      city: locationSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'city'),
      provinceTerritory: locationSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'provinceTerritory'),
      distinctFeatures: locationSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'locationDistinctFeatures'),
      showReturnCallOut: !!dateSectionData || !!locationSectionData
    };
  }

  get vehicleSectionSavedData(): VehicleSectionSavedData {
    const sectionData = this.reportData?.sections.find(({ sectionType }) => sectionType === 'vehicle');
    return {
      wasVehicleInvolved: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'wasVehicleInvolved'),
      vehicleMake: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'vehicleMake'),
      vehicleModel: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'vehicleModel'),
      vehicleColour: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'vehicleColour'),
      vehicleStyle: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'vehicleStyle'),
      licensePlate: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'licensePlate'),
      issuingProvinceTerritory: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'issuingProvinceTerritory'),
      distinctFeaturesAboutVehicle: sectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'distinctFeaturesAboutVehicle'),
      showReturnCallOut: !!sectionData
    };
  }

  get suspectSectionData(): SuspectSectionSavedData {
    const suspectSectionData = this.reportData?.suspectSection;
    return {
      suspects: suspectSectionData?.suspects,
      showReturnCallOut: !!suspectSectionData?.suspects && suspectSectionData.suspects.length > 0
    };
  }

  get sequenceOfEventsSectionSavedData(): SequenceOfEventsSectionSavedData {
    const sequenceOfEventsSectionData = this.reportData?.sections.find(({ sectionType }) => sectionType === 'sequenceOfEvents');
    return {
      description: sequenceOfEventsSectionData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'description'),
      showReturnCallOut: !!sequenceOfEventsSectionData
    };
  }

  get savedSections(): UISectionType[] {
    const savedSections: UISectionType[] = [];
    this.reportData?.sections.forEach(({ sectionType }) => {
      if (sectionType !== 'date' && sectionType !== 'location') {
        savedSections.push(sectionType as UISectionType);
      }
    });
    if (this.reportData?.suspectSection?.suspects !== undefined && this.reportData?.suspectSection?.suspects.length > 0) {
      savedSections.push('suspect');
    }
    if (this.reportData?.sections.find(({ sectionType }) => sectionType === 'date' || sectionType === 'location')) {
      savedSections.push('dateAndLocation');
    }
    return savedSections;
  }

  get hasReportPassword(): boolean {
    return !!this.reportPassword;
  }

  private notifySideNavDataObservers() {
    this._sideNavData.next({
      victim: { hasBeenFilled: !!this.reportData?.sections.find(({ sectionType }) => sectionType === 'victim') },
      date: { hasBeenFilled: !!this.reportData?.sections.find(({ sectionType }) => sectionType === 'date') },
      location: { hasBeenFilled: !!this.reportData?.sections.find(({ sectionType }) => sectionType === 'location') },
      vehicle: { hasBeenFilled: !!this.reportData?.sections.find(({ sectionType }) => sectionType === 'vehicle') },
      suspect: { hasBeenFilled: this.reportData?.suspectSection?.suspects !== undefined && this.reportData?.suspectSection?.suspects.length > 0 },
      sequenceOfEvents: { hasBeenFilled: !!this.reportData?.sections.find(({ sectionType }) => sectionType === 'sequenceOfEvents') }
    });
  }
}
