export type Color =
  'peach'
  | 'darkerPeach'
  | 'apricot'
  | 'navy'
  | 'lightNavy'
  | 'lavender'
  | 'purple'
  | 'grape'
  | 'brownGrey'
  | 'mediumGrey'
  | 'white'
  | 'lightGrey'
  | 'grey'
  | 'orangeRed'
  | 'none'

export type ColorPalette = Readonly<Record<Color, string>>

export const colorPalette: ColorPalette = {
  peach: '#fef4ee',
  darkerPeach: '#ffefe6',
  apricot: '#ffbea8',
  navy: '#162040',
  lightNavy: '#394a80',
  lavender: '#f6f4ff',
  purple: '#b3a8e8',
  grape: '#6c5fa7',
  white: '#FFFFFF',
  lightGrey: '#EEEEEE',
  grey: '#474747',
  brownGrey: '#7C7C7C',
  mediumGrey: '#6F6F6F',
  orangeRed: '#EA461B',
  none: 'none'
};

export default colorPalette;
