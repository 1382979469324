import React from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import AnimateHeight from 'react-animate-height';
import { CHECKBOX_YES_OPTION, CHECKBOX_NO_OPTION } from '../../../constants';
import { useFormStyles } from '../../../shared/theme/form-styles';
import { suspectSection } from '../../models/suspect-section.model';
import { SuspectForm } from '../../services/suspect-section-form-service';
import { DefaultBodyText } from '../../../shared/components';
import CheckboxSelection from '../../../shared/components/checkbox-selection';
import DoKnowThisPerson from './do-know-this-person/do-know-this-person';
import DoNotKnowThisPerson from './do-not-know-this-person/do-not-know-this-person';
import { useSuspectIdentityController } from './suspect-identity.controller';

const Grid = createGrid();
const model = suspectSection.suspectIdentity;

interface Props {
  form: SuspectForm
}

const SuspectIdentity: React.FunctionComponent<Props> = ({ form }) => {
  const formStyles = useFormStyles();

  const {
    knowThisPerson,
    previousKnowThisPerson,
    handleKnowThisPersonChange
  } = useSuspectIdentityController(form);

  return (
    <>
      <div className={formStyles.questionSectionTitle}>
        <DefaultBodyText isBolded={true}>{model.header}</DefaultBodyText>
      </div>
      <div className={formStyles.questionSectionFirstQuestion} id='knowThisPerson'>
        <DefaultBodyText
          color={form.hasBeenFilled.isSuspectKnown ? 'grape' : 'navy'}>{model.knowThisPerson.label}</DefaultBodyText>
      </div>
      <Grid container justify='center'>
        <Grid item flex={{ xs: 12, md: 10 }}>
          <div className={formStyles.conditionalQuestionContainer}>
            <CheckboxSelection
              options={model.knowThisPerson.options}
              selected={knowThisPerson}
              onChange={handleKnowThisPersonChange}
              hasBeenFilled={form.hasBeenFilled.isSuspectKnown}
              ariaDescribedby='knowThisPerson'
            />
            <AnimateHeight
              duration={800}
              height={knowThisPerson === CHECKBOX_YES_OPTION || knowThisPerson === CHECKBOX_NO_OPTION ? 'auto' : 0}
            >
              {knowThisPerson === CHECKBOX_YES_OPTION && <DoKnowThisPerson form={form}/>}
              {knowThisPerson === CHECKBOX_NO_OPTION && <DoNotKnowThisPerson form={form}/>}
              {knowThisPerson === '' && previousKnowThisPerson === CHECKBOX_YES_OPTION &&
              <DoKnowThisPerson form={form}/>}
              {knowThisPerson === '' && previousKnowThisPerson === CHECKBOX_NO_OPTION &&
              <DoNotKnowThisPerson form={form}/>}
            </AnimateHeight>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SuspectIdentity;
