import { firstTimeSaveAlertContentEn } from './first-time-save-alert.content-en'
import { ImageLabelListModel } from '../../image-label-list/image-label-list.model'

export interface FirstTimeSaveAlertContent {
    closeImgSrc: string
    closeImgAlt: string
    notNowButtonLabel: string
    header: string
    description: string
    noPasswordModel: ImageLabelListModel
    withPasswordModel: ImageLabelListModel
    footNote: string
    createPasswordButtonLabel: string
    doNotCreatePasswordButtonLabel: string
}

export const firstTimeSaveAlertContentProvider = (): FirstTimeSaveAlertContent => {
    return firstTimeSaveAlertContentEn
}
