import 'react-app-polyfill/ie11'
import 'core-js/es/array'
import 'core-js/es/object'
import 'intersection-observer'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);
