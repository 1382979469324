import { createUseStyles } from "react-jss";
import { colorPalette } from "../../../shared/theme";

interface StyleProps {
  isOpen: boolean;
}

export const suspectInformationFormStylesFactory = createUseStyles({
  suspectInfoFormContainer: {
    height: '60px',
    borderBottom: (props: StyleProps) => props.isOpen ? `1px dashed ${colorPalette.purple}` : 'none',
    borderTop: `1px solid ${colorPalette.purple}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:focus':{
      backgroundColor:colorPalette.lavender,
      outline: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  suspectInfoForm: {
    position: 'relative',
    paddingBottom: '32px'
  },
  removeSuspect: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
    marginRight: 7,
    marginBottom: -32
  },
  deleteFormButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    '&:focus': {
      transform:'translateX(-2%)',
      outline: 'none',
      textDecoration: 'underline',
      },
      '&:hover': {
        cursor: 'pointer',
        transform: 'translateX(-1.5%)'
      },
  },
  deleteFormButtonIcon: {
    width: '14px',
    marginLeft: '8px'
  }
})
