import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { DefaultBodyText } from './atomic';
import { BpTheme, fontFamilies } from '../theme';

interface StyleProps {
  label: string;
  hasBeenFilled: boolean;
  height: string;
}

const useStyles = createUseStyles((theme: BpTheme) => ({
  textArea: {
    boxSizing: 'border-box',
    background: (props: StyleProps) => props.hasBeenFilled ? theme.colorPalette.lavender : theme.colorPalette.white,
    color: (props: StyleProps) => props.hasBeenFilled ? theme.colorPalette.grape : theme.colorPalette.navy,
    extend: 'text',
    width: '100%',
    height: (props: StyleProps) => props.height,
    border: `1px solid ${theme.colorPalette.grape}`,
    borderRadius: '8px',
    padding: '10px',
    resize: 'none',
    fontFamily: [theme.fontFamilies.AvenirRoman, theme.fontFamilies.Avenir, fontFamilies.SansSerif],
    fontSize: '1rem',
    marginTop: (props: StyleProps) => props.label ? '8px' : '0',
    '&::placeholder': {
      color: theme.colorPalette.mediumGrey,
      fontStyle: 'italic'
    }
  }
}));

interface TextAreaInputFieldProps {
  onChange: (value: string) => void;
  label?: string;
  placeholder?: string;
  hasBeenFilled?: boolean;
  height?: string;
  initialValue?: string;
  id: string;
}

const TextAreaInputField: React.FunctionComponent<TextAreaInputFieldProps> = ({
                                                                                onChange,
                                                                                label,
                                                                                placeholder,
                                                                                hasBeenFilled,
                                                                                height,
                                                                                initialValue,
                                                                                id,
                                                                              }) => {
  const [value, setValue] = useState<string>(initialValue || '');
  const styles = useStyles({label, hasBeenFilled, height});

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = event.target;
    setValue(value);
    onChange(value);
  };

  if (label) {
    return (
      <div>
        <label htmlFor={id}>
          <DefaultBodyText color={hasBeenFilled ? 'grape' : 'navy'}>{label}</DefaultBodyText>
        </label>
        <textarea
          value={value}
          onChange={handleChange}
          className={styles.textArea}
          placeholder={placeholder}
          id={id}
        />
      </div>
    );
  } else {
    return (
      <textarea
        value={value}
        onChange={handleChange}
        className={styles.textArea}
        placeholder={placeholder}
        id={id}
      />
    );
  }
};

TextAreaInputField.defaultProps = {
  hasBeenFilled: false,
  height: '120px',
  initialValue: ''
};

export default TextAreaInputField;
