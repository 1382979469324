import { useState, ChangeEvent } from 'react'
import alertService from '../../services/alert-service'
import { CreateReportPasswordAlertContent, createReportPasswordAlertContentProvider } from './content/create-report-password-alert.content'
import { ReportPassword } from './report-password'
import ServiceLocator from '../../../shared/service-locator/service-locator'
import notificationService from '../../../notification/services/notification-service'
import snackBarService from '../../../snackbar/services/snackbar-service'

const content: CreateReportPasswordAlertContent = createReportPasswordAlertContentProvider()
const reportService = ServiceLocator.getInstance().getReportService()
const analyticsService = ServiceLocator.getInstance().getAnalyticsService()

export function useCreateReportPasswordAlertController() {
  const [isExecuting, setIsExecuting] = useState<boolean>(false)

  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('')
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState<string>('')

  const [passwordValue, setPasswordValue] = useState<ReportPassword>(new ReportPassword(''))
  const [confirmPasswordValue, setConfirmPasswordValue] = useState<ReportPassword>(new ReportPassword(''))

  const onBackButtonClicked = () => {
    alertService.clearAlert()
    alertService.alertFirstTimeSave()
  }

  const onCreateButtonClicked = async () => {
    if (!passwordValue.isValid()) {
      setPasswordErrorMessage(content.passwordErrorMessage)
      return
    }

    if (!passwordValue.equals(confirmPasswordValue)) {
      setConfirmPasswordErrorMessage(content.confirmPasswordErrorMessage)
      return
    }

    analyticsService.logEvent(
      'create_report_password',
    )

    try {
      setIsExecuting(true)
      await reportService.createReportPassword(passwordValue.value)
      await reportService.resumeReport()
    }
    catch (error) {
      notificationService.notifyServerError()
    }

    setIsExecuting(false)
    snackBarService.displayPasswordCreatedSnackbar()
    alertService.clearAlert()
  }

  const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(passwordValue.updateValue(event.target.value))
    setPasswordErrorMessage('')
  }

  const onConfirmPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPasswordValue(confirmPasswordValue.updateValue(event.target.value))
    setConfirmPasswordErrorMessage('')
  }

  return {
    onBackButtonClicked,
    isExecuting,
    onCreateButtonClicked,
    passwordErrorMessage,
    confirmPasswordErrorMessage,
    onPasswordChanged,
    onConfirmPasswordChanged,
    passwordValue,
    confirmPasswordValue
  }
}
