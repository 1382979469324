import { Observable, BehaviorSubject } from 'rxjs'
import ServiceLocator from '../../shared/service-locator/service-locator'
import { CreateDateAndLocationSectionOptions } from '../../shared/services/create-section-service/interfaces/section-service.interface'
import { SectionFormService } from '../../shared/interfaces/section-form-service.interface'
import snackBarService from '../../snackbar/services/snackbar-service'
import alertService from '../../alerts/services/alert-service'
import { SectionFormBaseService } from '../../shared/services/section-form-base-service/section-form-base.service'

const reportService = ServiceLocator.getInstance().getReportService()
const sectionService = ServiceLocator.getInstance().getSectionService()

class DateAndLocationSectionFormService extends SectionFormBaseService implements SectionFormService {
  // date
  private multipleOccurrencesRecall?: string
  private approximateDate?: string
  private year?: string
  private month?: string
  private day?: string
  private timeOfDay?: string
  // location
  private addressRecall?: string
  private address?: string
  private city?: string
  private provinceTerritory = ''
  private distinctFeatures?: string

  // ready to save flag
  private _isReadyToSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isReadyToSave: Observable<boolean> = this._isReadyToSave.asObservable()

  private updateIsReadyToSaveFlag(): void {
    if (this.multipleOccurrencesRecall || this.addressRecall || this.distinctFeatures) {
      this._isReadyToSave.next(true)
    } else {
      this._isReadyToSave.next(false)
    }
  }

  public initialize() {
    this.multipleOccurrencesRecall = reportService.dateAndLocationSectionData?.multipleOccurrencesRecall?.inputFieldValue
    this.approximateDate = reportService.dateAndLocationSectionData?.approximateDate?.inputFieldValue
    this.year = reportService.dateAndLocationSectionData?.year?.inputFieldValue
    this.month = reportService.dateAndLocationSectionData?.month?.inputFieldValue
    this.day = reportService.dateAndLocationSectionData?.day?.inputFieldValue
    this.timeOfDay = reportService.dateAndLocationSectionData?.timeOfDay?.inputFieldValue
    this.addressRecall = reportService.dateAndLocationSectionData?.addressRecall?.inputFieldValue
    this.address = reportService.dateAndLocationSectionData?.address?.inputFieldValue
    this.city = reportService.dateAndLocationSectionData?.city?.inputFieldValue
    this.provinceTerritory = reportService.dateAndLocationSectionData?.provinceTerritory?.inputFieldValue
    this.distinctFeatures = reportService.dateAndLocationSectionData?.distinctFeatures?.inputFieldValue
  }

  // clear methods
  public clearAddressDetails() {
    this.setAddress('')
    this.setCity('')
    this.setProvinceTerritory('')
  }

  public isFormReadyToBeSaved(): boolean {
    return this._isReadyToSave.value
  }

  public async submit() {
    const data: CreateDateAndLocationSectionOptions = {
      multipleOccurrencesRecall: this.multipleOccurrencesRecall,
      approximateDate: this.approximateDate,
      year: this.year,
      month: this.month,
      day: this.day,
      timeOfDay: this.timeOfDay,
      addressRecall: this.addressRecall,
      address: this.address,
      city: this.city,
      provinceTerritory: this.provinceTerritory,
      distinctFeatures: this.distinctFeatures
    }
    await sectionService.createDateAndLocationSection(reportService.reportCode, data)
    await reportService.resumeReport()
    snackBarService.displaySectionSavedSnackbar()
    alertService.alertFirstTimeSave()
  }

  // setters
  public setMultipleOccurrencesRecall(multipleOccurrencesRecall: string) {
    this.multipleOccurrencesRecall =
      this.compare(multipleOccurrencesRecall,
        reportService.dateAndLocationSectionData?.multipleOccurrencesRecall?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setApproximateDate(approximateDate: string) {
    this.approximateDate =
      this.compare(approximateDate,
        reportService.dateAndLocationSectionData?.approximateDate?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setYear(year: string) {
    this.year =
      this.compare(year,
        reportService.dateAndLocationSectionData?.year?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setMonth(month: string) {
    this.month =
      this.compare(month,
        reportService.dateAndLocationSectionData?.month?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setDay(day: string) {
    this.day =
      this.compare(day,
        reportService.dateAndLocationSectionData?.day?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setTimeOfDay(timeOfDay: string) {
    this.timeOfDay =
      this.compare(timeOfDay,
        reportService.dateAndLocationSectionData?.timeOfDay?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setAddressRecall(addressRecall: string) {
    this.addressRecall =
      this.compare(addressRecall,
        reportService.dateAndLocationSectionData?.addressRecall?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setAddress(address: string) {
    this.address =
      this.compare(address,
        reportService.dateAndLocationSectionData?.address?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setCity(city: string) {
    this.city =
      this.compare(city,
        reportService.dateAndLocationSectionData?.city?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setProvinceTerritory(provinceTerritory: string) {
    this.provinceTerritory = provinceTerritory
    this.updateIsReadyToSaveFlag()
  }

  public setDistinctFeatures(distinctFeatures: string) {
    this.distinctFeatures =
      this.compare(distinctFeatures,
        reportService.dateAndLocationSectionData?.distinctFeatures?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }
}

const dateAndLocationSectionFormService = new DateAndLocationSectionFormService()

export default dateAndLocationSectionFormService
