import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useFormStyles } from '../../shared/theme/form-styles';
import { suspectSection as model } from '../models/suspect-section.model';

// components
import InfoBlock from '../../shared/components/info-block';
import { Divider, DefaultBodyText } from '../../shared/components'
import BackToDashboardButton from '../../shared/components/back-to-dashboard-button';
import TertiaryButton from '../../shared/components/tertiary-button';
import SuspectSectionFormCtaButtons from './suspect-section-cta-buttons';
import SuspectInformationForm from './suspect-information-form/suspect-information-form';
import { useSuspectSectionController } from './suspect-section.controller';
import ReportSectionHeader from "../../shared/components/report-section-header/report-section-header"

const SuspectSection: React.FunctionComponent<RouteComponentProps> = () => {
  const formStyles = useFormStyles();

  const {
    formIds,
    openForms,
    isSaveAndCloseLoading,
    isSaveAndNextSectionLoading,
    isReadyToSave,
    showReturnCallOut,
    isWithPassword,
    handleSaveAndClose,
    handleSaveAndNextSection,
    handleDeleteForm,
    handleToggleView,
    handelCreateForm
  } = useSuspectSectionController();

  return (
    <div className={formStyles.page} id="page">
      <div className={formStyles.backButton}>
        <BackToDashboardButton leavingWhileDataIsUnsaved={isReadyToSave}/>
      </div>

      <ReportSectionHeader headerName={model.sectionHeader}></ReportSectionHeader>

      <div className={formStyles.infoBlock}>
        <InfoBlock>
          <DefaultBodyText>{model.description}</DefaultBodyText>
          {showReturnCallOut && (
            <>
              <br/>
              <DefaultBodyText>
                <b
                  style={{display: 'contents'}}>{isWithPassword ? model.returnCallOutWithPassword[0].text : model.returnCallOutWithoutPassword[0].text}</b>
                <p
                  style={{display: 'contents'}}>{isWithPassword ? model.returnCallOutWithPassword[1].text : model.returnCallOutWithoutPassword[1].text}</p>
              </DefaultBodyText>
            </>
          )}
        </InfoBlock>
      </div>
      <div className={formStyles.divider}>
        <Divider width="100px" thickness="medium"/>
      </div>

      <div className={formStyles.question}>
        {formIds.map((formId: number, index: number) => {
          return (
            <SuspectInformationForm
              key={formId}
              formId={formId}
              formNumber={index}
              deleteForm={handleDeleteForm}
              isOpen={openForms[formId]}
              toggleView={handleToggleView}
            />
          );
        })}
      </div>

      <div className={formStyles.addAnotherSuspectButton}>
        <TertiaryButton
          color="grape"
          onClick={handelCreateForm}>
          + Add another suspect description
        </TertiaryButton>
      </div>

      <div className={formStyles.buttonsContainer}>
        <SuspectSectionFormCtaButtons
          onSaveAndClose={handleSaveAndClose}
          isSaveAndCloseLoading={isSaveAndCloseLoading}
          onSaveAndNextSection={handleSaveAndNextSection}
          isSaveAndNextSectionLoading={isSaveAndNextSectionLoading}
        />
      </div>
    </div>
  );
};

export default withRouter(SuspectSection);
