import React from 'react';
import AnimateHeight from 'react-animate-height';
import { createUseStyles } from 'react-jss';
import { DefaultBodyText } from '../../shared/components';
import { colorPalette } from '../../shared/theme';

interface Props {
  show: boolean
}

const useStyles = createUseStyles({
  icon: {
    width: '40px',
    paddingRight: '15px'
  },
  notification: {
    height: '80px',
    background: colorPalette.orangeRed,
    width: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem'
  }
});

const ServerErrorNotification: React.FunctionComponent<Props> = ({show}) => {
  const {notification} = useStyles();

  return (
    <AnimateHeight
      duration={800}
      height={show ? 'auto' : 0}
    >
      <div className={notification} role='alert'>
        <DefaultBodyText fontSize="1rem" color="white" letterSpacing="1.14px">
          <b>HMMM, SOMETHING WENT WRONG.</b> Please try again later.
        </DefaultBodyText>
      </div>
    </AnimateHeight>
  );
};

export default ServerErrorNotification;
