import { useHistory } from "react-router-dom"
import { useEffect, useState } from 'react'
import ServiceLocator from '../../../../shared/service-locator/service-locator'
import { OtherRegion, Region } from '../../../../shared/services/region-service/interfaces/region-service.interface'

const regionService = ServiceLocator.getInstance().getRegionService()
const reportService = ServiceLocator.getInstance().getReportService()
const analyticsService = ServiceLocator.getInstance().getAnalyticsService()
const appInsights = ServiceLocator.getInstance().getAppInsightsService()

export function useHomeSelectRegionController() {

  const [regions, setRegions] = useState<Region[]>([])

  const history = useHistory()

  const handleContinueClick = () => {
    analyticsService.logEvent('home_continue', {
      region_name: reportService.region.regionName
    })

    if (reportService.region.isOther) {
      history.push('/resources')
    } else {
      history.push('/onboarding')
    }
  }

  const onRegionChange = (regionId: string) => {
    const regionIdAsNumber = parseInt(regionId, 10)
    const foundRegion = regions.find(region => regionIdAsNumber === region.regionId)
    reportService.region = foundRegion || new OtherRegion()
}

  async function getRegions() {
    const regionList = await regionService.getRegions()
    setRegions(regionList)
    appInsights.trackEvent('Regions fetched', {
      'regions': regionList.map(region => region.regionName).join('; '),
      'num_of_regions': regionList.length
    })
  }

  useEffect(() => {
    getRegions()
  }, [])

  return {
    handleContinueClick,
    onRegionChange,
    regions
  }
}


