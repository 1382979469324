import React from 'react';
import { createUseStyles } from 'react-jss';

// theme
import { Color, BpTheme, fontFamilies } from '../../theme';

interface Props extends React.ComponentPropsWithoutRef<'p'> {
  children: React.ReactNode;
  color?: Color;
  fontSize?: string;
  isBolded?: boolean
  isUnderlined?: boolean;
  isItalicized?: boolean;
  letterSpacing?: string;
  display?: string;
  isAlert?: boolean;
}

const useStyles = createUseStyles((theme: BpTheme) => ({
  defaultBodyText: {
    ...theme.fontStylesMobile.body,
    alignSelf: 'center',
    fontFamily: (props: Props) => props.isBolded ? [theme.fontFamilies.AvenirBlack, theme.fontFamilies.Avenir, fontFamilies.SansSerif] : [theme.fontFamilies.AvenirRoman, theme.fontFamilies.Avenir, fontFamilies.SansSerif],
    color: (props: Props) => props.color ? theme.colorPalette[props.color] : null,
    textDecoration: (props: Props) => props.isUnderlined ? 'underlined' : 'none',
    fontWeight: (props: Props) => props.isBolded ? 'bold' : 'normal',
    fontStyle: (props: Props) => props.isItalicized ? 'italic' : 'normal',
    letterSpacing: (props: Props) => props.letterSpacing,
    display: (props: Props) => props.display,
    whiteSpace: 'pre-wrap'
  }
}));



export const DefaultBodyText: React.FC<Props> = ({
  children,
  color,
  fontSize,
  isUnderlined,
  isBolded,
  isItalicized,
  letterSpacing,
  display,
  isAlert
}) => {
  const {defaultBodyText} = useStyles({
    color,
    fontSize,
    isUnderlined,
    isBolded,
    isItalicized,
    letterSpacing,
    display
  });
  return (
    <p role={isAlert ? "alert" : undefined} className={defaultBodyText}>{children}</p>
  );
};

DefaultBodyText.defaultProps = {
  color: "navy",
  fontSize: "0.875rem",
  isUnderlined: false,
  isItalicized: false,
  letterSpacing: '0px',
  isAlert: false
};

