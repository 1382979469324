import React from 'react';
import { DefaultHeader } from '../../shared/components';

interface ReportCodeProps {
  shortCode: string
}

const ReportCode: React.FunctionComponent<ReportCodeProps> = ({shortCode}) => {
  return (
    <DefaultHeader>{shortCode}</DefaultHeader>
  );
};

export default ReportCode;
