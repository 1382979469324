import { HttpClient, RequestOptions } from "./http-client.interface";
import notificationService from "../../notification/services/notification-service";
import { ServerError } from "../errors/server-error";

class MainHttpClient implements HttpClient {
  private fetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;

  public constructor() {
    this.fetch = window.fetch.bind(window)
  }

  public async get(url: string, options: RequestOptions = {}) {
    try {
      const response = await this.fetch(url, {
        ...options,
        method: 'GET'
      })
      return this.returnParsedResponse(response)
    } catch (e) {
      notificationService.notifyServerError()
      throw new ServerError(e.message)
    }
  }
  
  public async post(url: string, options: RequestOptions = {}) {
    try {
      const response = await this.fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })
      return this.returnParsedResponse(response)
    } catch (e) {
      notificationService.notifyServerError()
      throw new ServerError(e.message)
    }
  }

  public async put(url: string, options: RequestOptions = {}) {
    try {
      const response = await this.fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          'Content-Type': 'application/json'
        },
        method: 'PUT'
      })
      return this.returnParsedResponse(response)
    } catch (e) {
      notificationService.notifyServerError()
      throw new ServerError(e.message)
    }
  }

  private returnParsedResponse(response: Response) {
    if (response.status === 401) {
      window.location.href = '/login'
      return
    }
    if (response.status === 500) {
      notificationService.notifyServerError()
      throw new ServerError('Something went wrong in the server, response code was 500')
    }
    const contentType = response.headers.get("content-type")
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json()
    }
    return response
  }
}

export default MainHttpClient;
