import { Content } from "./content.model";

export const returnCallOutWithoutPassword: Content[] = [
  {
    isBolded: true,
    text: 'For your privacy, previously inputted entry details are hidden '
  },
  {
    isBolded: false,
    text: 'but you can add more information to these questions again — new answers will be added to your existing report.'
  },
]

export const returnCallOutWithPassword: Content[] = [
  {
    isBolded: true,
    text: 'Previously inputted entry details are purple. '
  },
  {
    isBolded: false,
    text: 'You can always add or edit your information.'
  },
]