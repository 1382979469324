import React from 'react';
import { ImpactStatementHeader } from '../../../shared/components';
import { homeContentProvider } from '../../contents/home.content';

const content = homeContentProvider();

const HomeHeader = () => {
  return (
    <ImpactStatementHeader>{content.heading}</ImpactStatementHeader>
  );
};

export default HomeHeader;
