export type ViewportSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type Breakpoints = Readonly<Record<ViewportSize, number>>

export const breakpoints: Breakpoints = {
  xs: 320,
  sm: 576,
  md: 769,
  lg: 992,
  xl: 1200
};

type ScreenSizes =
  'mobileOnly'
  | 'mobileAndAbove'
  | 'tabletOnly'
  | 'tabletAndAbove'
  | 'desktopOnly'
  | 'desktopAndAbove'
  | 'xl'

export type MediaQueries = Readonly<Record<ScreenSizes, string>>

export const mediaQueries: MediaQueries = {
  mobileOnly: `@media only screen 
  and (max-width: ${breakpoints.md}px)`,
  mobileAndAbove: `@media only screen 
    and (min-width: ${breakpoints.xs}px)`,
  tabletOnly: `@media only screen 
    and (min-width: ${breakpoints.md}px) 
    and (max-width: ${breakpoints.lg}px)`,
  tabletAndAbove: `@media only screen 
    and (min-width: ${breakpoints.md}px)`,
  desktopOnly: `@media only screen 
    and (min-width: ${breakpoints.lg}px)
    and (max-width: ${breakpoints.xl}px)`,
  desktopAndAbove: `@media only screen 
    and (min-width: ${breakpoints.lg}px)`,
  xl: `@media only screen 
    and (min-width: ${breakpoints.xl}px)`
};
