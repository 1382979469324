import React from 'react';
import { createUseStyles } from 'react-jss';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// service
import timeoutService from '../services/timeout-service';
import alertService from '../services/alert-service';

// components
import BaseAlert from './base-alert';
import { ImpactStatementHeader, DefaultBodyText } from '../../shared/components';
import PrimaryButton from '../../shared/components/primary-button';

const useLayout = createUseStyles({
  description: {
    paddingTop: '16px'
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '120px',
    alignItems: 'flex-end',
    paddingTop: '32px'
  }

});

const TimeoutAlert: React.FunctionComponent<RouteComponentProps> = () => {
  const layout = useLayout();

  const handleContinueSession = () => {
    timeoutService.resetTimer();
    alertService.clearAlert();
  };

  return (
    <BaseAlert aria-labelledby='stillHereHeader'>
      <div id='stillHereHeader'>
        <ImpactStatementHeader>Still there?</ImpactStatementHeader>
      </div>
      <div className={layout.description}>
        <DefaultBodyText fontSize="1rem">
          We’ve noticed your session has been inactive for <b>15 minutes</b>. Would you like to continue with your
          report?
        </DefaultBodyText>
      </div>
      <div className={layout.ctaButtons}>
        <PrimaryButton onClick={handleContinueSession} width="200px" isActive={true}>Continue Session</PrimaryButton>
      </div>
    </BaseAlert>
  );
};

export default withRouter(TimeoutAlert);
