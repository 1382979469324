import { AppInsightsCustomProperties, AppInsightsService } from './app-insights-service.interface'
import { ApplicationInsights } from "@microsoft/applicationinsights-web"

export class MicrosoftAppInsightsService implements AppInsightsService {
  constructor(private readonly appInsights: ApplicationInsights) {
  }

  trackEvent(eventName: string, customProperties: AppInsightsCustomProperties): void {
    this.appInsights.trackEvent({ name: eventName }, customProperties)
  }
}
