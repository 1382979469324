import React, { useEffect, useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import debounce from 'lodash/debounce';
import { NavigationContext } from './navigation-context';

// service
import ServiceLocator from '../shared/service-locator/service-locator';
import timeoutService from '../alerts/services/timeout-service';

// components
import SideNav from './side-nav/components/side-nav';
import ReportContainerBody from './report-container-body';
import NotificationContainer from '../notification/components/notification-container';
import { BpTheme } from '../shared/theme';

// services
const reportService = ServiceLocator.getInstance().getReportService();


// styles
const useStyles = createUseStyles((theme: BpTheme) => ( {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  content: {
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    [theme.mediaQueries.tabletAndAbove]: {
      right: 0,
      width: '70%'
    },
    [theme.mediaQueries.desktopAndAbove]: {
      width: '75%'
    }
  },
} ));


// component definition
const ReportContainer = () => {
  const classes = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = (didNavigate = false) => {
    if (contentRef.current !== null && didNavigate && showNav) {
      contentRef.current.scrollTop = 0;
    }

    setShowNav(!showNav);
  };


  useEffect(() => {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    timeoutService.startTimer();

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const reportCodePresent = reportService.isReportCodePresent();
  useEffect(() => {
    if (!reportCodePresent) {
      window.location.href = '/';
    }
  }, [reportCodePresent]);

  useEffect(() => {
    const eventListener: () => void = () => {
      toggleNav();
    };
    const contentDiv = contentRef.current;
    if (contentDiv !== null) {
      if (showNav) {
        contentDiv.addEventListener('click', eventListener);

      } else {
        contentDiv.removeEventListener('click', eventListener);
      }
    }
    return () => {
      if (contentDiv) {
        contentDiv.removeEventListener('click', eventListener);
      }
    };
  }, [showNav]);

  const handleMouseMove = debounce(() => {
    timeoutService.resetTimer();
  }, 100);


  return (
    <NavigationContext.Provider value={{ showNav, toggleNav }}>
      <NotificationContainer/>
      <div id="mouse-move-container" className={classes.container} onMouseMove={handleMouseMove}>
        <SideNav/>
        <div className={classes.content} role="main" ref={contentRef}>
          <ReportContainerBody/>
        </div>
      </div>
    </NavigationContext.Provider>
  );
};

export default ReportContainer;
