import {
  SectionService,
  CreateDateAndLocationSectionOptions,
  CreateVehicleSectionOptions,
  CreateSuspectSectionOptions,
  CreateVictimSectionOptions,
  CreateSequenceOfEventsSectionOptions
} from "./interfaces/section-service.interface";
import { HttpClient } from "../../clients/http-client.interface";
import { createDateAndLocationSectionRequestBody } from "./helpers/create-date-and-location-section-request-body";
import { createVictimSectionRequestBody } from "./helpers/create-victim-section-request-body";
import { createVehicleSectionRequestBody } from "./helpers/create-vehicle-section-request-body";
import { createSuspectSectionRequestBody } from "./helpers/create-suspect-section-request-body";
import { createSequenceOfEventsSectionRequestBody } from "./helpers/create-sequence-of-events-section-request-body";

class MainSectionService implements SectionService {
  private httpClient: HttpClient;
  private baseUrl: string;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.baseUrl = process.env.REACT_APP_API_ENDPOINT!
  }

  public async createDateAndLocationSection(reportCode: string, data: CreateDateAndLocationSectionOptions) {

    const requestBody = createDateAndLocationSectionRequestBody({
      dateSection: { sectionType: 'date', shortCode: reportCode },
      locationSection: { sectionType: 'location', shortCode: reportCode }
    }, data)

    try {
      await this.httpClient.post(this.baseUrl + '/date-and-location-section/create', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      })
      return
    } catch (e) {
      throw new Error('Section Service Error. creating date and location section failed')
    }
  }

  public async createVictimSection(reportCode: string, data: CreateVictimSectionOptions) {

    const requestBody = createVictimSectionRequestBody({
      sectionType: "victim",
      shortCode: reportCode,
    }, data)

    try {
      await this.httpClient.post(this.baseUrl + '/victim-section/create', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      })
      return
    } catch (e) {
      throw new Error('Section Service Error. creating victim section failed.')
    }
  }

  public async createVehicleSection(reportCode: string, data: CreateVehicleSectionOptions) {

    const requestBody = createVehicleSectionRequestBody({
      sectionType: "vehicle",
      shortCode: reportCode
    }, data)

    try {
      await this.httpClient.post(this.baseUrl + '/vehicle-section/create', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      })
      return
    } catch (e) {
      throw new Error('Section Service Error. creating vehicle section failed.')
    }
  }

  public async createSuspectSection(reportCode: string, data: CreateSuspectSectionOptions) {

    const requestBody = createSuspectSectionRequestBody({
      sectionType: "suspect",
      shortCode: reportCode,
    }, data)

    try {
      await this.httpClient.post(this.baseUrl + '/suspect-section/create', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      })
      return
    } catch (e) {
      throw new Error('Section Service Error. creating suspect section failed.')
    }
  }

  public async createSequenceOfEventsSection(reportCode: string, data: CreateSequenceOfEventsSectionOptions) {

    const requestBody = createSequenceOfEventsSectionRequestBody({
      sectionType: "sequenceOfEvents",
      shortCode: reportCode,
    }, data)

    try {
      await this.httpClient.post(this.baseUrl + '/sequence-of-events-section/create', {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      })
      return
    } catch (e) {
      throw new Error('Section Service Error. creating sequence of events section failed.')
    }
  }
}

export default MainSectionService
