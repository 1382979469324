import React from 'react'
import BaseButton from '../../../shared/components/base-button'
import { sideNav } from '../models/side-nav.model'
import alertService from '../../../alerts/services/alert-service'
import ServiceLocator from '../../../shared/service-locator/service-locator'

const reportService = ServiceLocator.getInstance().getReportService()

interface Props {
  disabled?: boolean;
}

const SubmitButton: React.FunctionComponent<Props> = ({ disabled }) => {
  const hasAtLeastOneSectionSaved = reportService.savedSections && reportService.savedSections?.length > 0
  const handleButtonClick = () => {
    if (!disabled && hasAtLeastOneSectionSaved) {
      alertService.alertSubmitForm()
    }
  }
  const disableButton = disabled || !hasAtLeastOneSectionSaved

  return (
    <BaseButton
      borderColor={disableButton ? 'lightGrey' : 'lavender'}
      backgroundColor={disableButton ? 'none' : 'lavender' }
      fontColor={disableButton ? 'lightGrey' : 'grape'}
      width="210px"
      inactive={disableButton}
      onClick={handleButtonClick}
    >
      {sideNav.submitButton.label.toUpperCase()}
    </BaseButton>
  )
}

SubmitButton.defaultProps = {
  disabled: false
}

export default SubmitButton
