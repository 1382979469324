// dependencies
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Container } from './container';
import { createGrid, ItemColumn, ItemFlex } from '@nascentdigital/react-lattice';
import logo from '../../assets/logo.png';
import backgroundImage from '../../assets/background-home.png';
import Background from './background';
import { BpTheme, breakpoints } from '../theme';

// styling
const useStyles = createUseStyles((theme: BpTheme) => ( {
  background: {
    [theme.mediaQueries.tabletAndAbove]: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
    }
  },
  logo: {
    width: 150,
    paddingBottom: 32,
    [theme.mediaQueries.tabletAndAbove]: {
      paddingBottom: 50
    }
  }
} ));

const Grid = createGrid({ breakpoints });

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  columnWidth?: boolean | ItemColumn | ItemFlex
}

// component definition
export const BrandedContainer: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <Background backgroundImage={backgroundImage} className={`${props.className || ''} ${classes.background}`}>
      <Container>
        <Grid container direction="row" justify="center">
          <Grid item flex={{ xs: 12, md: 8 }}>
            <img src={logo} alt="Speakout Logo" width="150px" className={classes.logo}/>
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </Background>
  );
};
