import React, { useState, useEffect, useRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { createGrid } from '@nascentdigital/react-lattice';
import BaseAlert from "../base-alert";
import { ImpactStatementHeader, DefaultBodyText, DefaultHeader } from '../../../shared/components';
import PrimaryButton from '../../../shared/components/primary-button';
import SecondaryButton from '../../../shared/components/secondary-button';
import ServiceLocator from '../../../shared/service-locator/service-locator';
import { useLayout } from './styles';
import alertService from '../../services/alert-service';
import TertiaryButton from '../../../shared/components/tertiary-button';
import ReactToPrint from 'react-to-print';
import { PrintTemplate } from '../../../dashboard/components/print-template';
import SupportBlock from '../../../shared/components/support-block';
import { breakpoints } from '../../../shared/theme';

const reportService = ServiceLocator.getInstance().getReportService();
const analyticsService = ServiceLocator.getInstance().getAnalyticsService();

const Grid = createGrid({ breakpoints });

const LeaveFormAlert: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const [reportCode] = useState<string>(reportService.reportCode);
  const layout = useLayout();
  const [recentlyCopied, setRecentlyCopied] = useState<boolean>(false);
  const printComponentRef = useRef(null);

  const handleLeave = () => {
    analyticsService.logEvent('leave_session')
    reportService.clear()
    window.onbeforeunload = () => {}
    window.location.href = 'https://www.google.ca/'
  };

  const handleClose = () => {
    alertService.clearAlert();
  };

  useEffect(() => {
    if (recentlyCopied) {
      setTimeout(() => setRecentlyCopied(false), 600);
    }
  }, [recentlyCopied]);


  return (
    <BaseAlert aria-labelledby='exitHeader'>
      <ImpactStatementHeader>Exiting?</ImpactStatementHeader>
      <div className={layout.description}>
        <DefaultBodyText fontSize="1rem">You can always come back to add information.</DefaultBodyText>
      </div>
      <div className={layout.supportBlock}>
        <SupportBlock height="260px" width="100%">
          <div>
            <DefaultHeader>This is your code.</DefaultHeader>
            <div className={layout.subdescription}>
              <DefaultBodyText>Keep this code to pick up where you left off. Any unsaved information will be lost, but
                you can always come back to add information.</DefaultBodyText>
            </div>
          </div>
          <div className={layout.reportCode}>
            <DefaultHeader>{reportService.formattedReportCode}</DefaultHeader>
          </div>
          <Grid container direction="row" justify={'spaceAround'}>
            <Grid item flex={6}>
              <CopyToClipboard text={reportCode.toUpperCase()}>
                <TertiaryButton onClick={() => setRecentlyCopied(true)} color={recentlyCopied ? 'lightGrey' : 'grape'}
                                width="100%">
                  COPY CODE
                </TertiaryButton>
              </CopyToClipboard>
            </Grid>
            <Grid item flex={6}>
              <ReactToPrint
                trigger={() => <TertiaryButton color="grape" width="100%">PRINT CODE →</TertiaryButton>}
                content={() => printComponentRef.current}
              />
              <div style={{ display: 'none' }}>
                <PrintTemplate ref={printComponentRef} reportCode={reportCode}/>
              </div>
            </Grid>
          </Grid>
        </SupportBlock>
      </div>
      <div className={layout.ctaButtons}>
        <PrimaryButton onClick={handleLeave} width="230px">LEAVE</PrimaryButton>
        <SecondaryButton onClick={handleClose} width="230px">CANCEL</SecondaryButton>
      </div>
    </BaseAlert>
  );
};

export default withRouter(LeaveFormAlert);
