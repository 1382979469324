import { Section } from '../../shared/models/section.model'
import { returnCallOutWithPassword, returnCallOutWithoutPassword } from '../../shared/models/return-call-out.model'
import { HappenedMoreThanOnceQuestionModel, happenedMoreThanOnceQuestion } from './happened-more-than-once-question.model';
import { KnowAddressOfThisLocationQuestionModel, knowAddressOfThisLocationQuestion } from './know-address-of-this-location-question.model'
import { DistinctFeaturesAboutTheLocationQuestionModel, distinctFeaturesAboutTheLocationQuestion } from './distinct-feature-about-location-question.model'

interface DateAndLocationSection extends Section {
  happenedMoreThanOnce: HappenedMoreThanOnceQuestionModel;
  knowAddressOfThisLocation: KnowAddressOfThisLocationQuestionModel;
  distinctFeaturesAboutTheLocation: DistinctFeaturesAboutTheLocationQuestionModel;
}

export const dateAndLocationSection: DateAndLocationSection = {
  sectionHeader: 'Description of Date and Location',
  description: 'Information regarding the date and location of the assault can help police identify trends in specific areas, potential suspects/offenders, and/or where they could frequent. It could also help the police determine if there are specific locations that have a higher prevalence of assault.',
  returnCallOutWithPassword,
  returnCallOutWithoutPassword,
  happenedMoreThanOnce: happenedMoreThanOnceQuestion,
  knowAddressOfThisLocation: knowAddressOfThisLocationQuestion,
  distinctFeaturesAboutTheLocation: distinctFeaturesAboutTheLocationQuestion
}