import React from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import ServiceLocator from '../../shared/service-locator/service-locator';

// service
import formService from '../services/date-and-location-section-form-service';

// theme
import { useFormStyles } from '../../shared/theme/form-styles';

// model
import { dateAndLocationSection } from '../models/date-and-location-section.model';

// components
import { DefaultBodyText, Divider } from '../../shared/components';
import TextInputField from '../../shared/components/text-input-field';
import Dropdown from '../../shared/components/dropdown';
import { numberValidator } from '../../shared/validators/number-validator';

const Grid = createGrid();
const reportService = ServiceLocator.getInstance().getReportService();
const model = dateAndLocationSection.happenedMoreThanOnce;

const DidNotHappenMoreThanOnce = () => {
  const formStyles = useFormStyles();

  return (
    <div className={formStyles.question}>
      <DefaultBodyText>
        <p style={{ display: 'contents' }}>{model.NO_whenItHappened[0].text} </p>
        <br/>
        <p style={{ display: 'contents' }}>{model.NO_whenItHappened[1].text}</p>
      </DefaultBodyText>
      <Grid container className={formStyles.textInputGroup}>
        <Grid item flex={{ xs: 4, sm: 3 }}>
          <TextInputField
            initialValue={reportService.dateAndLocationSectionData?.year?.inputFieldValue}
            hasBeenFilled={!!reportService.dateAndLocationSectionData?.year}
            label={model.year.label}
            placeholder={model.year.placeholder}
            centerPlaceholder={false}
            onChange={(year) => formService.setYear(year)}
            validator={(value) => numberValidator(value, 4)}
            id='year'
          />
        </Grid>
        <Grid item flex={{ xs: 3, sm: 2 }}>
          <TextInputField
            initialValue={reportService.dateAndLocationSectionData?.month?.inputFieldValue}
            hasBeenFilled={!!reportService.dateAndLocationSectionData?.month}
            label={model.month.label}
            placeholder={model.month.placeholder}
            centerPlaceholder={false}
            onChange={(month) => formService.setMonth(month)}
            validator={(value) => numberValidator(value, 2)}
            id='month'
          />
        </Grid>
        <Grid item flex={{ xs: 3, sm: 2 }}>
          <TextInputField
            initialValue={reportService.dateAndLocationSectionData?.day?.inputFieldValue}
            hasBeenFilled={!!reportService.dateAndLocationSectionData?.day}
            label={model.day.label}
            placeholder={model.day.placeholder}
            centerPlaceholder={false}
            onChange={(day) => formService.setDay(day)}
            validator={(value) => numberValidator(value, 2)}
            id='day'
          />
        </Grid>
      </Grid>
      <Grid container className={formStyles.questionGroup}>
        <Grid item flex={{ xs: 12, md: 8 }}>
          <Dropdown
            initialValue={reportService.dateAndLocationSectionData?.timeOfDay?.inputFieldValue}
            hasBeenFilled={!!reportService.dateAndLocationSectionData?.timeOfDay}
            label={model.timeOfDay.label}
            options={model.timeOfDay.options}
            onChange={(timeOfDay) => formService.setTimeOfDay(timeOfDay)}
            id='timeOfDay'
          />
        </Grid>
      </Grid>
      <div className={formStyles.divider}>
        <Divider width="100%" thickness="thin"/>
      </div>
    </div>
  );
};

export default DidNotHappenMoreThanOnce;
