import React, { useEffect } from 'react'

// theme
import { homeContentProvider } from '../../../contents/home.content'
import PrimaryActionButton from '../../../../shared/components/primary-action-button'
import { useHomeSelectRegionController } from './home-select-region.controller'
import Dropdown from '../../../../shared/components/dropdown'
import { capitalizeString } from '../../../../shared/utils/capitalize-string'
import { createUseStyles } from 'react-jss'
import { BpTheme } from '../../../../shared/theme'
import ServiceLocator from "../../../../shared/service-locator/service-locator"
import { OtherRegion } from "../../../../shared/services/region-service/interfaces/region-service.interface"

const content = homeContentProvider();
const reportService = ServiceLocator.getInstance().getReportService()

export const useStyles = createUseStyles((theme: BpTheme) => ({
  button: {
    padding: 0
  },
  dropdown: {
    flex: 1
  },
  [theme.mediaQueries.tabletAndAbove]: {
    button: {
      paddingLeft: '32px'
    }
  },
}))

const HomeSelectRegion: React.FunctionComponent = () => {
  const classes = useStyles()

  const {
    handleContinueClick,
    onRegionChange,
    regions
  } = useHomeSelectRegionController()

  useEffect(() => {
    reportService.region = new OtherRegion()
  }, []);

  const regionOptions = regions.map((region) => ({
    displayText: capitalizeString(region.regionName),
    value: region.regionId.toString()
  }))
    .concat([{
      displayText: new OtherRegion().regionName,
      value: new OtherRegion().regionId.toString(),
    }])

  return (
    <>
      <div className={classes.dropdown}>
        <Dropdown
          label={content.regionDropdownLabel}
          onChange={onRegionChange}
          options={regionOptions}
          id="select-region"
        />
      </div>
      <div className={classes.button}>
        <PrimaryActionButton onClick={handleContinueClick} width="210px">
          {content.continueButtonLabel}
        </PrimaryActionButton>
      </div>
    </>
  );
};

export default HomeSelectRegion;
