import { BehaviorSubject, Observable } from 'rxjs'
import ServiceLocator from '../../shared/service-locator/service-locator'

export enum ALERT_TYPE {
  NONE,
  LEAVING_SECTION,
  SUBMIT_FORM,
  LEAVE_FORM,
  SESSION_EXPIRE_WARNING,
  SESSION_EXPIRED,
  FIRST_TIME_SAVE,
  CREATE_REPORT_PASSWORD,
  RESUME_REPORT
}

type ALERT_OPTIONS = {
  [key: string]: any
}

export interface ALERT {
  type: ALERT_TYPE,
  options?: { [index: string]: string }
}

const reportService = ServiceLocator.getInstance().getReportService()

class AlertService {
  private _alert: BehaviorSubject<ALERT> = new BehaviorSubject<ALERT>({ type: ALERT_TYPE.NONE, options: undefined })
  public alert: Observable<ALERT> = this._alert.asObservable()

  public notifyAlert(type: ALERT_TYPE, options?: ALERT_OPTIONS) {
    this._alert.next({
      type,
      options
    })
  }

  public alertLeavingSection(options?: ALERT_OPTIONS) {
    this._alert.next({
      type: ALERT_TYPE.LEAVING_SECTION,
      options
    })
  }

  public alertLeaveForm(options?: ALERT_OPTIONS) {
    this._alert.next({
      type: ALERT_TYPE.LEAVE_FORM,
      options
    })
  }

  public alertSubmitForm(options?: ALERT_OPTIONS) {
    this._alert.next({
      type: ALERT_TYPE.SUBMIT_FORM,
      options
    })
  }

  public alertSessionExpireWarning(options?: ALERT_OPTIONS) {
    this._alert.next({
      type: ALERT_TYPE.SESSION_EXPIRE_WARNING,
      options
    })
  }

  public alertSessionExpired(options?: ALERT_OPTIONS) {
    this._alert.next({
      type: ALERT_TYPE.SESSION_EXPIRED,
      options
    })
  }

  public alertFirstTimeSave(options?: ALERT_OPTIONS) {
    if (reportService.reportData?.hasRespondedToSaveWithPassword) { return }

    this._alert.next({
      type: ALERT_TYPE.FIRST_TIME_SAVE,
      options
    })
  }

  public alertCreateReportPassword(options?: ALERT_OPTIONS) {
    this._alert.next({
      type: ALERT_TYPE.CREATE_REPORT_PASSWORD,
      options
    })
  }

  public alertResumeReportButtonClick() {
    this._alert.next({
      type: ALERT_TYPE.RESUME_REPORT
    })
  }

  public clearAlert() {
    this._alert.next({
      type: ALERT_TYPE.NONE
    })
  }
}

const alertService = new AlertService()

export default alertService
