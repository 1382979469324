import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { useStyles } from '../styles/loginPageStyle';
import ServiceLocator from '../../shared/service-locator/service-locator';
import logoImage from '../../assets/logo.png';
import InactiveActionButton from '../../shared/components/inactive-action-button';
import { DefaultBodyText } from '../../shared/components';
import TextInputField from '../../shared/components/text-input-field';
import PrimaryActionButton from '../../shared/components/primary-action-button';
import { ROUTES } from '../../constants';

const authService = ServiceLocator.getInstance().getAuthService();

const Login: React.FunctionComponent<RouteComponentProps> = ({history}) => {
  const [isReadyToLogin, setIsReadyToLogin] = useState<boolean>(false);
  const [loginFailed, setLoginFailed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const style = useStyles();
  const unsubscribe: Subject<void> = new Subject();

  useEffect(() => {
    authService.isReadyToLogin.pipe(takeUntil(unsubscribe)).subscribe((isReady) => {
      setIsReadyToLogin(isReady);
    });
  }, [unsubscribe]);

  const handleUsernameChange = (value: string) => {
    setIsReadyToLogin(!!value);
    authService.setUsername(value);
  };

  const handlePasswordChange = (value: string) => {
    setIsReadyToLogin(!!value);
    authService.setPassword(value);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const {accessToken} = await authService.login();
      if (accessToken) {
        history.push(ROUTES.HOME);
      } else {
        setLoginFailed(true);
      }
    } catch (e) {
      setLoginFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.loginPage}>
      <div>
        <img src={logoImage} alt='speak out' className={style.logo}/>
      </div>
      <div className={style.loginCard}>
        <div className={style.title}>
          <DefaultBodyText fontSize="24px">Login</DefaultBodyText>
        </div>
        <div className={style.inputFieldsContainer}>
          <TextInputField label="username" onChange={handleUsernameChange} id='username'/>
          <TextInputField isPassword={true} label="password" onChange={handlePasswordChange} id='password'/>
        </div>
        <div className={style.buttonContainer}>
          {isReadyToLogin ? (
            <PrimaryActionButton onClick={handleLogin} isLoading={isLoading}>Login</PrimaryActionButton>
          ) : (
            <InactiveActionButton>Login</InactiveActionButton>
          )}
        </div>
      </div>
      {loginFailed && (
        <div className={style.notification}>
          <DefaultBodyText fontSize="16px" isBolded={true} color="orangeRed">
            login failed, please try again.
          </DefaultBodyText>
        </div>
      )}
    </div>
  );
};

export default withRouter(Login);
