import { createUseStyles } from "react-jss";
import { colorPalette } from "../../../../../shared/theme";

interface StyleProps {
  selected: boolean;
  position: 'left'|'right'
}

export const resumeReportOptionButtonStylesFactory = createUseStyles({
  button: {
    position: 'absolute !important',
    clip: 'rect(0, 0, 0, 0)',
    height: '1px',
    width: '1px',
    border: 0,
    overflow: 'hidden',
    '&:focus + label':{
    // // /* Adds a white border around the button, along with a blue glow. The white and blue have a color contrast ratio of at least 3:1, which means this will work against any background color. */
    // boxShadow: '0 0 0 2px #ffffff, 0 0 3px 5px #005FCC',
    // //  /* NOTE: box-shadow is invisible in Windows high-contrast mode, so we need to add a transparent outline, which actually does show up in high-contrast mode. */
    // outline: '2px dotted transparent',
    // outlineOffset: '-5px',
    outline: 'none',
    transForm: 'translateX(-2%)',
    textDecoration: 'underline',
    },
    '&:checked + label': {
      backgroundColor: colorPalette.lavender,
    }
  },
  label: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'center',
    height: '70px',
    // backgroundColor: (props: StyleProps) => props.selected ? colorPalette.lavender : colorPalette.white,
    backgroundColor: colorPalette.white,
    border: `1px solid ${colorPalette.grape}`,
    'border-radius': '50px',
    'box-sizing': 'border-box',
    marginLeft: (props: StyleProps) => props.position === 'right' ? 'auto' : 'none',
    padding: '0 10%',
    margin: 'unset',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
  },

})
