export const ROUTES = {
  HOME: '/home',
  ONBOARDING: '/onboarding',
  RESOURCES: '/resources',
  REPORT: '/report',
  LOGIN: '/login',
  THANK_YOU: '/thank-you'
}

export const REPORT_ROUTES = {
  DASHBOARD: '/report/dashboard',
  VICTIM_SECTION: '/report/victim-section',
  DATE_AND_LOCATION_SECTION: '/report/date-and-location-section',
  VEHICLE_SECTION: '/report/vehicle-section',
  SUSPECT_SECTION: '/report/suspect-section',
  EVENTS_SECTION: '/report/events-section'
}

export const CHECKBOX_YES_NO_I_DONT_RECALL_OPTIONS = ["Yes", "No", "I don't recall"]

export const CHECKBOX_YES_NO_OPTIONS = ["Yes", "No"]

export const CHECKBOX_YES_OPTION = 'Yes'
export const CHECKBOX_NO_OPTION = 'No'
export const CHECKBOX_I_DONT_RECALL_OPTION = "I don't recall"
