import React from 'react';
import { homeContentProvider } from '../../contents/home.content';
import { DefaultBodyText } from '../../../shared/components';
import { Content } from '../../../shared/models/content.model';

const content = homeContentProvider();

const HomeDescription = () => {
  return (

    <DefaultBodyText fontSize="1rem">
      {
        content.description.map((desc: Content, index: number) => {
          return (
            <span key={index}>
              {
                desc.isBolded ? (
                  <b>{desc.text}</b>
                ) : (
                  <>{desc.text}</>
                )
              }
            </span>
          );
        })
      }
    </DefaultBodyText>
  );
};

export default HomeDescription;
