import React from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import { SuspectForm } from '../../services/suspect-section-form-service';
import { useFormStyles } from '../../../shared/theme/form-styles';
import { suspectSection } from '../../models/suspect-section.model';
// components
import { DefaultBodyText } from '../../../shared/components';
import TextInputField from '../../../shared/components/text-input-field';
import TextAreaInputField from '../../../shared/components/text-area-input-field';
import { useAccentsLanguagesPhrasesController } from './accents-lanuages-phrases.controller';

const Grid = createGrid();
const model = suspectSection.accentsLanguageAndPhrases;

interface Props {
  form: SuspectForm
}

const AccentsLanguageAndPhrases: React.FunctionComponent<Props> = ({ form }) => {
  const formStyles = useFormStyles();

  const {
    initialAccentsLanguage,
    initialDistinctWordsPhrases,
    handleAccentsLanguageChange,
    handleDistinctWordsPhrasesChange
  } = useAccentsLanguagesPhrasesController(form);

  return (
    <div>
      <div className={formStyles.questionSectionTitle}>
        <DefaultBodyText isBolded={true}>{model.header}</DefaultBodyText>
      </div>
      <div className={formStyles.questionSectionFirstQuestion}>
        <label htmlFor='accent'>
          <DefaultBodyText
            color={form.hasBeenFilled.accentsLanguage ? 'grape' : 'navy'}>{model.distinctAccentsOrLanguage.label}</DefaultBodyText>
        </label>
        <div className={formStyles.questionSectionFirstQuestion}>
          <Grid container justify='center'>
            <Grid item flex={{ xs: 12, md: 10 }}>
              <TextInputField
                initialValue={initialAccentsLanguage}
                onChange={handleAccentsLanguageChange}
                hasBeenFilled={form.hasBeenFilled.accentsLanguage}
                id='accent'
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={formStyles.question}>
        <label htmlFor='distinctWordsPhrases'>
          <DefaultBodyText
            color={form.hasBeenFilled.distinctWordsPhrases ? 'grape' : 'navy'}>{model.distinctWordsOrPhrases.label}</DefaultBodyText>
        </label>
        <div className={formStyles.questionSectionFirstQuestion}>
          <Grid container justify='center'>
            <Grid item flex={{ xs: 12, md: 10 }}>
              <TextAreaInputField
                initialValue={initialDistinctWordsPhrases}
                onChange={handleDistinctWordsPhrasesChange}
                hasBeenFilled={form.hasBeenFilled.distinctWordsPhrases}
                id='distinctWordsPhrases'
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AccentsLanguageAndPhrases;
