import { useState } from 'react'
import hide from './assets/hide.png'
import show from './assets/show.png'

export function useAlertInputFieldController(initialType: 'password'|'' = 'password') {
  const [type, setType] = useState<string>(initialType)
  const [passwordImg, setPasswordImg] = useState<string>(show)
  const [passwordAlt, setPasswordAlt] = useState<string>('showPassword')
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const onHideShowPassword = () => {
    if (type === '') {
      setType('password')
      setPasswordImg(show)
      setPasswordAlt('showPassword')
      setIsHidden(true)
    }
    else {
      setType('')
      setPasswordImg(hide)
      setPasswordAlt('hidePassword')
      setIsHidden(false)
    }
  }

  return {
    onHideShowPassword,
    type,
    passwordImg,
    passwordAlt,
    isHidden
  }
}
