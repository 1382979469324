import { CheckboxesInputField, TextInputField, DropdownInputField } from "../../shared/models/question.model";
import { Content } from "../../shared/models/content.model";
import { CHECKBOX_YES_NO_I_DONT_RECALL_OPTIONS } from "../../constants";

export interface HappenedMoreThanOnceQuestionModel {
  didItHappenMoreThanOnce: CheckboxesInputField;
  YES_approximatelyWhenItHappened: TextInputField;
  YES_whenItHappenedMostRecentlyHeader: Content[];
  NO_whenItHappened: Content[];
  year: TextInputField;
  month: TextInputField;
  day: TextInputField;
  timeOfDay: DropdownInputField;
}

export const happenedMoreThanOnceQuestion: HappenedMoreThanOnceQuestionModel = {
  didItHappenMoreThanOnce: {
    label: 'Did this happen more than once?',
    options: CHECKBOX_YES_NO_I_DONT_RECALL_OPTIONS
  },
  YES_approximatelyWhenItHappened: {
    label: 'Do you recall approximately when it happened?',
    placeholder: '(eg. between Mar 1999 - Jun 2000)'
  },
  YES_whenItHappenedMostRecentlyHeader: [
    { isBolded: false, text: 'Do you recall when and where this happened' },
    { isBolded: true, text: 'most recently?' },
    { isBolded: false, text: 'Even approximate or partial information can help.' }
  ],
  NO_whenItHappened: [
    { isBolded: false, text: 'Do you recall when and where this happened?' },
    { isBolded: false, text: 'Even approximate or partial information can help.' }
  ],
  year: {
    label: 'Year', placeholder: 'YYYY'
  },
  month: {
    label: 'Month', placeholder: 'MM'
  },
  day: {
    label: 'Day', placeholder: 'DD'
  },
  timeOfDay: {
    label: 'Time of day',
    options: [
      { value: 'early morning 12-3am', displayText: 'early morning 12-3am'},
      { value: 'early morning 3-6am', displayText: 'early morning 3-6am'},
      { value: 'morning 6-9am', displayText: 'morning 6-9am'},
      { value: 'late morning 9-12pm', displayText: 'late morning 9-12pm'},
      { value: 'afternoon 12-3pm', displayText: 'afternoon 12-3pm'},
      { value: 'late afternoon 3-6pm', displayText: 'late afternoon 3-6pm'},
      { value: 'evening 6-9pm', displayText: 'evening 6-9pm'},
      { value: 'late evening 9-12pm', displayText: 'late evening 9-12pm'},
    ]
  }
}
