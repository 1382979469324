import { SectionLeaveAlertContent } from "./section-leave-alert.content";

export const sectionLeaveAlertContentEn: SectionLeaveAlertContent = {
  header: 'Leaving this section?',
  descriptionPart1: 'Seems like you’re trying to leave ',
  descriptionPart2: ' without saving some of the information you’ve entered in this section.',
  warning: 'Are you sure you want to leave without saving?',
  leaveSectionButtonLabel: 'leave section',
  cancelButtonLabel: 'cancel'
}
