import React from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import AnimateHeight from 'react-animate-height';
import { CHECKBOX_YES_OPTION } from '../../../constants';
import { useFormStyles } from '../../../shared/theme/form-styles';
import { vehicleSection } from '../../models/vehicle-section.model';
import { DefaultBodyText } from '../../../shared/components';
import CheckboxSelection from '../../../shared/components/checkbox-selection';
import VehicleWasInvolved from '../vehicle-was-involved/vehicle-was-involved';
import { useWasVehicleInvolvedQuestionController } from './was-vehicle-involved-question.controller';

const Grid = createGrid();
const model = vehicleSection.wasVehicleInvolved;

const WasVehicleInvolvedQuestion = () => {

  const styles = useFormStyles();

  const {
    showVehicleWasInvolvedQuestions,
    wasVehicleInvolved,
    handleWasVehicleInvolvedChange,
    wasVehicleInvolvedHasBeenFillled
  } = useWasVehicleInvolvedQuestionController();

  return (
    <div>
      <div id='vehicleInvolved'>
        <DefaultBodyText color={wasVehicleInvolvedHasBeenFillled ? 'grape' : 'navy'}>
          {model.vehicleInvolved.label}
        </DefaultBodyText>
      </div>
      <Grid container justify='center'>
        <Grid item flex={{xs: 12, md: 10}}>
          <div className={styles.conditionalQuestionContainer}>
            <CheckboxSelection
              options={model.vehicleInvolved.options}
              onChange={handleWasVehicleInvolvedChange}
              selected={wasVehicleInvolved}
              hasBeenFilled={wasVehicleInvolvedHasBeenFillled}
              ariaDescribedby='vehicleInvolved'
            />
            {showVehicleWasInvolvedQuestions && (
              <AnimateHeight
                duration={800}
                height={wasVehicleInvolved === CHECKBOX_YES_OPTION ? 'auto' : 0}
              >
                <VehicleWasInvolved/>
              </AnimateHeight>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default WasVehicleInvolvedQuestion;
