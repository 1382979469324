import { Link } from '../../shared/models/link.model'
import { Content } from '../../shared/models/content.model'
import { homeContentEn } from './home.content-en'

export interface FooterContent {
  heading: string;
  resources: FooterResource[];
}

export interface HomeContent {
  heading: string;
  description: Content[];
  regionDropdownLabel: string;
  continueButtonLabel: string;
  resumeDescription: string;
  resumeReportButtonLabel: string;
  returnVisitHeader: string;
  reportCodePlaceholder: string;
  footer: FooterContent
}

export interface FooterResource extends Link {
  heading: string;
}

export const homeContentProvider = (): HomeContent => {
  return homeContentEn
}
