import { HomeContent } from './home.content';

export const homeContentEn: HomeContent = {
  heading: 'Every word you share counts',
  description: [
    {
      isBolded: false,
      text: 'Speak Out is an anonymous third party reporting tool for victims of sexual violence/assault who are 18 years and older. \n\n'
    },
    {
      isBolded: false,
      text: 'Currently this tool is only available in'
    },
    {
      isBolded: true,
      text: ' select cities in Ontario,'
    },
    {
      isBolded: false,
      text: ' with hopes to make it available in more cities in the future.'
    }
  ],
  regionDropdownLabel: 'Select the city where the assault occurred',
  continueButtonLabel: 'CONTINUE',
  resumeDescription: 'If you are returning to continue where you left off, you can resume your report by entering your report code.',
  resumeReportButtonLabel: 'RESUME REPORT',
  returnVisitHeader: 'To continue where you left off, please enter your report code:',
  reportCodePlaceholder: 'Code #',
  footer: {
    heading: 'Not sure where to start?',
    resources: [
      {
        heading: 'Resource Link #1',
        label: 'Learn more →',
        url: '/link1'
      },
      {
        heading: 'Resource Link #2',
        label: 'Learn more →',
        url: '/link2'
      },
      {
        heading: 'Resource Link #3',
        label: 'Learn more →',
        url: '/link3'
      },
      {
        heading: 'Resource Link #4',
        label: 'Learn more →',
        url: '/link4'
      },
    ]
  }
};
