import { createUseStyles } from "react-jss";
import { BpTheme, fontFamilies } from "../../../shared/theme";

export const firstTimeSaveAlertStylesFactory = createUseStyles((theme: BpTheme) => ( {
  closeImg: {
    width: 18,
    height: 18,
    verticalAlign: 'top',
    marginLeft: 10,
  },
  description: {
    paddingTop: '16px'
  },
  footNote: {
    textAlign: 'start'
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: '32px',
    paddingBottom: '50px',
    '&>button:first-child': {
      marginBottom: 16
    }
  },
  supportBlock: {
    paddingTop: '32px',
  },
  notNowButton: {
    color: theme.colorPalette.grape,
    fontSize: '0.875rem',
    fontFamily: [theme.fontFamilies.AvenirBlack, theme.fontFamilies.Avenir, fontFamilies.SansSerif],
    fontWeight: 'bold',
    letterSpacing: '1px',
    textAlign: 'right',
    backgroundColor: 'transparent',
    border: 'none',
    padding: 10,
    transition: 'transform .2s ease',
    '&:hover': {
      transform: 'translateX(-1.5%)'
    },
    '&:focus': {
      transform: 'translateX(-2%)',
      outline: 'none',
      textDecoration: 'underline',
    },
  },
  scrollBanner: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(to top, ${theme.colorPalette.lavender}, rgba(247, 245, 255, 0.76))`,
    padding: 24,
    transition: 'opacity 0.3s',
    textAlign: 'center',
    opacity: (props: { displayScrollBanner: boolean }) => props.displayScrollBanner ? 1 : 0,
    [theme.mediaQueries.tabletAndAbove]: {
      display: 'none'
    }
  },
  buttonLink: {
    ...theme.fontStylesTablet.buttonLink,
    color: theme.colorPalette.navy,
    textDecoration: 'underline',
    fontWeight: 'bold',
    background: 'none',
    border: 'none',
    textTransform: 'uppercase'
  }
} ));
