import { Link } from '../../shared/models/link.model'
import { Button } from '../../shared/models/button.model'
import { InfoBlock } from '../../shared/models/info-block.model'

// assets
import subhead1Icon from '../../assets/dashboard-subhead1-icon.png'
import subhead2Icon from '../../assets/dashboard-subhead2-icon.png'
import subhead3Icon from '../../assets/dashboard-subhead3-icon.png'

// model
import { Content } from '../../shared/models/content.model'

export interface Dashboard {
  impactStatement: string;
  subheads: Subhead[];
  returnCallOut?: InfoBlock;
  supportBlock1: ResourceCard;
  supportBlock2: ReportCodeCard;
}

export interface Subhead {
  icon: string;
  heading: string;
  description: Content[];
}

export interface SupportBlock {
  heading: string;
  description: string;
}

interface ResourceCard extends SupportBlock {
  seeAllLink: Link
}

interface ReportCodeCard extends SupportBlock {
  copyCodeButton: Button;
  printCodeButton: Button;
}

export const dashboard: Dashboard = {
  impactStatement: 'Any information you provide is helpful',
  subheads: [
    {
      icon: subhead1Icon,
      heading: 'Get started',
      //description: 'This report is broken into 5 sections, you may start with any one. &lt;b&gt;No fields are mandatory&lt;b&gt; and you can always start another section at a later time.'
      description: [
        {
          isBolded: false,
          text: 'This report is broken into 5 sections, you may start with any one. '
        },
        {
          isBolded: true,
          text: 'No fields are mandatory '
        },
        {
          isBolded: false,
          text: 'and you can always start another section at a later time.'
        },
      ]
    },
    {
      icon: subhead2Icon,
      heading: 'Complete at your pace',
      // description: 'Information entered will be securely saved. You can always pause then return to your report later using **your report code**.',
      description: [
        {
          isBolded: false,
          text: 'Information entered will be securely saved. You can always pause then return to your report later using '
        },
        {
          isBolded: true,
          text: 'your report code.'
        }
      ]
    },
    {
      icon: subhead3Icon,
      heading: "Submit when you're ready",
      // description: 'When you have provided as much information as you would like to, you may submit your report at any time.'
      description: [
        {
          isBolded: false,
          text: 'When you have provided as much information as you would like to, you may submit your report at any time.'
        }
      ]
    },
  ],
  returnCallOut: {
    content: [
      {
        isBolded: true,
        text: 'Sections that contain information entered will appear in purple.'
      },
      {
        isBolded: false,
        text: 'For your privacy, previously entered details will be hidden but you can add more information to these questions again — new answers will be added to your existing report.'
      },
    ]
  },
  supportBlock1: {
    heading: 'Here are some resources',
    description: 'to help as you go through the process of reporting an assault. We encourage you to reach out for support at any time.',
    seeAllLink: {
      label: 'SEE ALL →',
      url: '/'
    }
  },
  supportBlock2: {
    heading: 'This is your report code.',
    description: 'You can pause at any time and start again later where you left off.',
    copyCodeButton: {
      label: 'COPY CODE'
    },
    printCodeButton: {
      label: 'PRINT CODE →'
    }
  }
}
