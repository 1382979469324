import React from 'react';
import { createUseStyles } from 'react-jss';

// theme
import { BpTheme, Color, fontFamilies } from '../theme';

interface StyleProps {
  borderColor: Color,
  backgroundColor: Color,
  fontColor: Color,
  isUnderlined: boolean,
  width: string,
  padding: string
}

interface StyleProps {
  inactive: boolean
}

const useStyles = createUseStyles((theme: BpTheme) => ({
  baseButton: {
    display: 'flex',
    justifyContent: 'center',
    background: (props: StyleProps) => theme.colorPalette[props.backgroundColor],
    border: (props: StyleProps) => props.borderColor === 'none' ? 'none' : `1px solid ${theme.colorPalette[props.borderColor]}`,
    borderRadius: '2rem',
    // boxSizing: 'border-box',
    color: (props: StyleProps) => theme.colorPalette[props.fontColor],
    fontSize: '14px',
    fontFamily: [theme.fontFamilies.AvenirBlack, theme.fontFamilies.Avenir, fontFamilies.SansSerif],
    fontWeight: 'bold',
    letterSpacing: '1px',
    padding: (props: StyleProps) => props.padding,
    textDecoration: (props: StyleProps) => props.isUnderlined ? 'underline' : 'none',
    transition: 'transform .2s ease',
    '&:hover': {
      cursor: (props: StyleProps) => props.inactive ? 'default' : 'pointer',
      transform: (props: StyleProps) => props.inactive ? 'none' : 'translateX(-1.5%)'
    },
    '&:focus': {
      // /* Adds a white border around the button, along with a blue glow. The white and blue have a color contrast ratio of at least 3:1, which means this will work against any background color. */
      // boxShadow: '0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9',
      // /* NOTE: box-shadow is invisible in Windows high-contrast mode, so we need to add a transparent outline, which actually does show up in high-contrast mode. */
      // outline: '2px dotted transparent',
      // outlineOffset: '2px',
      transform: 'translateX(-2%)',
      outline: 'none',
      opacity: 0.9,
    },
    [theme.mediaQueries.mobileOnly]: {
      width: (props: StyleProps) => props.width || '100%'
    },
    [theme.mediaQueries.tabletAndAbove]: {
      width: (props: StyleProps) => props.width || 'fit-content'
    }
  },
}));

interface BaseButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  borderColor: Color,
  backgroundColor: Color,
  fontColor: Color,
  isUnderlined?: boolean,
  width?: string,
  padding?: string,
  inactive?: boolean,
  onClick?: () => void,
  children: React.ReactNode
}

export const BaseButton: React.FC<BaseButtonProps> = (props: BaseButtonProps) => {
  const {
    className,
    children,
    borderColor,
    backgroundColor,
    fontColor,
    isUnderlined,
    width,
    padding,
    inactive,
    onClick
  } = props;

  const { baseButton } = useStyles({
    borderColor,
    backgroundColor,
    fontColor,
    isUnderlined,
    width,
    padding,
    inactive
  });

  const classNames = [baseButton];
  if (className) {
    classNames.push(className);
  }
  return (
    <button className={classNames.join(' ')} onClick={onClick} aria-disabled={inactive}>
      {children}
    </button>
  );
};

BaseButton.defaultProps = {
  isUnderlined: false,
  padding: '15px 20px',
  inactive: false
};

export default BaseButton;
