export interface HelplineResourcesLinkContent {
  readonly label: string
}

const HelplineResourcesLinkContentEn: HelplineResourcesLinkContent ={
  label: 'Helpline and other resources'
}

export const helplineResourcesLinkContentProvider = (): HelplineResourcesLinkContent => {
  return HelplineResourcesLinkContentEn
}
