import { createUseStyles } from "react-jss";
import { BpTheme } from '../../../shared/theme';

export const imageLabelStylesFactory = createUseStyles((theme: BpTheme) => ( {
  container: {
    paddingTop: 16,
    [theme.mediaQueries.tabletAndAbove]: {
      paddingTop: 22
    }
  },
  image: {
    height: 18
  }
} ));
