import React from 'react'

// components
import BaseButton from './base-button'

interface InactiveButtonProps {
  children: React.ReactNode,
  width?: string
}

const InactiveButton: React.FunctionComponent<InactiveButtonProps> = ({ children, width }) => {
  return (
    <BaseButton
      borderColor="brownGrey"
      backgroundColor="none"
      fontColor="brownGrey"
      width={width}
      inactive={true}
    >
      {children?.toString().toUpperCase()}
    </BaseButton>
  )
}

export default InactiveButton
