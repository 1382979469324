import { CreateSequenceOfEventsSectionRequestDto } from "../data-transfer-objects/create-sequence-of-events-section.dto";
import { CreateSequenceOfEventsSectionOptions } from "../interfaces/section-service.interface";

export function createSequenceOfEventsSectionRequestBody(
  minimumRequestBody: CreateSequenceOfEventsSectionRequestDto,
  data: CreateSequenceOfEventsSectionOptions
): CreateSequenceOfEventsSectionRequestDto {
  let requestBody = { ...minimumRequestBody }
  if (data.description !== undefined) {
    requestBody.description = {
      inputFieldName: 'description',
      inputFieldValue: data.description
    }
  }
  return requestBody
}
