import { Link } from '../../../shared/models/link.model'
import { Button } from '../../../shared/models/button.model'

export interface SideNav {
  heading: string;
  links: Link[];
  submitButton: Button;
  leaveSessionButton: Button;
}

export const sideNav: SideNav = {
  heading: 'My Report',
  links: [
    {
      label: 'DESCRIPTION OF VICTIM/SURVIVOR',
      url: '/'
    },
    {
      label: 'DESCRIPTION OF DATE AND LOCATION',
      url: '/'
    },
    {
      label: 'DESCRIPTION OF THE VEHICLE',
      url: '/'
    },
    {
      label: 'DESCRIPTION OF THE SUSPECT/OFFENDER',
      url: '/'
    },
    {
      label: 'DESCRIPTION OF WHAT HAPPENED',
      url: '/'
    },
  ],
  submitButton: {
    label: 'Submit report'
  },
  leaveSessionButton: {
    label: 'Leave session'
  }
}
