import { CreateDateAndLocationSectionDto } from "../data-transfer-objects/create-date-and-location-section.dto";
import { CreateDateAndLocationSectionOptions } from "../interfaces/section-service.interface";

export function createDateAndLocationSectionRequestBody(
  minimumRequestBody: CreateDateAndLocationSectionDto,
  data: CreateDateAndLocationSectionOptions
): CreateDateAndLocationSectionDto {
  let requestBody = { ...minimumRequestBody }
  if (data.multipleOccurrencesRecall !== undefined) {
    requestBody.dateSection.multipleOccurrencesRecall = {
      inputFieldName: 'multipleOccurrencesRecall',
      inputFieldValue: data.multipleOccurrencesRecall
    }
  }
  if (data.approximateDate !== undefined) {
    requestBody.dateSection.approximateDate = {
      inputFieldName: 'approximateDate',
      inputFieldValue: data.approximateDate
    }
  }
  if (data.year !== undefined) {
    requestBody.dateSection.year = {
      inputFieldName: 'year',
      inputFieldValue: data.year
    }
  }
  if (data.month !== undefined) {
    requestBody.dateSection.month = {
      inputFieldName: 'month',
      inputFieldValue: data.month
    }
  }
  if (data.day !== undefined) {
    requestBody.dateSection.day = {
      inputFieldName: 'day',
      inputFieldValue: data.day
    }
  }
  if (data.timeOfDay) {
    requestBody.dateSection.timeOfDay = {
      inputFieldName: 'timeOfDay',
      inputFieldValue: data.timeOfDay
    }
  }
  if (data.addressRecall !== undefined) {
    requestBody.locationSection.addressRecall = {
      inputFieldName: 'addressRecall',
      inputFieldValue: data.addressRecall
    }
  }
  if (data.address !== undefined) {
    requestBody.locationSection.address = {
      inputFieldName: 'address',
      inputFieldValue: data.address
    }
  }
  if (data.city !== undefined) {
    requestBody.locationSection.city = {
      inputFieldName: 'city',
      inputFieldValue: data.city
    }
  }
  if (data.provinceTerritory) {
    requestBody.locationSection.provinceTerritory = {
      inputFieldName: 'provinceTerritory',
      inputFieldValue: data.provinceTerritory
    }
  }
  if (data.distinctFeatures !== undefined) {
    requestBody.locationSection.distinctFeatures = {
      inputFieldName: 'locationDistinctFeatures',
      inputFieldValue: data.distinctFeatures
    }
  }
  return requestBody
}
