import { useState, useEffect } from "react"
import suspectSectionFormService, { SuspectForm } from "../../services/suspect-section-form-service"

export function useSuspectInformationFormController(formId: number) {
  const [form, setForm] = useState<SuspectForm|undefined>(undefined)

  // on mount, get the correct form and set it
  useEffect(() => {
    setForm(suspectSectionFormService.getFormById(formId))
  }, [formId])
  
  return {
    form
  }
}
