import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import formService from '../services/date-and-location-section-form-service';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { useFormStyles } from '../../shared/theme/form-styles';
import { dateAndLocationSection as model } from '../models/date-and-location-section.model';
import { REPORT_ROUTES } from '../../constants';
// components
import InfoBlock from '../../shared/components/info-block';
import { DefaultBodyText, Divider } from '../../shared/components'
import HappenedMoreThanOnceQuestion from './happened-more-than-once-question';
import FormCtaButtons from '../../shared/components/form-cta-buttons';
import KnowTheAddressQuestion from './know-the-address-question';
import DistinctFeaturesAboutLocationQuestion from './distinct-features-about-location';
import BackToDashboardButton from '../../shared/components/back-to-dashboard-button';
import ReportSectionHeader from "../../shared/components/report-section-header/report-section-header";

const reportService = ServiceLocator.getInstance().getReportService();
const analyticsService = ServiceLocator.getInstance().getAnalyticsService();

const DateAndLocationSection: React.FunctionComponent<RouteComponentProps> = ({history}) => {
  const [isSaveAndNextSectionLoading, setIsSaveAndNextSectionLoading] = useState<boolean>(false);
  const [isSaveAndCloseLoading, setIsSaveAndCloseLoading] = useState<boolean>(false);
  const [isReadyToSave, setIsReadyToSave] = useState<boolean>(false);

  const formStyles = useFormStyles();

  const dateAndLocationSectionData = reportService.dateAndLocationSectionData;

  useEffect(() => {
    analyticsService.viewPage('/report/date-and-location-section');
    const unsubscribe: Subject<void> = new Subject();

    formService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setIsReadyToSave(isReadyToSave);
    });

    formService.initialize();

    return () => {
      unsubscribe.next();
      unsubscribe.complete();
    };
  }, []);

  const handleSaveAndClose = async () => {
    analyticsService.logEvent(
      'save_report',
      {
        section: 'date_and_location'
      }
    );
    setIsSaveAndCloseLoading(true);
    try {
      await formService.submit();
      setIsSaveAndCloseLoading(false);
      history.push(REPORT_ROUTES.DASHBOARD);
    } catch (e) {
      setIsSaveAndCloseLoading(false);
    }
  };

  const handleSaveAndNextSection = async () => {
    setIsSaveAndNextSectionLoading(true);
    analyticsService.logEvent(
      'save_report',
      {
        section: 'date_and_location'
      }
    );
    try {
      await formService.submit();
      setIsSaveAndNextSectionLoading(false);
      history.push(REPORT_ROUTES.VEHICLE_SECTION);
    } catch (e) {
      setIsSaveAndNextSectionLoading(false);
    }
  };

  return (
    <div className={formStyles.page}>
      <div className={formStyles.backButton}>
        <BackToDashboardButton leavingWhileDataIsUnsaved={isReadyToSave}/>
      </div>

      <ReportSectionHeader headerName={model.sectionHeader}></ReportSectionHeader>

      <div className={formStyles.infoBlock}>
        <InfoBlock>
          <DefaultBodyText>{model.description}</DefaultBodyText>
          {
            dateAndLocationSectionData?.showReturnCallOut && (
              <>
                <br/>
                <DefaultBodyText>
                  <b
                    style={{display: 'contents'}}>{reportService.hasReportPassword ? model.returnCallOutWithPassword[0].text : model.returnCallOutWithoutPassword[0].text}</b>
                  <p
                    style={{display: 'contents'}}>{reportService.hasReportPassword ? model.returnCallOutWithPassword[1].text : model.returnCallOutWithoutPassword[1].text}</p>
                </DefaultBodyText>
              </>
            )
          }
        </InfoBlock>
      </div>
      <div className={formStyles.divider}>
        <Divider width="100px" thickness="medium"/>
      </div>
      <div className={formStyles.question}>
        <HappenedMoreThanOnceQuestion/>
      </div>
      <div className={formStyles.question}>
        <KnowTheAddressQuestion/>
      </div>
      <div className={formStyles.question}>
        <DistinctFeaturesAboutLocationQuestion/>
      </div>
      <div className={formStyles.buttonsContainer}>
        <FormCtaButtons
          isReadyToSave={isReadyToSave}
          onSaveAndClose={handleSaveAndClose}
          isSaveAndCloseLoading={isSaveAndCloseLoading}
          onSaveAndNextSection={handleSaveAndNextSection}
          isSaveAndNextSectionLoading={isSaveAndNextSectionLoading}
        />
      </div>
    </div>
  );
};

export default withRouter(DateAndLocationSection);
