import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  withRouter
} from "react-router-dom";
import { createUseStyles } from 'react-jss';
import { REPORT_ROUTES } from '../constants';
import Dashboard from '../dashboard/components/dashboard-page';
import VictimSection from '../victim-section/victim-section';
import DateAndLocationSection from '../date-and-location-section/components/date-and-location-section';
import VehicleSection from '../vehicle-section/components/vehicle-section';
import SuspectSection from '../suspect-section/components/suspect-section';
import EventsSection from '../sequence-of-events-section/sequence-of-events-section';
import SnackBar from '../snackbar/components/snackbar';
import { BpTheme } from '../shared/theme';
import Background from '../shared/components/background';
import backgroundImage from '../assets/background-report.png';

const useStyles = createUseStyles((theme: BpTheme) => ( {
  reportWrapper: {
    paddingBottom: 32,
    [theme.mediaQueries.tabletAndAbove]: {
      maxWidth: theme.breakpoints.lg
    }
  }
} ));

const ReportContainerBody: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  useEffect(() => {
    window.onpopstate = () => {
      alert('Going back will cause any unsaved data to be removed.');
    };
    return () => {
      window.onpopstate = null;
    };
  }, [history]);

  return (
    <Background backgroundImage={backgroundImage}>
      <div className={classes.reportWrapper}>
        <Switch>
          <Route path={REPORT_ROUTES.DASHBOARD}>
            <Dashboard/>
          </Route>
          <Route path={REPORT_ROUTES.VICTIM_SECTION}>
            <VictimSection/>
          </Route>
          <Route path={REPORT_ROUTES.DATE_AND_LOCATION_SECTION}>
            <DateAndLocationSection/>
          </Route>
          <Route path={REPORT_ROUTES.VEHICLE_SECTION}>
            <VehicleSection/>
          </Route>
          <Route path={REPORT_ROUTES.SUSPECT_SECTION}>
            <SuspectSection/>
          </Route>
          <Route path={REPORT_ROUTES.EVENTS_SECTION}>
            <EventsSection/>
          </Route>
          <Route path="*">
            <Redirect to={REPORT_ROUTES.DASHBOARD}/>
          </Route>
        </Switch>
      </div>
      <SnackBar/>
    </Background>
  );
};

export default withRouter(ReportContainerBody);
