import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

import { onboarding } from '../models/onboarding.model';
import ServiceLocator from '../../shared/service-locator/service-locator';

// components
import {
  ImpactStatementHeader,
  DefaultHeader,
  DefaultBodyText,
  Divider,
  BrandedContainer,
  Content
} from '../../shared/components';
import ConsentCheckbox from './consent-checkbox';
import GetStartedButton from './onboarding-get-started-button';
import NotificationContainer from '../../notification/components/notification-container';
import ResourcesBanner from '../../shared/components/resources-banner';

const reportService = ServiceLocator.getInstance().getReportService()
const analyticsService = ServiceLocator.getInstance().getAnalyticsService()

const useStyles = createUseStyles({
  defaultBodyText: {
    marginTop: '16px'
  },
  divider: {
    marginTop: '16px'
  },
  defaultHeader: {
    marginTop: '24px'
  },
  inputWrapper: {
    display: 'flex'
  },
  consentInput: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column'
  },
  consentInputLabel: {
    marginLeft: '15px',
    display: 'flex',
  },
  primaryActionButton: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '50px'
  }
});

const OnboardingPage = () => {
  const [reporterTypes, setReporterTypes] = useState<string[]>([]);
  const [anonConsentChecked, setAnonConsentChecked] = useState<boolean>(false);
  const [ageConsentChecked, setAgeConsentChecked] = useState<boolean>(false);
  const [reportIsBeingCreated, setReportIsBeingCreated] = useState<boolean>(false);

  const layout = useStyles();

  useEffect(() => {
    analyticsService.viewPage('/onboarding');
  }, []);

  useEffect(() => {
    const getReporterTypes = async () => {
      const reporterTypes = await reportService.getReporterTypes()
      setReporterTypes(reporterTypes)
    }
    getReporterTypes()
  }, []);

  const handleAnonConsentChange = (checked: boolean) => {
    if (reportIsBeingCreated) return;
    setAnonConsentChecked(checked);
  };

  const handleAgeConsentChange = (checked: boolean) => {
    if (reportIsBeingCreated) return;
    setAgeConsentChecked(checked);
  };

  const handleStartLoading = () => {
    setReportIsBeingCreated(true);
  };

  return (
    <>
      <NotificationContainer width="100%"/>
      <BrandedContainer>
        <ImpactStatementHeader size="medium">
          {onboarding.heading}
        </ImpactStatementHeader>
        <div className={layout.defaultBodyText}>
          <DefaultBodyText fontSize="1rem">
            <Content content={onboarding.headingDescription}/>
          </DefaultBodyText>
        </div>
        <div className={layout.divider}>
          <Divider width="12%" thickness="medium"/>
        </div>
        <div className={layout.defaultHeader}>
          <DefaultHeader>
            {onboarding.subhead1}
          </DefaultHeader>
        </div>
        <div className={layout.defaultBodyText}>
          <DefaultBodyText>
            {onboarding.subhead1Description}
          </DefaultBodyText>
        </div>
        <div className={layout.defaultHeader}>
          <DefaultHeader>
            {onboarding.subhead2}
          </DefaultHeader>
        </div>
        <div className={layout.defaultBodyText}>
          <DefaultBodyText>
            {onboarding.subhead2Description}
          </DefaultBodyText>
        </div>
        <div className={layout.consentInput}>
          <div className={layout.inputWrapper}>
            <ConsentCheckbox onChange={handleAnonConsentChange} locked={reportIsBeingCreated} id="anonConsent" />
            <label className={layout.consentInputLabel} htmlFor="anonConsent">
              <DefaultBodyText>
                {onboarding.anonymityStatement}
              </DefaultBodyText>
            </label>
          </div>
          <div className={layout.inputWrapper}>
            <ConsentCheckbox onChange={handleAgeConsentChange} locked={reportIsBeingCreated} id="ageConsent" />
            <label className={layout.consentInputLabel} htmlFor="ageConsent">
              <DefaultBodyText>
                {onboarding.ageStatement}
              </DefaultBodyText>
            </label>
          </div>
        </div>
        <div className={layout.primaryActionButton}>
          <GetStartedButton
            reporterType={reporterTypes[0]}
            consentChecked={ageConsentChecked && anonConsentChecked}
            onStartLoading={handleStartLoading}
          />
        </div>

      </BrandedContainer>
      <ResourcesBanner/>
    </>
  );
};

export default OnboardingPage;
