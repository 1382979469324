import { createUseStyles } from "react-jss";

export const sectionLeaveAlertStyleFactory = createUseStyles({
  description: {
    paddingTop: '32px'
  },
  ctaButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '120px',
    alignItems: 'flex-end',
    paddingTop: '32px'
  },
  supportBlockDescription: {
    marginTop: '10px'
  },
  returnCallOut: {
    marginTop: '30px'
  },
  supportBlock: {
    paddingTop: '32px',
  },
  reportCode: {
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 0'
  }
})
