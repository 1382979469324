import ServiceLocator from '../../../shared/service-locator/service-locator';
import alertService from '../../services/alert-service';
import notificationService from '../../../notification/services/notification-service';
import snackBarService from '../../../snackbar/services/snackbar-service';
import { useState } from 'react';

const reportService = ServiceLocator.getInstance().getReportService();
const analyticsService = ServiceLocator.getInstance().getAnalyticsService();

export function useFirstTimeSaveAlertController() {
  const [isSaveWithoutPasswordExecuting, setIsSaveWithoutPasswordExecuting] = useState<boolean>(false);

  const onNotNowButtonClicked = () => {
    analyticsService.logEvent(
      'first_time_save_alert_click',
      {
        button: 'not_now'
      }
    );
    alertService.clearAlert();
  };

  const handleCreatePassword = async () => {
    analyticsService.logEvent(
      'first_time_save_alert_click',
      {
        button: 'create_password'
      }
    );
    alertService.clearAlert();
    alertService.alertCreateReportPassword();
  };

  const handleDoNotCreatePassword = async () => {
    analyticsService.logEvent(
      'first_time_save_alert_click',
      {
        button: 'no_password'
      }
    );
    setIsSaveWithoutPasswordExecuting(true);

    try {
      await reportService.saveWithoutPassword();
      await reportService.resumeReport();
    } catch (error) {
      notificationService.notifyServerError();
    }

    setIsSaveWithoutPasswordExecuting(false);
    snackBarService.displaySaveWithoutPasswordSnackbar();
    alertService.clearAlert();
  };

  return {
    handleCreatePassword,
    handleDoNotCreatePassword,
    onNotNowButtonClicked,
    isSaveWithoutPasswordExecuting
  };
}
