import React from 'react';
import { createGrid } from '@nascentdigital/react-lattice';
import formService from '../services/date-and-location-section-form-service';
import { useFormStyles } from '../../shared/theme/form-styles';
import { dateAndLocationSection } from '../models/date-and-location-section.model';
import { DefaultBodyText, Divider } from '../../shared/components';
import TextInputField from '../../shared/components/text-input-field';
import Dropdown from '../../shared/components/dropdown';
import { numberValidator } from '../../shared/validators/number-validator';
import ServiceLocator from '../../shared/service-locator/service-locator';
import { breakpoints } from '../../shared/theme';

const Grid = createGrid({ breakpoints });
const model = dateAndLocationSection.happenedMoreThanOnce;
const reportService = ServiceLocator.getInstance().getReportService();

const DidHappenMoreThanOnce = () => {
  const formStyles = useFormStyles();

  return (
    <div>
      <div className={formStyles.question}>
        <TextInputField
          initialValue={reportService.dateAndLocationSectionData?.approximateDate?.inputFieldValue}
          hasBeenFilled={!!reportService.dateAndLocationSectionData?.approximateDate}
          label={model.YES_approximatelyWhenItHappened.label}
          placeholder={model.YES_approximatelyWhenItHappened.placeholder}
          onChange={(approximateDate) => formService.setApproximateDate(approximateDate)}
          id='approximateDate'
        />
      </div>
      <div className={formStyles.question}>
        <DefaultBodyText>
          <p style={{ display: 'contents' }}>{model.YES_whenItHappenedMostRecentlyHeader[0].text} </p>
          <b style={{ display: 'contents' }}>{model.YES_whenItHappenedMostRecentlyHeader[1].text} </b>
          <br/>
          <p style={{ display: 'contents' }}>{model.YES_whenItHappenedMostRecentlyHeader[2].text}</p>
        </DefaultBodyText>
        <Grid container className={formStyles.textInputGroup}>
          <Grid item flex={{ xs: 3, md: 2 }}>
            <TextInputField
              initialValue={reportService.dateAndLocationSectionData?.year?.inputFieldValue}
              hasBeenFilled={!!reportService.dateAndLocationSectionData?.year}
              label={model.year.label}
              placeholder={model.year.placeholder}
              centerPlaceholder={true}
              onChange={(year) => formService.setYear(year)}
              validator={(value) => numberValidator(value, 4)}
              id='year'
            />
          </Grid>
          <Grid item flex={{ xs: 3, md: 2 }}>
            <TextInputField
              initialValue={reportService.dateAndLocationSectionData?.month?.inputFieldValue}
              hasBeenFilled={!!reportService.dateAndLocationSectionData?.month}
              label={model.month.label}
              placeholder={model.month.placeholder}
              centerPlaceholder={true}
              onChange={(month) => formService.setMonth(month)}
              validator={(value) => numberValidator(value, 2)}
              id='month'
            />
          </Grid>
          <Grid item flex={{ xs: 3, md: 2 }}>
            <TextInputField
              initialValue={reportService.dateAndLocationSectionData?.day?.inputFieldValue}
              hasBeenFilled={!!reportService.dateAndLocationSectionData?.day}
              label={model.day.label}
              placeholder={model.day.placeholder}
              centerPlaceholder={true}
              onChange={(day) => formService.setDay(day)}
              validator={(value) => numberValidator(value, 2)}
              id='day'
            />
          </Grid>
        </Grid>
        <Grid container className={formStyles.questionGroup}>
          <Grid item flex={{ xs: 12, md: 8 }}>
            <Dropdown
              initialValue={reportService.dateAndLocationSectionData?.timeOfDay?.inputFieldValue}
              hasBeenFilled={!!reportService.dateAndLocationSectionData?.timeOfDay}
              label={model.timeOfDay.label}
              options={model.timeOfDay.options}
              onChange={(timeOfDay) => formService.setTimeOfDay(timeOfDay)}
              id='timeOfDay'
            />
          </Grid>
        </Grid>
      </div>
      <div className={formStyles.divider}>
        <Divider width="100%" thickness="thin"/>
      </div>
    </div>
  );
};

export default DidHappenMoreThanOnce;
