import ReactGA from 'react-ga4';
import { AnalyticsService } from './analytics-service.interface';

export class GoogleAnalyticsService implements AnalyticsService {
  constructor(trackingId: string) {
    ReactGA.initialize(trackingId);
    ReactGA.set({ anonymizeIp: true });
    ReactGA.gtag('config', trackingId, {
      send_page_view: false
    })
  }

  public logEvent(eventName: string, data: Record<string, string | number | boolean> = {}) {
    ReactGA.gtag('event', eventName, data);
  }

  public viewPage(pathname: string) {
    ReactGA.gtag('event', 'page_view', {
      page_location: pathname
    });
  }
}
