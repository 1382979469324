import React, { useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { useHistory } from 'react-router';
// components
import TimeoutAlert from './timeout-alert';
import SectionLeaveAlert from './section-leave-alert/section-leave-alert';
import LogoutAlert from './logout-alert';
import LeaveFormAlert from './leave-form-alert/leave-form-alert';
import FirstTimeSaveAlert from './first-time-save-alert/first-time-save-alert';
import CreateReportPasswordAlert from './create-report-password-alert/create-report-password-alert';
// constants
import { REPORT_ROUTES } from '../../constants';
// service
import alertService, { ALERT, ALERT_TYPE } from '../services/alert-service';
import ServiceLocator from '../../shared/service-locator/service-locator';

import SubmitFormAlert from './submit-form-alert/submit-form-alert';
import ResumeReportAlert from './resume-report-alert/resume-report-alert';
import timeoutService from '../services/timeout-service';

const reportService = ServiceLocator.getInstance().getReportService();

const AlertContainer = () => {
  const history = useHistory();
  const [alertState, setAlertState] = useState<ALERT>({ type: ALERT_TYPE.NONE });

  // on mount, subscribe to the alert service
  useEffect(() => {
    const unsubscribe: Subject<void> = new Subject();
    alertService.alert.pipe(takeUntil(unsubscribe)).subscribe(({ type, options }) => {
      setAlertState({ type, options });
    });
    return () => {
      unsubscribe.next();
      unsubscribe.complete();
    };
  }, []);

  useEffect(() => {
    if (alertState.type === ALERT_TYPE.SESSION_EXPIRED) {
      reportService.clear();
      alertService.clearAlert();
      timeoutService.clear();
      history.push('/');
    }
  }, [alertState.type]);

  switch (alertState.type as ALERT_TYPE) {
    case ALERT_TYPE.LEAVE_FORM:
      return <LeaveFormAlert/>;
    case ALERT_TYPE.SUBMIT_FORM:
      return <SubmitFormAlert/>;
    case ALERT_TYPE.SESSION_EXPIRE_WARNING:
      return <TimeoutAlert/>;
    case ALERT_TYPE.LEAVING_SECTION:
      return <SectionLeaveAlert nextRoute={alertState.options?.nextRoute || REPORT_ROUTES.DASHBOARD}/>;
    case ALERT_TYPE.FIRST_TIME_SAVE:
      return <FirstTimeSaveAlert/>;
    case ALERT_TYPE.CREATE_REPORT_PASSWORD:
      return <CreateReportPasswordAlert/>;
    case ALERT_TYPE.RESUME_REPORT:
      return <ResumeReportAlert/>;
    default:
      return null;
  }
};

export default AlertContainer;
