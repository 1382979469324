import { CreateReportPasswordAlertContent } from './create-report-password-alert.content'

export const createReportPasswordAlertContentEn: CreateReportPasswordAlertContent = {
  header: 'Create a password',

  descriptionPart1: 'For your report code ',
  descriptionPart2: ', please create an associated password below.',

  passwordRequirementsHeader: 'Your password should contain:',
  passwordRequirements: [
    'minimum 6 characters',
    'with at least 1 uppercase letter',
    '1 lowercase letter',
    '1 number'
  ],

  passwordLabel: 'Password',
  confirmPasswordLabel: 'Confirm password',

  passwordErrorMessage: 'Please check password requirements and try again.',
  confirmPasswordErrorMessage: 'Confirm password does not match password.',

  backButtonLabel: 'BACK',
  createButtonLabel: 'CREATE'
}
