import React from 'react';
import AnimateHeight from 'react-animate-height';
import { useFormStyles } from '../../../shared/theme/form-styles';
import closePurple from '../../../assets/close-purple.png';
import downArrow from '../../../assets/downArrow.png';
import upArrow from '../../../assets/upArrow.png';
import { DefaultBodyText } from '../../../shared/components';
import SuspectIdentity from '../suspect-identity/suspect-identity';
import PhysicalFeatures from '../physical-features/physical-features';
import AccentsLanguageAndPhrases from '../accents-languages-phrases/accents-languages-phrases';
import { useSuspectInformationFormController } from './suspect-information-form.controller';
import { suspectInformationFormStylesFactory } from './suspect-information-form.styles';

const useStyles = suspectInformationFormStylesFactory;

const SuspectInformationForm: React.FunctionComponent<{
  formNumber: number;
  formId: number;
  deleteForm: (value: number) => void;
  isOpen: boolean;
  toggleView: (formId: number) => void;
}> = ({formNumber, formId, deleteForm, isOpen, toggleView}) => {

  const formStyles = useFormStyles();
  const styles = useStyles({isOpen});

  const {form} = useSuspectInformationFormController(formId);

  if (!form) {
    return null;
  }

  return (
    <div>
      <div
        id={`suspect-form-${formNumber}`}
        onClick={() => toggleView(formId)}
        className={styles.suspectInfoFormContainer}
        onKeyPress={() => toggleView(formId)}
        aria-expanded={isOpen}
        role="button"
        tabIndex={0}
        data-a11y-toggle={`target${formNumber + 1}`}
      >
        <DefaultBodyText isBolded={true} fontSize="1rem">Suspect {formNumber + 1}</DefaultBodyText>
        <div>
          {isOpen ? <img src={upArrow} style={{width: '30px'}} alt="toggle view" aria-hidden="true"/> :
            <img src={downArrow} style={{width: '30px'}} alt="toggle view" aria-hidden="true"/>}
        </div>
      </div>

      <AnimateHeight duration={800} height={isOpen ? 'auto' : 0}>
        <div className={styles.suspectInfoForm} id="target">
          {formNumber > 0 && !form.hasBeenPreviouslySaved() && (
            <div className={styles.removeSuspect}>
              <button
                className={styles.deleteFormButton}
                onClick={() => {
                  deleteForm(formId);
                }}
              >
                <DefaultBodyText color="grape" isBolded={true} letterSpacing="1.14px">REMOVE</DefaultBodyText>
                <img src={closePurple} alt="close icon" className={styles.deleteFormButtonIcon} aria-hidden="true"/>
              </button>
            </div>
          )}
          <div className={formStyles.question}>
            <SuspectIdentity form={form}/>
          </div>
          <div className={formStyles.question}>
            <PhysicalFeatures form={form}/>
          </div>
          <div className={formStyles.question}>
            <AccentsLanguageAndPhrases form={form}/>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default SuspectInformationForm;
