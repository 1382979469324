import { Observable, BehaviorSubject, Subject } from "rxjs";
import cookie from 'react-cookies'
import { HttpClient } from "../../shared/clients/http-client.interface";
import { AuthService, LoginStatus } from "./auth-service.interface";

export const AUTH_TOKEN_NAME = 'authToken'

class MainAuthService implements AuthService {
  private baseUrl: string;
  private httpClient: HttpClient;
  private authClient: HttpClient;
  private username: string = '';
  private password: string = '';
  private authToken: string = '';
  private _isReadyToLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isReadyToLogin: Observable<boolean> = this._isReadyToLogin.asObservable()
  private _isLoggedIn: Subject<boolean> = new Subject<boolean>()
  public isLoggedIn: Observable<boolean> = this._isLoggedIn.asObservable()

  public constructor(httpClient: HttpClient, authClient: HttpClient) {
    this.httpClient = httpClient;
    this.authClient = authClient;
    this.baseUrl = process.env.REACT_APP_AUTH_ENDPOINT!
  }

  private credentialsAreValid() {
    return this.username !== '' && this.password !== ''
  }

  private getAuthToken(): string {
    // fetch the auth token from the cookie jar
    const authToken = cookie.load(AUTH_TOKEN_NAME)
    return authToken
  }

  private async isTokenValid() {
    try {
      const response = await this.authClient.get(this.baseUrl + '/is-token-valid')
      const isValid = await response.json()
      return isValid
    } catch (e) {
      return false
    }
  }

  public async isSessionValid(): Promise<boolean> {
    const authToken = this.getAuthToken()
    if (!authToken) {
      return false
    }
    const isValid = await this.isTokenValid()
    return isValid
  }

  public async login(): Promise<LoginStatus> {
    // basic validation of username and password
    if (this.username === '' || this.password === '') {
      throw new Error('credentials are invalid')
    }
    try {
      const response = await this.httpClient.post(this.baseUrl + '/login', {
        body: JSON.stringify({
          username: this.username,
          password: this.password
        })
      })
      if (response.statusCode === 401) {
        return response
      }

      this.authToken = response.accessToken

      // and save it as a cookie
      cookie.save(AUTH_TOKEN_NAME, this.authToken, {})
      this._isLoggedIn.next(true)
      return response
    } catch (e) {
      throw new Error('something unexpected')
    }
  }

  public async logout() {
    try {
      const response = await this.authClient.post(this.baseUrl + '/logout')
      await response.json()

      // delete the auth token from the cookie jar
      cookie.remove(AUTH_TOKEN_NAME)
      this._isLoggedIn.next(false)
      return
    } catch (e) {
      return
    }
  }

  private updateIsReadyToLogin() {
    this._isReadyToLogin.next(this.credentialsAreValid())
  }

  public setUsername(username: string) {
    this.username = username;
    this.updateIsReadyToLogin()
  }

  public setPassword(password: string) {
    this.password = password;
    this.updateIsReadyToLogin()
  }
}

export default MainAuthService;
