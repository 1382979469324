import { BehaviorSubject, Observable } from 'rxjs'

// service
import ServiceLocator from '../shared/service-locator/service-locator'
import { SectionFormService } from '../shared/interfaces/section-form-service.interface';
import snackBarService from '../snackbar/services/snackbar-service';
import alertService from '../alerts/services/alert-service';
import { SectionFormBaseService } from '../shared/services/section-form-base-service/section-form-base.service';

const reportService = ServiceLocator.getInstance().getReportService()
const sectionService = ServiceLocator.getInstance().getSectionService()

class VictimSectionFormService extends SectionFormBaseService implements SectionFormService {
  private age?: string
  private gender: string = '';
  private victimRaceAppearance: string = '';

  private _isReadyToSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public isReadyToSave: Observable<boolean> = this._isReadyToSave.asObservable()

  private updateIsReadyToSaveFlag() {
    if (this.age || this.gender || this.victimRaceAppearance) {
      this._isReadyToSave.next(true)
    } else {
      this._isReadyToSave.next(false)
    }
  }

  initialize() {
    this.age = reportService.victimSectionSavedData?.age?.inputFieldValue
    this.gender = reportService.victimSectionSavedData?.gender?.inputFieldValue
    this.victimRaceAppearance = reportService.victimSectionSavedData?.victimRaceAppearance?.inputFieldValue
  }

  public setAge(age: string) {
    this.age =
      this.compare(age,
        reportService.victimSectionSavedData?.age?.inputFieldValue,
        reportService.hasReportPassword)

    this.updateIsReadyToSaveFlag()
  }

  public setGender(value: string) {
    this.gender = value;
    this.updateIsReadyToSaveFlag()
  }

  public setVictimRaceAppearance(value: string) {
    this.victimRaceAppearance = value;
    this.updateIsReadyToSaveFlag()
  }

  public clearForm() {
    this.age = ''
    this.gender = ''
    this.victimRaceAppearance = ''
    this.updateIsReadyToSaveFlag()
  }

  public isFormReadyToBeSaved(): boolean {
    return this._isReadyToSave.value
  }

  public async submit() {
    const reportCode = reportService.reportCode
    await sectionService.createVictimSection(reportCode, {
      age: this.age,
      gender: this.gender,
      victimRaceAppearance: this.victimRaceAppearance,
    })
    await reportService.resumeReport()
    snackBarService.displaySectionSavedSnackbar()
    alertService.alertFirstTimeSave()
    return
  }
}

const victimSectionFormService = new VictimSectionFormService()

export default victimSectionFormService
