import React from 'react'
export class PrintTemplate extends React.Component<{
  reportCode: string
}> {
  render() {
    return (
      <div style={{ padding: '24px'}}>
        <p style={{ fontSize: '32px' }}>{this.props.reportCode}</p>
      </div>
    )
  }
}
