import { Section } from '../../shared/models/section.model'
import { TextInputField, DropdownInputField, CheckboxesInputField } from '../../shared/models/question.model'

// model
import { returnCallOutWithPassword, returnCallOutWithoutPassword } from '../../shared/models/return-call-out.model'
import { CHECKBOX_YES_NO_OPTIONS } from '../../constants'
import { genderOptions } from "../../shared/models/gender-options";
import { raceAppearanceOptions } from "../../shared/models/race-appearance-options";

interface SuspectIdentity {
  header: string;
  knowThisPerson: CheckboxesInputField;
  YES_suspectNames: TextInputField;
  YES_howDidSuspectKnowYou: TextInputField;
  NO_didSuspectGiveTheirNames: TextInputField;
}

interface PhysicalFeatures {
  header: string;
  description: string;
  approximateAge: DropdownInputField;
  approximateHeight: DropdownInputField;
  raceAppearance: DropdownInputField;
  skinColour: DropdownInputField;
  hairColour: DropdownInputField;
  suspectGender: DropdownInputField;
  descriptionOfBuild: TextInputField;
  distinctFeatures: TextInputField;
}

interface AccentsLanguageAndPhrases {
  header: string;
  distinctAccentsOrLanguage: TextInputField;
  distinctWordsOrPhrases: TextInputField;
}

export interface SuspectSection extends Section {
  moreThanOneSuspect: CheckboxesInputField;
  suspectIdentity: SuspectIdentity;
  physicalFeatures: PhysicalFeatures;
  accentsLanguageAndPhrases: AccentsLanguageAndPhrases;
}

export const suspectSection: SuspectSection = {
  sectionHeader: 'Description of the Suspect/Offender',
  description: 'Descriptions of a suspect/offender help create a clearer image of what they look like. It can also potentially link previously unconnected cases if suspect/offender descriptions match.',
  returnCallOutWithPassword,
  returnCallOutWithoutPassword,
  moreThanOneSuspect: {
    label: 'Was there more than one suspect/offender?',
    options: CHECKBOX_YES_NO_OPTIONS
  },
  suspectIdentity: {
    header: 'Suspect/Offender identity',
    knowThisPerson: {
      label: 'Did you know this person?',
      options: CHECKBOX_YES_NO_OPTIONS
    },
    YES_suspectNames: { label: 'Would you be comfortable providing their name(s)?' },
    YES_howDidSuspectKnowYou: { label: 'How did the suspect(s)/offender(s) know you?' },
    NO_didSuspectGiveTheirNames: { label: 'Did the suspect(s)/offender(s) give you their name(s)?' }
  },
  physicalFeatures: {
    header: 'Physical features',
    description: 'Can you help describe the suspect/offender? Any information is helpful in identifying the type of individual.',
    approximateAge: {
      label: 'Approximate age',
      options: [
        { value: 'Younger than 18 years old', displayText: 'Younger than 18 years old' },
        { value: '18-25 yo', displayText: '18-25 yo' },
        { value: '25-30 yo', displayText: '25-30 yo' },
        { value: '30-35 yo', displayText: '30-35 yo' },
        { value: '35-40 yo', displayText: '35-40 yo' },
        { value: '40-45 yo', displayText: '40-45 yo' },
        { value: '45-50 yo', displayText: '45-50 yo' },
        { value: '50-55 yo', displayText: '50-55 yo' },
        { value: '55-60 yo', displayText: '55-60 yo' },
        { value: '60-65 yo', displayText: '60-65 yo' },
        { value: 'Older than 65 years old', displayText: 'Older than 65 years old' },
      ]
    },
    approximateHeight: {
      label: 'Approximate height',
      options: [
        { value: "Shorter than 5'ft / 152cm", displayText: "Shorter than 5'ft / 152cm" },
        { value: "5'0\"–5'3\" / 152–160cm", displayText: "5'0\"–5'3\" / 152–160cm" },
        { value: "5'3\"–5'6\" / 160–167cm", displayText: "5'3\"–5'6\" / 160–167cm" },
        { value: "5'6\"–5'9\" / 167–175cm", displayText: "5'6\"–5'9\" / 167–175cm" },
        { value: "5'9\"–6'0\" / 175–182cm", displayText: "5'9\"–6'0\" / 175–182cm" },
        { value: "6'0\"–6'3\" / 182–190cm", displayText: "6'0\"–6'3\" / 182–190cm" },
        { value: "Taller than 6'3ft / 190cm", displayText: "Taller than 6'3ft / 190cm" },
      ]
    },
    raceAppearance: {
      label: 'Race appearance',
      options: raceAppearanceOptions
    },
    skinColour: {
      label: 'Skin colour',
      options: [
        { value: 'White', displayText: 'White'},
        { value: 'Light brown', displayText: 'Light brown'},
        { value: 'Medium brown', displayText: 'Medium brown'},
        { value: 'Dark brown', displayText: 'Dark brown'},
        { value: 'Black', displayText: 'Black'},
      ]
    },
    hairColour: {
      label: 'Hair colour',
      options: [
        { value: 'Bald', displayText: 'Bald'},
        { value: 'Blonde', displayText: 'Blonde'},
        { value: 'Light brown', displayText: 'Light brown'},
        { value: 'Dark brown', displayText: 'Dark brown'},
        { value: 'Black', displayText: 'Black'},
        { value: 'Red', displayText: 'Red'},
        { value: 'Other', displayText: 'Other' },
      ]
    },
    suspectGender: {
      label: 'Gender',
      options: genderOptions
    },
    descriptionOfBuild: {
      label: 'How would you describe their build? (eg. stocky)'
    },
    distinctFeatures: {
      label: 'If you were able to get a look, would you be able to describe any distinct features about the suspect/offender? (eg. scars / tattoos / facial hair / odours)'
    }
  },
  accentsLanguageAndPhrases: {
    header: 'Accents, language, and phrases',
    distinctAccentsOrLanguage: {
      label: 'If this suspect/offender spoke to you, do you recall any distinct accents or language they used?'
    },
    distinctWordsOrPhrases: {
      label: 'Were there any distinct words or phrases this person used?'
    }
  }
}
