import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createGrid } from '@nascentdigital/react-lattice';
import { useFormStyles } from '../shared/theme/form-styles';
import { sequenceOfEventsSectionContentProvider } from './contents/sequence-of-events-section.content';
import { breakpoints } from '../shared/theme';
import FormCtaButtons from '../shared/components/form-cta-buttons';
import InfoBlock from '../shared/components/info-block';
import { DefaultBodyText, Divider } from '../shared/components'
import BackToDashboardButton from '../shared/components/back-to-dashboard-button';
import TextAreaInputField from '../shared/components/text-area-input-field';
import { sequenceOfEventsSectionStylesFactory } from './sequence-of-events-section.styles';
import { useSequenceOfEventsSectionController } from './sequence-of-events-section.controller';
import ReportSectionHeader from "../shared/components/report-section-header/report-section-header"

const Grid = createGrid({ breakpoints });
const useLayout = sequenceOfEventsSectionStylesFactory;
const content = sequenceOfEventsSectionContentProvider();

const SequenceOfEventsSection: React.FunctionComponent<RouteComponentProps> = () => {
  const formStyles = useFormStyles();
  const layout = useLayout();

  const {
    initialDescriptionValue,
    descriptionHasBeenFilled,
    isSaveAndCloseLoading,
    showReturnCallOut,
    isWithPassword,
    isReadyToSave,
    handleSaveAndClose,
    handleDescriptionChange
  } = useSequenceOfEventsSectionController();

  return (
    <div className={formStyles.page}>
      <div className={formStyles.backButton}>
        <BackToDashboardButton leavingWhileDataIsUnsaved={isReadyToSave}/>
      </div>

      <ReportSectionHeader headerName={content.sectionHeader}></ReportSectionHeader>

      <div className={formStyles.infoBlock}>
        <InfoBlock>
          <DefaultBodyText>{content.description}</DefaultBodyText>
          {showReturnCallOut && (
            <>
              <br/>
              <DefaultBodyText>
                <b
                  style={{ display: 'contents' }}>{isWithPassword ? content.returnCallOutWithPassword[0].text : content.returnCallOutWithoutPassword[0].text}</b>
                <p
                  style={{ display: 'contents' }}>{isWithPassword ? content.returnCallOutWithPassword[1].text : content.returnCallOutWithoutPassword[1].text}</p>
              </DefaultBodyText>
            </>
          )}
        </InfoBlock>
      </div>
      <div className={formStyles.divider}>
        <Divider width="100px" thickness="medium"/>
      </div>
      <div className={formStyles.question}>

        <label htmlFor='eventDescription' style={{ margin: 'unset' }}>
          <DefaultBodyText
            color={descriptionHasBeenFilled ? 'grape' : 'navy'}>{content.eventsDescription.label}</DefaultBodyText>
        </label>
        <div className={formStyles.questionSectionFirstQuestion}>
          <Grid container justify='center'>
            <Grid item flex={{ xs: 12, md: 10 }}>
              <TextAreaInputField
                initialValue={initialDescriptionValue}
                onChange={handleDescriptionChange}
                hasBeenFilled={descriptionHasBeenFilled}
                height="230px"
                id='eventDescription'
              />
              <div className={layout.assistanceBlock}>
                <InfoBlock backgroundColor="lightGrey">
                  <DefaultBodyText>{content.assistanceBlock.header}</DefaultBodyText>
                  <div className={layout.infoBlockHelpfulQuestionsContainer}>
                    <DefaultBodyText isItalicized={true}>{content.assistanceBlock.helpfulQuestions[0]}</DefaultBodyText>
                    <div className={layout.infoBlockHelpfulQuestion}>
                      <DefaultBodyText
                        isItalicized={true}>{content.assistanceBlock.helpfulQuestions[1]}</DefaultBodyText>
                    </div>
                    <div className={layout.infoBlockHelpfulQuestion}>
                      <DefaultBodyText
                        isItalicized={true}>{content.assistanceBlock.helpfulQuestions[2]}</DefaultBodyText>
                    </div>
                    <div className={layout.infoBlockHelpfulQuestion}>
                      <DefaultBodyText
                        isItalicized={true}>{content.assistanceBlock.helpfulQuestions[3]}</DefaultBodyText>
                    </div>
                  </div>
                </InfoBlock>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={formStyles.buttonsContainer}>
        <FormCtaButtons
          isReadyToSave={isReadyToSave}
          onSaveAndClose={handleSaveAndClose}
          isSaveAndCloseLoading={isSaveAndCloseLoading}
          isLastSection={true}
        />
      </div>
    </div>
  );
};

export default withRouter(SequenceOfEventsSection);
