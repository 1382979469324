import React from 'react';
import { useFormStyles } from '../../../shared/theme/form-styles';
import TextInputField from '../../../shared/components/text-input-field';
import { Divider } from '../../../shared/components';
import TextAreaInputField from '../../../shared/components/text-area-input-field';
import Dropdown from '../../../shared/components/dropdown';
import { vehicleSection } from '../../models/vehicle-section.model';
import { createGrid } from '@nascentdigital/react-lattice';
import { useVehicleWasInvolvedController } from './vehicle-was-involved.controller';

const content = vehicleSection.wasVehicleInvolved;
const Grid = createGrid();

const VehicleWasInvolved = () => {
  const formStyles = useFormStyles();

  const {
    initialVehicleMakeValue,
    initialVehicleModelValue,
    initialVehicleColourValue,
    initialVehicleStyleValue,
    initialLicensePlateValue,
    initialIssuingProvinceTerritory,
    initialDistinctFeaturesAboutVehicleValue,
    vehicleMakeHasBeenFilled,
    vehicleModelHasBeenFilled,
    vehicleColourHasBeenFilled,
    vehicleStyleHasBeenFilled,
    licensePlateHasBeenFilled,
    issuingProvinceTerritoryHasBeenFilled,
    distintFeaturesAboutVehicleHasBeenFilled,
    handleVehicleMakeChange,
    handleVehicleModelChange,
    handleVehicleColourChange,
    handleVehicleStyleChange,
    handleLicensePlateChange,
    handleIssuingProvinceTerritoryChange,
    handleDistinctFeaturesAboutVehicleChange
  } = useVehicleWasInvolvedController();

  return (
    <>
      <Grid container justify='spaceBetween' className={formStyles.questionGroupInternalPadding}>
        <Grid item flex={{ xs: 12, md: 6 }}>
          <TextInputField
            hasBeenFilled={vehicleMakeHasBeenFilled}
            initialValue={initialVehicleMakeValue}
            label={content.make.label}
            onChange={handleVehicleMakeChange}
            id='make'
          />
        </Grid>
        <Grid item flex={{ xs: 12, md: 6 }}>
          <TextInputField
            hasBeenFilled={vehicleModelHasBeenFilled}
            initialValue={initialVehicleModelValue}
            label={content.model.label}
            onChange={handleVehicleModelChange}
            id='model'
          />
        </Grid>
      </Grid>
      <Grid container justify='spaceBetween' className={formStyles.questionGroupInternalPadding}>
        <Grid item flex={{ xs: 12, md: 6 }}>
          <TextInputField
            hasBeenFilled={vehicleColourHasBeenFilled}
            initialValue={initialVehicleColourValue}
            label={content.colour.label}
            onChange={handleVehicleColourChange}
            id='color'
          />
        </Grid>
        <Grid item flex={{ xs: 12, md: 6 }}>
          <Dropdown
            hasBeenFilled={vehicleStyleHasBeenFilled}
            initialValue={initialVehicleStyleValue}
            label={content.vehicleStyle.label}
            options={content.vehicleStyle.options}
            onChange={handleVehicleStyleChange}
            isComboBox={true}
            id='style'
          />
        </Grid>
      </Grid>
      <Grid container justify='spaceBetween' className={formStyles.questionGroupInternalPadding}>
        <Grid item flex={{ xs: 12, md: 6 }}>
          <TextInputField
            hasBeenFilled={licensePlateHasBeenFilled}
            initialValue={initialLicensePlateValue}
            placeholder="(Full or partial plates)"
            label={content.licensePlate.label}
            onChange={handleLicensePlateChange}
            id='plate'
          />
        </Grid>
        <Grid item flex={{ xs: 12, md: 6 }}>
          <Dropdown
            hasBeenFilled={issuingProvinceTerritoryHasBeenFilled}
            initialValue={initialIssuingProvinceTerritory}
            label={content.issuingProvince.label}
            options={content.issuingProvince.options}
            onChange={handleIssuingProvinceTerritoryChange}
            isComboBox={true}
            id='province'
          />
        </Grid>
      </Grid>
      <div className={formStyles.question}>
        <TextAreaInputField
          hasBeenFilled={distintFeaturesAboutVehicleHasBeenFilled}
          initialValue={initialDistinctFeaturesAboutVehicleValue}
          label={content.distinctFeatures.label}
          placeholder={content.distinctFeatures.placeholder}
          onChange={handleDistinctFeaturesAboutVehicleChange}
          id='feature'
        />
      </div>
      <div className={formStyles.divider}>
        <Divider width="100%" thickness="thin"/>
      </div>
    </>
  );
};

export default VehicleWasInvolved;
