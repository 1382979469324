import { useHistory } from 'react-router-dom'
import alertService from '../../services/alert-service'

export function useSectionLeaveAlertController(nextRoute: string) {
  const history = useHistory()

  const handleLeaveSection = () => {
    history.push(nextRoute)
    alertService.clearAlert()
  }

  const handleCancel = () => {
    alertService.clearAlert()
  }

  return {
    handleLeaveSection,
    handleCancel
  }
}
