import { createUseStyles } from 'react-jss'
import { colorPalette } from '../../shared/theme'

export const useStyles = createUseStyles({
  loginPage: {
    width: '100vw',
    height: '100%',
    background: colorPalette.purple,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginCard: {
    width: '400px',
    height: '360px',
    background: colorPalette.lavender,
    border: `1px solid ${colorPalette.lavender}`,
    borderRadius: '16px',
    boxSizing: 'border-box',
    padding: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  title: {
    width: 'fit-content'
  },
  inputFieldsContainer: {
    height: '140px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '16px'
  },
  notification: {
    position: 'absolute',
    width: '400px',
    height: '100px',
    border: `1px solid ${colorPalette.orangeRed}`,
    background: colorPalette.peach,
    borderRadius: '16px',
    padding: '30px',
    boxSizing: 'border-box',
    top: '24px',
    left: '24px'
  },
  logo: {
    position: 'absolute',
    width: '150px',
    left: '48px',
    top: '48px'
  }
})
