import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { colorPalette } from '../../shared/theme';
import notificationService, {
  NOTIFICATION_TYPE,
  NotificationStatus,
  initialNotificationStatus
} from '../services/notification-service';
import ServerErrorNotification from './server-error-notification';

interface StyleProps {
  width: string
}

const useStyles = createUseStyles({
  notificationContainer: {
    position: 'fixed',
    height: 'fit-content',
    background: colorPalette.orangeRed,
    right: 0,
    top: 0,
    left: 0,
    display: 'flex',
    zIndex: 1001,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

interface Props {
  width?: string
}

const NotificationContainer: React.FunctionComponent<Props> = ({width}) => {
  const [notificationStatus, setNotificationStatus] = useState<NotificationStatus>(initialNotificationStatus);
  const {notificationContainer} = useStyles({width});

  useEffect(() => {
    const unsubscribe: Subject<void> = new Subject();
    notificationService.notifications.pipe(takeUntil(unsubscribe)).subscribe((notificationStatus: NotificationStatus) => {
      setNotificationStatus(notificationStatus);
    });
  }, []);

  const showServerErrorNotification = notificationStatus[NOTIFICATION_TYPE.SERVER_ERROR].display;

  return (
    <div className={notificationContainer}>
      <ServerErrorNotification show={showServerErrorNotification}/>
    </div>
  );
};

NotificationContainer.defaultProps = {
  width: 'inherit'
};

export default NotificationContainer;
