import React from "react"
import { useFormStyles } from "../../../../shared/theme/form-styles"
import { SuspectForm } from "../../../services/suspect-section-form-service"
import TextInputField from "../../../../shared/components/text-input-field"
import { Divider} from "../../../../shared/components"
import { suspectSection as model } from '../../../models/suspect-section.model'
import { useDoNotKnowThisPersonController } from "./do-not-know-this-person.controller"

const content = model.suspectIdentity

const DoNotKnowThisPerson: React.FunctionComponent<{ form: SuspectForm }> = ({ form }) => {
  const formStyles = useFormStyles()

  const {
    initialSuspectNames,
    handleSuspectNamesChange
  } = useDoNotKnowThisPersonController(form)

  return (
    <div>
      <div className={formStyles.question}>
        <TextInputField
          initialValue={initialSuspectNames}
          label={content.NO_didSuspectGiveTheirNames.label}
          onChange={handleSuspectNamesChange}
          hasBeenFilled={!!form.hasBeenFilled['suspectNames']}
          id='didSuspectGiveTheirNames'
        />
      </div>
      <div className={formStyles.divider}>
        <Divider width="100%" thickness="thin" />
      </div>
    </div>
  )
}

export default DoNotKnowThisPerson
