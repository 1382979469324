import { CreateSuspectSectionDto, SuspectDto } from "../data-transfer-objects/create-suspect-section.dto";
import { CreateSuspectSectionOptions } from "../interfaces/section-service.interface";

export function createSuspectSectionRequestBody(
  minimumRequestBody: CreateSuspectSectionDto,
  data: CreateSuspectSectionOptions
): CreateSuspectSectionDto {
  let requestBody = { ...minimumRequestBody }
  requestBody.suspects = data.suspects.map((data) => {
    const suspect: SuspectDto = {
      suspectId: data.suspectId
    }
    if (data.isSuspectKnown !== undefined) {
      suspect.isSuspectKnown = {
        inputFieldName: 'isSuspectKnown',
        inputFieldValue: data.isSuspectKnown
      }
    }
    if (data.suspectNames !== undefined) {
      suspect.suspectNames = {
        inputFieldName: 'suspectNames',
        inputFieldValue: data.isSuspectKnown === '' ? '' : data.suspectNames
      }
    }
    if (data.howDidSuspectKnowYou !== undefined) {
      suspect.howDidSuspectKnowYou = {
        inputFieldName: 'howDidSuspectKnowYou',
        inputFieldValue: data.isSuspectKnown === '' ? '' : data.howDidSuspectKnowYou
      }
    }
    if (data.approximateAge !== undefined) {
      suspect.approximateAge = {
        inputFieldName: 'approximateAge',
        inputFieldValue: data.approximateAge
      }
    }
    if (data.approximateHeight !== undefined) {
      suspect.approximateHeight = {
        inputFieldName: 'approximateHeight',
        inputFieldValue: data.approximateHeight
      }
    }
    if (data.raceAppearance !== undefined) {
      suspect.raceAppearance = {
        inputFieldName: 'raceAppearance',
        inputFieldValue: data.raceAppearance
      }
    }
    if (data.skinColour !== undefined) {
      suspect.skinColour = {
        inputFieldName: 'skinColour',
        inputFieldValue: data.skinColour
      }
    }
    if (data.hairColour !== undefined) {
      suspect.hairColour = {
        inputFieldName: 'hairColour',
        inputFieldValue: data.hairColour
      }
    }
    if (data.suspectGender !== undefined) {
      suspect.suspectGender = {
        inputFieldName: 'suspectGender',
        inputFieldValue: data.suspectGender
      }
    }
    if (data.buildDescription !== undefined) {
      suspect.buildDescription = {
        inputFieldName: 'buildDescription',
        inputFieldValue: data.buildDescription
      }
    }
    if (data.distinctFeatures !== undefined) {
      suspect.distinctFeatures = {
        inputFieldName: 'suspectDistinctFeatures',
        inputFieldValue: data.distinctFeatures
      }
    }
    if (data.accentsLanguage !== undefined) {
      suspect.accentsLanguage = {
        inputFieldName: 'accentsLanguage',
        inputFieldValue: data.accentsLanguage
      }
    }
    if (data.distinctWordsPhrases !== undefined) {
      suspect.distinctWordsPhrases = {
        inputFieldName: 'distinctWordsPhrases',
        inputFieldValue: data.distinctWordsPhrases
      }
    }
    return suspect
  })
  return requestBody
}
