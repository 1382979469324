import React from 'react';
import { ImageLabelModel } from './image-label.model';
import { imageLabelStylesFactory } from './image-label.styles';
import { createGrid } from '@nascentdigital/react-lattice';
import { DefaultBodyText } from '../../../shared/components';
import { breakpoints } from '../../../shared/theme';

const stylesFactory = imageLabelStylesFactory;
const Grid = createGrid({ breakpoints});

const ImageLabel: React.FunctionComponent<{ model: ImageLabelModel }> = ({ model }) => {
  const styles = stylesFactory();

  return (
    <Grid container className={styles.container}>
      <Grid item flex={{ xs: 2 }}>
        <img src={model.imgSrc} alt={model.imgAlt} className={styles.image}/>
      </Grid>
      <Grid item flex={{ xs: 10 }}>
        <DefaultBodyText fontSize="1rem">
          {model.label}
        </DefaultBodyText>
      </Grid>
    </Grid>
  );
};

export default ImageLabel;
