import React from 'react'

// components
import BaseButton from './base-button'

interface InactiveActionButtonProps {
  children: React.ReactNode,
  width?: string
}

const InactiveActionButton: React.FunctionComponent<InactiveActionButtonProps> = ({ children, width }) => {
  return (
    <BaseButton
      borderColor="brownGrey"
      backgroundColor="none"
      fontColor="brownGrey"
      width={width}
      inactive={true}
    >
      {children?.toString().toUpperCase()} &#8594;
    </BaseButton>
  )
}

export default InactiveActionButton
