import { usePrevious } from "../../../shared/hooks/use-previous"
import { useState, useEffect } from "react"
import suspectSectionFormService, { SuspectForm } from "../../services/suspect-section-form-service"
import ServiceLocator from "../../../shared/service-locator/service-locator"

const reportService = ServiceLocator.getInstance().getReportService()

export function useSuspectIdentityController(form: SuspectForm) {

  const suspectSavedData = reportService.suspectSectionData?.suspects?.find(({ suspectId }) => suspectId === form.getId())

  const isSuspectKnownSavedData = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'isSuspectKnown')?.inputFieldValue

  let initialKnowThisPerson = ''

  if (isSuspectKnownSavedData === "true") {
    initialKnowThisPerson = 'Yes'
  } else if (isSuspectKnownSavedData === "false") {
    initialKnowThisPerson = 'No'
  }

  const [ knowThisPerson, setKnowThisPerson ] = useState<string>(initialKnowThisPerson)
  const previousKnowThisPerson = usePrevious(knowThisPerson)

  useEffect(() => {
    return () => {
      form.setIsSuspectKnown('')
      suspectSectionFormService.notifyFormChanged()
    }
  }, [form])

  const handleKnowThisPersonChange = (value: string) => {
    setKnowThisPerson(value)
    if (value === 'Yes') {
      form.setIsSuspectKnown("true")
    }
    else if (value === 'No') {
      form.setIsSuspectKnown("false")
    }
    else {
      form.setIsSuspectKnown("")
    }
    suspectSectionFormService.notifyFormChanged()
  }

  return {
    initialKnowThisPerson,
    knowThisPerson,
    previousKnowThisPerson,
    handleKnowThisPersonChange
  }
}
