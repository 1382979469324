import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import TertiaryButton from "../tertiary-button"
import { helplineResourcesLinkContentProvider } from "./helpline-resources-link.content"
import { HelplineResourcesLinkProps } from "./helpline-resources-link.props";

const content = helplineResourcesLinkContentProvider()

const HelplineResourcesLink: React.FunctionComponent<RouteComponentProps & HelplineResourcesLinkProps> = (
  { history, className }) => {
  const onSelected = () => {
    history.push('/resources')
  }

  return (
    <TertiaryButton className={className} width={'auto'} onClick={() => onSelected()}
                    color={'grape'}>{content.label}</TertiaryButton>
  )
}

export default withRouter(HelplineResourcesLink)
