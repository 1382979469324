import React from 'react'

// component
import BaseButton from './base-button'

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  onClick?: () => void;
  width?: string;
  arrowDirection?: 'left'|'right';
}

export const SecondaryActionButton: React.FunctionComponent<Props> = ({ children, onClick, width, arrowDirection, className }) => {
  return (
    <BaseButton onClick={onClick} borderColor="grape" backgroundColor="white" fontColor="grape" width={width} className={className}>
      {arrowDirection === 'left' && <p>&#8592;&nbsp;</p>} {children?.toString().toUpperCase()} {arrowDirection === 'right' && <p>&nbsp;&#8594;</p>}
    </BaseButton>
  )
}

SecondaryActionButton.defaultProps = {
  arrowDirection: 'left'
}

export default SecondaryActionButton
