import React from 'react'

// components
import TertiaryButton from '../../../shared/components/tertiary-button'

// models
import { sideNav } from '../models/side-nav.model'
import alertService from '../../../alerts/services/alert-service'

interface Props {
  disabled?: boolean;
}

const LeaveSessionButton: React.FunctionComponent<Props> = ({ disabled }) => {
  const handleButtonClick = () => {
    if (!disabled) {
      alertService.alertLeaveForm()
    }
  }
  return (
    <TertiaryButton
      width="210px"
      color={disabled ? 'lightGrey' : 'white'}
      inactive={disabled}
      isUnderlined={!disabled}
      onClick={handleButtonClick}
    >
      {sideNav.leaveSessionButton.label}
    </TertiaryButton>
  )
}

LeaveSessionButton.defaultProps = {
  disabled: false
}

export default LeaveSessionButton
