import suspectSectionFormService, { SuspectForm } from "../../services/suspect-section-form-service";
import ServiceLocator from "../../../shared/service-locator/service-locator";

const reportService = ServiceLocator.getInstance().getReportService()

export function useAccentsLanguagesPhrasesController(form: SuspectForm) {

  const suspectSavedData = reportService.suspectSectionData?.suspects?.find(({ suspectId }) => suspectId === form.getId())

  const initialAccentsLanguage = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'accentsLanguage')?.inputFieldValue
  const initialDistinctWordsPhrases = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'distinctWordsPhrases')?.inputFieldValue


  const handleAccentsLanguageChange = (value: string) => {
    form.setAccentsLanguage(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleDistinctWordsPhrasesChange = (value: string) => {
    form.setDistinctWordsPhrases(value)
    suspectSectionFormService.notifyFormChanged()
  }

  return {
    initialAccentsLanguage,
    initialDistinctWordsPhrases,
    handleAccentsLanguageChange,
    handleDistinctWordsPhrasesChange
  }
}
