import vehicleSectionFormService from "../services/vehicle-section-form-service"
import { useEffect, useState } from "react"
import { Subject } from "rxjs"
import { REPORT_ROUTES } from "../../constants"
import { useHistory } from "react-router-dom"
import { takeUntil } from "rxjs/operators"
import ServiceLocator from "../../shared/service-locator/service-locator"

const reportService = ServiceLocator.getInstance().getReportService()
const analyticsService = ServiceLocator.getInstance().getAnalyticsService()

export function useVehicleSectionController() {

  const history = useHistory()
  const vehicleSectionSavedData = reportService.vehicleSectionSavedData

  const [ isSaveAndNextSectionLoading, setIsSaveAndNextSectionLoading ] = useState<boolean>(false)
  const [ isSaveAndCloseLoading, setIsSaveAndCloseLoading ] = useState<boolean>(false)
  const [ isReadyToSave , setIsReadyToSave ] = useState<boolean>(false)

  const showReturnCallOut = vehicleSectionSavedData?.showReturnCallOut

  const handleSaveAndClose = async () => {
    setIsSaveAndCloseLoading(true)
    analyticsService.logEvent(
      'save_report',
      {
        section: 'vehicle'
      }
    )
    try {
      await vehicleSectionFormService.submit()
      setIsSaveAndCloseLoading(false)
      history.push(REPORT_ROUTES.DASHBOARD)
    } catch (e) {
      setIsSaveAndCloseLoading(false)
    }
  }

  const handleSaveAndNextSection = async () => {
    setIsSaveAndNextSectionLoading(true)
    analyticsService.logEvent(
      'save_report',
      {
        section: 'vehicle'
      }
    )
    try {
      await vehicleSectionFormService.submit()
      setIsSaveAndNextSectionLoading(false)
      history.push(REPORT_ROUTES.SUSPECT_SECTION)
    } catch (e) {
      setIsSaveAndNextSectionLoading(false)
    }
  }

  const unsubscribe: Subject<void> = new Subject();

  useEffect(() => {

    analyticsService.viewPage('/report/vehicle-section')
    vehicleSectionFormService.isReadyToSave.pipe(takeUntil(unsubscribe)).subscribe((isReadyToSave) => {
      setIsReadyToSave(isReadyToSave)
    })

    vehicleSectionFormService.initialize()

    return () => {
      unsubscribe.next()
      unsubscribe.complete()
    }
  }, [])

  useEffect(() => {
    return () => { vehicleSectionFormService.clearForm() }
  }, [])

  return {
    showReturnCallOut,
    isSaveAndCloseLoading,
    isSaveAndNextSectionLoading,
    isReadyToSave,
    handleSaveAndClose,
    handleSaveAndNextSection
  }
}
