import { createUseStyles } from 'react-jss';

// theme
import { BpTheme, colorPalette } from '../../../shared/theme';

export const useLayout = createUseStyles({
  header: {
    marginBottom: '24px'
  },
  leaveSessionButton: {
    marginTop: '10px'
  }
});

const navWidth = 276;
export const useNavStyle = createUseStyles((theme: BpTheme) => ( {
  nav: {
    padding: 24,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.mediaQueries.tabletAndAbove]: {
      padding: 45
    }
  },
  container: {
    background: colorPalette.navy,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 15,
    width: navWidth,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'left .5s',
    [theme.mediaQueries.tabletAndAbove]: {
      left: 0,
      width: '30%'
    },
    [theme.mediaQueries.desktopAndAbove]: {
      left: 0,
      width: '25%'
    }
  },
  containerCollapsed: {
    extend: 'container',
    left: -navWidth,
  },
  mobileToggle: {
    borderStyle: 'none',
    display: 'block',
    zIndex: 50,
    position: 'absolute',
    right: -103,
    minWidth: 180,
    top: '50%',
    background: theme.colorPalette.navy,
    color: theme.colorPalette.white,
    transform: 'rotate(270deg)',
    padding: [7, 25, 5, 25],
    cursor: 'pointer',
    borderRadius: [0, 0, 15, 15],
    ...theme.fontStylesTablet.body,
    [theme.mediaQueries.tabletAndAbove]: {
      display: 'none'
    }
  },
  mobileToggleCollapsed: {
    extend: 'mobileToggle'
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '300px',
    maxHeight: '500px',
  },
  linkList: {
    margin: '0',
    padding: '0',
    listStyle: 'none'
  },
  link: {
    position: 'relative',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'inherit',
    border: 'none',
    padding: '16px 0',
    textAlign: 'left',
    '&:focus': {
      // /* Adds a white border around the button, along with a blue glow. The white and blue have a color contrast ratio of at least 3:1, which means this will work against any background color. */
      // boxShadow: '0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9',
      // /* NOTE: box-shadow is invisible in Windows high-contrast mode, so we need to add a transparent outline, which actually does show up in high-contrast mode. */
      // outline: '2px dotted transparent',
      // outlineOffset: '2px',
      outline: 'none',
      transform: 'translateX(-2%)',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkListItem: {
    borderBottom: '1px solid white',
  },
  topLinkListItem: {
    extend: 'linkListItem',
    borderTop: '1px solid white',
  },
  linkLabel: {
    zIndex: 2,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  activeLinkIndicator: {
    background: colorPalette.lightNavy,
    position: 'absolute',
    height: '115%',
    width: '150%',
    left: '-12%',
    zIndex: 1,
    border: `2px solid ${colorPalette.lightNavy}`,
    borderRadius: '0.6rem'
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '130px',
    justifyContent: 'space-evenly'
  }
} ));

export const useLogoStyle = createUseStyles({
  logo: {
    width: '50%',
    minWidth: '80px',
    paddingBottom: 5
  }
});
