import React, { ChangeEvent } from 'react';
import { passwordInputFieldStylesFactory } from './alert-input-field.styles';
import { useAlertInputFieldController } from './alert-input-field.controller';
import { createGrid } from '@nascentdigital/react-lattice';
import { DefaultBodyText } from '../../../../shared/components';

const stylesFactory = passwordInputFieldStylesFactory;
const Grid = createGrid();

interface AlertInputFieldProps {
  autoFocus?: boolean;
  label?: string;
  errorMessage?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  fieldType?: 'reportPassword' | 'reportCode';
  isInvalid?: boolean;
  id: string;
}

const AlertInputField: React.FunctionComponent<AlertInputFieldProps> = (props) => {

  const initialType = props.fieldType === 'reportPassword' ? 'password' : '';

  const {
    onHideShowPassword,
    type,
    passwordImg,
    passwordAlt,
    isHidden
  } = useAlertInputFieldController(initialType);

  const styles = stylesFactory(
    {
      isValid: props.errorMessage || props.isInvalid,
      isHidden
    });

  return (
    <>
      <Grid container>
        <Grid item flex={{ xs: 12 }} className={styles.inputFieldContainer}>
          <input
            id={props.id}
            autoComplete='off'
            type={type}
            value={props.value}
            onChange={props.onChange}
            autoFocus={props.autoFocus}
            className={styles.inputField}/>
          {props.fieldType === 'reportPassword' && (
            <input type='image' src={passwordImg} alt={passwordAlt} onClick={onHideShowPassword}
                   className={styles.passwordImg}/>
          )}
        </Grid>
      </Grid>
      <Grid container className={styles.labelErrorMessage}>
        <Grid item flex={{ xs: 12, md: 3 }}>
          <label htmlFor={props.id}>
            <DefaultBodyText fontSize='0.8rem'>
              {props.label}
            </DefaultBodyText>
          </label>
        </Grid>
        <Grid item flex={{ xs: 9 }}>
          <div className={styles.errorMessage}>
            <DefaultBodyText fontSize='0.8rem' color='orangeRed' isBolded={true} isAlert={true}>
              {props.errorMessage}
            </DefaultBodyText>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

AlertInputField.defaultProps = {
  fieldType: 'reportPassword',
  isInvalid: false
};

export default AlertInputField;
