import React from 'react';
import { DefaultHeader } from '../../../shared/components';
import { ImageLabelListModel } from './image-label-list.model';
import ImageLabel from '../image-label/image-label';
import { createUseStyles } from 'react-jss';
import { BpTheme } from '../../../shared/theme';

const useStyles = createUseStyles((theme: BpTheme) => ( {
  list: {
    marginBottom: 16
  }
} ));

const ImageLabelList: React.FunctionComponent<{ model: ImageLabelListModel }> = ({ model }) => {
  const classes = useStyles();
  return (
    <div className={classes.list}>
      <DefaultHeader>{model.header}</DefaultHeader>
      {model.items.map((item, index) => <ImageLabel model={item} key={index}/>)}
    </div>
  );
};

export default ImageLabelList;
