import React from 'react';
import { DefaultBodyText } from '../../../../../shared/components';
import { resumeReportOptionButtonStylesFactory } from './resume-report-option-button.styles';
import { Color } from '../../../../../shared/theme';

const useStyles = resumeReportOptionButtonStylesFactory;

const ResumeReportOptionButton: React.FunctionComponent<{
  label: string;
  selected: boolean;
  handleClick: () => void;
  position?: 'left' | 'right';
  id: string;
}> = ({label, selected, handleClick, position, id}) => {

  const styles = useStyles({selected, position});
  const labelColor: Color = selected ? 'navy' : 'grape';

  return (
    <>
      <input className={styles.button} onChange={handleClick} type='radio' name='loginOption' id={id}
             checked={selected}/>
      <label htmlFor={id} className={styles.label}>
        <DefaultBodyText isBolded={true} color={labelColor} letterSpacing="1px">
          {label.toUpperCase()}
        </DefaultBodyText>
      </label>
    </>
  );
};

ResumeReportOptionButton.defaultProps = {
  position: 'left'
};

export default ResumeReportOptionButton;
