import React from 'react';
import { createUseStyles } from 'react-jss';
import { BpTheme } from '../theme';


const useStyles = createUseStyles((theme: BpTheme) => (
  {
    container: {
      [theme.mediaQueries.mobileOnly]: {
        padding: [24, 33, 54, 33],
        maxWidth: `${theme.breakpoints.sm}px`
      },
      [theme.mediaQueries.tabletAndAbove]: {
        maxWidth: `${theme.breakpoints.md}px`
      },
      [theme.mediaQueries.desktopAndAbove]: {
        maxWidth: `${theme.breakpoints.lg}px`
      },
      [theme.mediaQueries.xl]: {
        maxWidth: `${theme.breakpoints.xl}px`
      },
      padding: [75, 0, 75, 0],
      margin: 'auto'
    }
  }
));

export const Container: React.FunctionComponent = ({children}) => {
  const {container} = useStyles();

  return (
    <div className={container}>{children}</div>
  );
};

export default Container;
