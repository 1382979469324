import { VictimSectionContent } from "./victim-section.content";
import { returnCallOutWithPassword, returnCallOutWithoutPassword } from "../../shared/models/return-call-out.model";
import { genderOptions } from "../../shared/models/gender-options";
import { raceAppearanceOptions } from "../../shared/models/race-appearance-options";

export const victimSectionContentEn: VictimSectionContent = {
  sectionHeader: 'Description of Victim/Survivor',
  description: 'This information could help link assaults that may have been committed by the same suspect/offender.',
  returnCallOutWithPassword,
  returnCallOutWithoutPassword,
  age: {
    label: 'What was the age of the victim/survivor at the time of the offence?'
  },
  gender: {
    label: 'What gender did the victim/survivor identify as at the time of the offence?',
    options: genderOptions
  },
  victimRaceAppearance: {
    label: 'What race does the victim/survivor identify as?',
    options: raceAppearanceOptions
  }
}
