import { TextInputField } from "../../shared/models/question.model";

export interface DistinctFeaturesAboutTheLocationQuestionModel {
  distinctFeaturesAboutTheLocation: TextInputField
}

export const distinctFeaturesAboutTheLocationQuestion: DistinctFeaturesAboutTheLocationQuestionModel = {
  distinctFeaturesAboutTheLocation: {
    label: 'Do you recall any distinct features about the location?',
    placeholder: '(eg. indoor or outdoor, etc.)'
  }
}
