import { CheckboxesInputField, TextInputField, DropdownInputField } from "../../shared/models/question.model";
import { CHECKBOX_YES_NO_I_DONT_RECALL_OPTIONS } from "../../constants";

export interface KnowAddressOfThisLocationQuestionModel {
  knowTheAddressOfThisLocation: CheckboxesInputField;
  YES_address: TextInputField;
  YES_city: TextInputField;
  YES_province: DropdownInputField;
}

export const knowAddressOfThisLocationQuestion: KnowAddressOfThisLocationQuestionModel = {
  knowTheAddressOfThisLocation: {
    label: 'Do you know the address of this location?',
    options: CHECKBOX_YES_NO_I_DONT_RECALL_OPTIONS
  },
  YES_address: {
    label: 'Are you comfortable sharing the address in this report?',
    placeholder: 'Address'
  },
  YES_city: {
    label: 'City',
  },
  YES_province: {
    label: 'Province',
    options: [
      { value: 'Alberta', displayText: 'Alberta'},
      { value: 'British Columbia', displayText: 'British Columbia'},
      { value: 'Manitoba', displayText: 'Manitoba'},
      { value: 'New Brunswick', displayText: 'New Brunswick'},
      { value: 'Newfoundland and Labrador', displayText: 'Newfoundland and Labrador'},
      { value: 'Nova Scotia', displayText: 'Nova Scotia'},
      { value: 'Ontario', displayText: 'Ontario'},
      { value: 'Prince Edward Island', displayText: 'Prince Edward Island'},
      { value: 'Québec', displayText: 'Québec'},
      { value: 'Northwest Territories', displayText: 'Northwest Territories'},
      { value: 'Nunavut', displayText: 'Nunavut'},
      { value: 'Yukon', displayText: 'Yukon'},
      { value: 'Other', displayText: 'Other'},
    ]
  }
}
