import { BehaviorSubject, Observable } from "rxjs";

export enum NOTIFICATION_TYPE {
  SERVER_ERROR 
}

interface NotificationValue {
  display: boolean,
  options?: {[index: string]: string}
}

export type NotificationStatus = Record<NOTIFICATION_TYPE, NotificationValue>

export const initialNotificationStatus: NotificationStatus = {
  [NOTIFICATION_TYPE.SERVER_ERROR]: { display: false }
}

type NotificationServiceConfig = {
  displayDuration: number;
}

class NotificationService {
  private _notifications: BehaviorSubject<NotificationStatus> = new BehaviorSubject<NotificationStatus>(initialNotificationStatus)
  public notifications: Observable<NotificationStatus> = this._notifications.asObservable()
  private displayDuration: number;
  private clearTimer: NodeJS.Timeout | undefined = undefined;

  constructor(config: NotificationServiceConfig) {
    this.displayDuration = config.displayDuration
  }

  public notifyServerError() {
    const notificationStatus: NotificationStatus = {
      ...this._notifications.value,
      [NOTIFICATION_TYPE.SERVER_ERROR]: { display: true }
    }
    this._notifications.next(notificationStatus)
    
    // if a timer currently exists, remove the timer and create a new one
    if (this.clearTimer !== undefined) {
      clearTimeout(this.clearTimer)
    }
  
    this.clearTimer = setTimeout(() => {
      this.clearNotification(NOTIFICATION_TYPE.SERVER_ERROR)
      // noce timeout is complete, set the timer to undefined
    }, this.displayDuration * 1000)
  }

  private clearNotification(type: NOTIFICATION_TYPE | 'all' = 'all') {
    if (type === 'all') {
      this._notifications.next(initialNotificationStatus)
    } else {
      const notificationStatus: NotificationStatus = {
        ...this._notifications.value,
        [type]: { display: false }
      }
      this._notifications.next(notificationStatus)
    }
  }
}

const notificationService = new NotificationService({ displayDuration: 3 })

export default notificationService

