import suspectSectionFormService, { SuspectForm } from "../../services/suspect-section-form-service";
import { useEffect } from "react";
import ServiceLocator from "../../../shared/service-locator/service-locator";

const reportService = ServiceLocator.getInstance().getReportService()

export function usePhysicalFeaturesController(form: SuspectForm) {

  const suspectSavedData = reportService.suspectSectionData?.suspects?.find(({ suspectId }) => suspectId === form.getId())

  const initialApproximateAge = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'approximateAge')?.inputFieldValue
  const initialApproximateHeight = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'approximateHeight')?.inputFieldValue
  const initialRaceAppearance = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'raceAppearance')?.inputFieldValue
  const initialSkinColour = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'skinColour')?.inputFieldValue
  const initialHairColour = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'hairColour')?.inputFieldValue
  const initialSuspectGender = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'suspectGender')?.inputFieldValue
  const initialBuildDescription = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'buildDescription')?.inputFieldValue
  const initialDistinctFeatures = suspectSavedData?.inputFields?.find(({ inputFieldName }) => inputFieldName === 'suspectDistinctFeatures')?.inputFieldValue

  const handleApproximateAgeChange = (value: string) => {
    form.setApproximateAge(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleApproximateHeightChange = (value: string) => {
    form.setApproximateHeight(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleRaceAppearanceChange = (value: string) => {
    form.setRaceAppearance(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleSkinColourChange = (value: string) => {
    form.setSkinColour(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleHairColourChange = (value: string) => {
    form.setHairColour(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleSuspectGenderChange = (value: string) => {
    form.setSuspectGender(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleBuildDescriptionChange = (value: string) => {
    form.setBuildDescription(value)
    suspectSectionFormService.notifyFormChanged()
  }

  const handleDistinctFeaturesChange = (value: string) => {
    form.setDistinctFeatures(value)
    suspectSectionFormService.notifyFormChanged()
  }

  useEffect(() => {
    return () => {
      form.setApproximateAge('')
      form.setApproximateHeight('')
      form.setRaceAppearance('')
      form.setSkinColour('')
      form.setHairColour('')
      form.setSuspectGender('')
      form.setBuildDescription('')
      form.setDistinctFeatures('')
    }
  }, [form])

  return {
    initialApproximateAge,
    initialApproximateHeight,
    initialRaceAppearance,
    initialSkinColour,
    initialHairColour,
    initialSuspectGender,
    initialBuildDescription,
    initialDistinctFeatures,
    handleApproximateAgeChange,
    handleApproximateHeightChange,
    handleRaceAppearanceChange,
    handleSkinColourChange,
    handleHairColourChange,
    handleSuspectGenderChange,
    handleBuildDescriptionChange,
    handleDistinctFeaturesChange
  }
}
